var _settingsDeferred;
var ignorePageUrl = false;

//get savedItems for current user at scope of current page
function getSavedItems() {
    var pageUrl = ignorePageUrl ? '' : window.location.origin + window.location.pathname;

    if (!_settingsDeferred) {
        _settingsDeferred = $.Deferred();

        $.getJSON('/api/Save/GetSavedItems?pageUrl=' + pageUrl).then(function (val) {
            _settingsDeferred.resolve(val);
        }).fail(function (err) {
            //on error, just assume no saved items for this page
            _settingsDeferred.resolve([]);
        });
    }
    return _settingsDeferred.promise();
}


var itemTypes = {
    AV: 'AV',
    Image: 'Image',
    FloorPlan: 'FloorPlan',
    Community: 'Community',
    Plan: 'Plan',
    QMI: 'QMI',
    Video: 'Video',
    Cinemagraph: 'Cinemagraph'
};

// returns promise<bool>
function isCommunitySaved(communityId) {
    return getSavedItems().then(function (items) {
        return !!(items.find(function (item) {
            return item.itemType == itemTypes.Community && item.communityId == communityId;
        }));
    })
}

// returns promise<bool>
function isImageSaved(imageUrl) {
    return getSavedItems()
        .then(function (items) {
            return !!(items.find(function (item) {
                return item.itemType === itemTypes.Image && item.url === imageUrl;
            }));
        });
}

// returns promise<bool>
// TODO: Update to use dynamic unique link to video. Current API doesn't not have information but will
// in the follow story
function isVideoSaved(videoUrl) {
    return getSavedItems()
        .then(function (items) {
            return !!(items.find(function (item) {
                return item.itemType === itemTypes.Video && item.url === videoUrl;
            }));
        });
}

// returns promise<bool>
function isPlanSaved(planId) {
    return getSavedItems().then(function (items) {
        return !!(items.find(function (item) {
            return item.itemType == itemTypes.Plan && item.planId == planId;
        }));
    });
}

function isQmiSaved(qmiId) {
    return getSavedItems().then(function (items) {
        return !!(items.find(function (item) {
            return item.itemType === itemTypes.QMI && item.qmiId == qmiId;
        }));
    });
}

function addTemporarySavedItem(itemData) {
    return getSavedItems().then(function (items) {
        return  items.push({
            communityId : itemData.CommunityId,
            itemType: itemData.ToolType,
            url: itemData.MediaLocation,
            planId: itemData.PlanId,
            qmiId: itemData.QmiId, 
            caption: itemData.Caption,
            pageUrl: itemData.pageUrl
        });
    });
}


function removeTemporarySavedItem(itemType, id, idType) {
    return getSavedItems().then(function(items) {
        items.splice(items.findIndex(function(el) { 
            return itemType == el.itemType && id == el[idType]
        }),1);
       
    });
}

//set saved state of all 'saveable' elements on page
function setSavedStateForDocument() {
    $('[data-tool-type]').setSaved();
}

//set saved state of all saveable children of parentElement
function setChildSavedState(parentElement) {
    $(parentElement).find('[data-tool-type]').setSaved();
}

//jQuery extension to toggle the saved state of a set of elements
//isSaved is checked against the initial downloaded saved items list
$.fn.setSaved = function () {
    function setSavedAttributes($el, saved) {
        var savedClass = $el.data('save-class');
        var unSavedClass = $el.data('unsave-class');
        var saveText = $el.data('save-text');
        var unsaveText = $el.data('unsave-text');
        //toggle classes
        $el.removeClass(saved ? unSavedClass : savedClass);
        $el.addClass(saved ? savedClass : unSavedClass);
        //toggle text
        if (!saved && saveText) {
            $el.text(saveText);
        }
        if (saved && unsaveText) {
            $el.text(unsaveText);
        }

        if ($el.data('is-saved')) {

            $el.data('is-saved', saved ? "True" : "False");
            $el.attr('data-is-saved', saved ? "True" : "False");

            if ($el.is('img')) {
                $el.attr('src', saved ? $el.data('saveImgSavedSrc') : $el.data('saveImgUnsavedSrc'));
                $el.attr('alt', saved ? $el.data('saveImgSavedAlt') : $el.data('saveImgUnsavedAlt'));
            } 

            if($el.data("media-location")){
                var commonUnsaveLocations =  $("[data-media-location='" + $el.data("media-location") +"']"); 
                commonUnsaveLocations.removeClass(saved ? unSavedClass : savedClass)
                commonUnsaveLocations.addClass(saved ? savedClass : unSavedClass)
                if (!saved && saveText) {
                    commonUnsaveLocations.text(saveText);
                }
                if (saved && unsaveText) {
                    commonUnsaveLocations.text(unsaveText);
                }
                commonUnsaveLocations.data('is-saved', saved ? "True" : "False")
                commonUnsaveLocations.attr('data-is-saved', saved ? "True" : "False");
            }

        }        
        
    }

    this.each(function (idx, el) {
        var $el = $(el);
        var type = $el.data('tool-type');

        if (type === itemTypes.Image) {
            var url = $el.data('media-location');

            isImageSaved(url).done(function (saved) {
                setSavedAttributes($el, saved);
            });
        }

        if (type == itemTypes.Video) {
            var videoUrl = $el.data('media-location');

            isVideoSaved(videoUrl).done(function (saved) {
                setSavedAttributes($el, saved);
            });
        }

        if (type === itemTypes.Plan) {
            var planId = $el.data('plan-id');

            isPlanSaved(planId).done(function (saved) {
                setSavedAttributes($el, saved);
            });
        }

        if (type == itemTypes.Community) {
            var communityId = $el.data('community-id');

            isCommunitySaved(communityId).done(function (saved) { setSavedAttributes($el, saved); });
        }

        if (type == itemTypes.QMI) {
            var qmiId = $el.data('qmi-id');

            isQmiSaved(qmiId).done(function (saved) { setSavedAttributes($el, saved); });
        }

    });
}

function activateIgnorePageUrlSetting() {
    ignorePageUrl = true;
}

//set initial state of all page element on document ready
$(function () {
    setSavedStateForDocument();
});


module.exports = {
    setSavedStateForDocument: setSavedStateForDocument,
    setChildSavedState: setChildSavedState,
    isPlanSaved: isPlanSaved,
    activateIgnorePageUrlSetting: activateIgnorePageUrlSetting,
    addTemporarySavedItem: addTemporarySavedItem, 
    removeTemporarySavedItem: removeTemporarySavedItem,
    itemTypes: itemTypes
};
