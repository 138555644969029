/*
 * Setup the print CTAs
 * https://github.com/DoersGuild/jQuery.print
*/

(function ($) {

  var startPrint;

  startPrint = function (e) {
    var $element = $(e.target.dataset.printElement || e.target.parentElement.dataset.printElement),
      $collapsibles = $element.find('.collapse:not(".in")'),
      collapsiblesCount = 0;

    var isFloorPlanModule = $element.hasClass('FloorPlanPrintModule');
    //make sure the accordion panels are not collapsed
    if ($collapsibles.length > 0) {
      $collapsibles.on('shown.bs.collapse', function () {
        collapsiblesCount++;
        if (collapsiblesCount === $collapsibles.length) {
          setTimeout(printElement, 250, $element);
        }
      });
      $collapsibles.collapse('show');
    } else {
      setTimeout(printElement, 250, $element);
    }
  };

  var printElement = function ($element) {
    $element.print();
  }

  $(document).on('click', "[data-print-element]", startPrint);

})(jQuery);
