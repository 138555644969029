var component = require('../../../../lib/js/component.js');
var utils = require('../../../../lib/js/utils.js');

var CompareResults = function () {

    var compareTypeMap = {};

    this.initialize = function (element, options) {

        var _this = this;
        var $title = _this.$element.find(".CompareResults__title");
        var compareResultsModal = $("#modal-compare-results");
        var modalCloseEvent = 'hidden.bs.modal';
        compareTypeMap["plan"] = $title.data("comparePlanTitle");
        compareTypeMap["qmi"] = $title.data("compareQmiTitle");
        compareTypeMap["community"] = $title.data("compareCommunityTitle");
        
        _this.$element.on("compare-results", this.handleCompare.bind(this));

        var $compareResultsModal = $("#modal-compare-results");
        var modalOpenEvent = 'shown.bs.modal';

        $compareResultsModal.on(modalOpenEvent, function (e) {
            var $activeCompareCards = $('.CompareResults__card.active');
            var $modalCarousels = $activeCompareCards.find(".owl-carousel");
            $modalCarousels.trigger("refresh.owl.carousel");
        });

        compareResultsModal.on(modalCloseEvent, function (e) {
            _this.$element.find('.popover-close-btn').trigger('click');
        });
    }

    this.handleCompare = function(event, view, ids){

        var $allCards = this.$element.find(".CompareResults__card");
        var $title = this.$element.find(".CompareResults__title");

        $allCards.addClass("d-none");
        
        var $showCards = $allCards.filter( 
            function() {
                return $(this).data("cardType") == view && ids.includes($(this).data("cardId").toString());
            }
        );

        if(view == "qmi"){
            $showCards.find(".CompareResults__contentContainer").removeClass("plan-card-override");
        }
        
        $showCards.removeClass("d-none two-cards three-cards three-cards-community-view").addClass("active");

        var threeCardsClass = view == "community" ? "three-cards-community-view" : "three-cards";
    
        $showCards.length == 2 ? $showCards.addClass("two-cards") : $showCards.addClass(threeCardsClass);
        
        orderCardsBySelectedIds($showCards, ids);

        $title.text(compareTypeMap[view]);

    }

    function getMaxHeightBulletsContainer(){
        // Calculate height of the bullet row and set each row to be the height of the tallest row
        var maxHeight = 0;
      
        // Find the maximum height among the elements
        $(".CompareResults__bullets").each(function() {
          maxHeight = Math.max(maxHeight, $(this).height() + 48);
        });
      
        // Set the maximum height to all elements
        $(".features-wrapper").css('min-height',maxHeight);
      }
      
    function orderCardsBySelectedIds($showCards, ids) {
        var $sorted = [];
        $showCards.each(function () {
            var newIndex = ids.indexOf($(this).data("cardId").toString());
            $sorted[newIndex] = $(this);
        });
    
        $cardsParent = $('.CompareResults__resultsContainer');
        for (var i = 0; i < $sorted.length; i++) {
            var card = $sorted[i];
            $cardsParent.append(card);
        }

        setTimeout(getMaxHeightBulletsContainer,0);
    }
}

module.exports = component(CompareResults);