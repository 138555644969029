var component = require('../../../lib/js/component.js');
var customTabs = require('../../../lib/js/custom-tabs.js');
var utils = require("../../../lib/js/utils.js");
var AdobeLaunch = require('../../../lib/js/adobelaunch.js');

function Lifestyle() {

    this.initialize = function (element) {
        _this = this;

        var $tabs = document.querySelectorAll('.Lifestyle [role="tab"]');
        var $tabList = document.querySelector('.Lifestyle [role="tablist"]');
        var $expandedCarouselContainer = document.querySelector('#ExpandedCarouselV2 .expanded-carousel-container .expanded-carousel-slides');

        $tabs.forEach(function (tab) {
          tab.addEventListener("click", function(e) {
            var $target = e.target;
            var $parent = $target.parentNode;
          
            // Remove all current selected $tabs
            $parent
              .querySelectorAll('[aria-selected="true"]')
              .forEach(function(t) {
              t.setAttribute("aria-selected", false);
              t.setAttribute("tabindex",-1)
              });
          
            // Set this tab as selected
            $target.setAttribute("aria-selected", true);
            $target.setAttribute("tabindex",0);
    
            // Hide all tab panels
            document
              .querySelectorAll('.Lifestyle__image-container [role="tabpanel"]')
              .forEach(function(p){p.setAttribute("hidden", true)});
          
            var $selector = $target.getAttribute("aria-controls");
            // Show the selected panel
            document
              .querySelector(".Lifestyle__image-container #"+$selector)
              .removeAttribute('hidden');

            // Add or remove CSS class for the expanded carousel container based on the tab type
            var tabType = $target.getAttribute('data-tab-type');
            if (tabType === 'LifestyleDirector' && $expandedCarouselContainer) {
              $expandedCarouselContainer.classList.add('expanded-carousel--lifestyle-director');
            } else if ($expandedCarouselContainer) {
              $expandedCarouselContainer.classList.remove('expanded-carousel--lifestyle-director');
            }     
          });
        });

        // Enable arrow navigation between $tabs in the tab list
        var tabFocus = 0;
      
        $tabList.addEventListener("keydown", function (e) {
          // Move right
          if (e.keyCode === 39 || e.keyCode === 37) {
            $tabs[tabFocus].setAttribute("tabindex", -1);
            if (e.keyCode === 39) {
              tabFocus++;
              // If we're at the end, go to the start
              if (tabFocus >= $tabs.length) {
                tabFocus = 0;
              }
              // Move left
            } else if (e.keyCode === 37) {
              tabFocus--;
              // If we're at the start, move to the end
              if (tabFocus < 0) {
                tabFocus = $tabs.length - 1;
              }
            }
      
            $tabs[tabFocus].setAttribute("tabindex", 0);
            $tabs[tabFocus].focus();
          }
        });
 
        $element = $(element);

        this.brand = $('body').data('brand');
		
		_this.$visitUsBtn = $(".Lifestyle .visit-us-btn");
		if (_this.$visitUsBtn && _this.$visitUsBtn.length) {
        _this.$visitUsBtn.on("click", function () {
            var communityId = $element.data("analytics-community-id");
            var communityBrand = $element.data("analytics-community-brand");
            AdobeLaunch.pushFormViewedEvent(
                AdobeLaunch.FORM_COMMUNITY_ID,
                AdobeLaunch.FORM_MODAL_TYPE,
                AdobeLaunch.FORM_REQUEST_INFO_NAME,
                communityId,
                communityBrand
            );
        });
		}
		
        this.manageTabindex = function (updatedIndex) {
          var $items = $('.Lifestyle__image .ls-carousel .carousel__item');
          // Reset tabindex for all relevant elements
          $items.find('*[tabindex]').attr('tabindex', '-1');
  
          // If updatedIndex is not provided, set tabindex for visible items
          if (updatedIndex === undefined || updatedIndex === null) {
            var $visibleItems = $('.Lifestyle__image .ls-carousel > .owl-stage-outer > .owl-stage > .owl-item.active:not(.cloned)');
            $visibleItems.find('*[tabindex]').attr('tabindex', '0');
  
            // Handle nested carousel
            $visibleItems.each(function () {
              $(this).find('.owl-item.active:not(.cloned) *[tabindex]').attr('tabindex', '0');
            });
  
            return;
          }
  
          // Set tabindex for active item
          var $activeItems = $items.eq(updatedIndex);
          $activeItems.find('*[tabindex]').attr('tabindex', '0');
  
          // Handle nested carousel for specific updatedIndex
          var $nestedActiveItems = $activeItems.find('.owl-item.active:not(.cloned)');
          $nestedActiveItems.find('*[tabindex]').attr('tabindex', '0');
          $nestedActiveItems.siblings().find('*[tabindex]').attr('tabindex', '-1');

        }.bind(this);

        var $owlCarousel = this.$element.find('.ls-carousel');

        $owlCarousel.owlCarousel({
            lazyLoad: true,
            loop: true,
            dots: false,
            margin: 10,
            autoHeight: true,
            nav: false,
            responsive: {
                0: {
                    items: 1,
                    touchDrag: false
                }
            },
            onRefreshed: function(){
                setTimeout(this.manageTabindex, 100)
            }.bind(this) 
        });

        // Event handling for slider navigation
        this.$element.find(".slider-nav__next, .slider-nav__prev").on("click", function (event) {
            event.stopPropagation();
            $owlCarousel.trigger((this.className.includes("next") ? "next" : "prev") + ".owl.carousel");
        });

         // Update tabindex based on active item
         $owlCarousel.on("changed.owl.carousel", function (event) {
          if (event.target === $owlCarousel.get(0)) {
              this.manageTabindex(event.item.index);
          }
         }.bind(this));

       $owlCarousel.on('translated.owl.carousel', function() {
        this.checkOverflowVisibility($owlCarousel);
       }.bind(this));

       this.$element.on('click', 'a.popover-close-btn', function (e) {
        e.preventDefault();
        $("[data-toggle=popover]").popover('hide');
      });

      this.$element.on('hidden.bs.popover', function () {
        $(".popover").remove();
      })

        customTabs.createCustomTabEvents(this.$element);

        $(window).on("resize", utils.debounce(this.handleWindowResize.bind(this), 250, false));
       
    }

    this.handleWindowResize = function(){
      var $carousels = this.$element.find(".carousel-2-wrapper"); 
      $carousels.find(".owl-carousel").trigger("refresh.owl.carousel");

      if ($(window).outerWidth() < 1025) {
        $('.slider-nav__next').attr('data-analytics-cta',$('.ls-carousel .owl-item.active .carousel__item .info__heading')[0].innerHTML + "|next-button");
        $('.slider-nav__next').attr('data-analytics',$('.ls-carousel .owl-item.active .carousel__item .info__heading')[0].innerHTML + "|next-button");    

        $('.slider-nav__prev').attr('data-analytics-cta',$('.ls-carousel .owl-item.active .carousel__item .info__heading')[0].innerHTML + "|prev-button");
        $('.slider-nav__prev').attr('data-analytics',$('.ls-carousel .owl-item.active .carousel__item .info__heading')[0].innerHTML + "|prev-button");  
      }
    }

    this.checkOverflowVisibility = function($owlCarousel) {
      // Find if the current visible item contains the visit-us-container
      var $owlStageOuter = $owlCarousel.find('> .owl-stage-outer');
      var $containsVisitUsContainer = false;
      $owlCarousel.find('.owl-item.active').each(function() {
          if ($(this).find('.visit-us-container').length) {
              $containsVisitUsContainer = true;
              return false; // Break out of the loop
          }
      });

      $containsVisitUsContainer
          ? $owlStageOuter.addClass("overflow-visible")
          : $owlStageOuter.removeClass("overflow-visible");
  }
}

module.exports = component(Lifestyle);
