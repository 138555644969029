/**
 * Constants (events and classes) used by form components.
 * @type {Object}
 */

module.exports = {
    // classes
    ERROR_CLASS: 'has-error',
    EMPTY_CLASS: 'is-empty',
    DIRTY_CLASS: 'is-dirty',
    MESSAGE_ERROR_CLASS: 'Form__message--error',
    MESSAGE_SUCCESS_CLASS: 'Form__message--success',

    // events
    FIELD_INIT: 'field-init',
    FIELD_CHECKED: 'field-checked',
    FORM_CHANGED: 'form-changed',
    MODAL_FORM_CHANGED: 'change',
    FORM_AJAX_SUBMIT: 'form-ajax-submit',
    FOCUS_OUT: 'focusout',
    BLUR: 'blur',
    REGION_SELECTED: 'region-selected'
};
