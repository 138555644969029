var component = require('../../../lib/js/component.js');
var Form = require('../forms/form.js');
var constants = require('../forms/constants.js');
var AdobeLaunch = require('../../../lib/js/adobelaunch.js');
var utils = require('../../../lib/js/utils.js');

var LoginEditorial = function () {

    this.initialize = function (el, options) {
        this.$loginPage = this.$element.find('.LoginEditorial__login');
        this.$createAccountPage = this.$element.find('.LoginEditorial__createAccount');
        this.$createAccountPageForm = this.$element.find('.LoginEditorial__createAccountForm');
        this.$homeownerRegistrations = this.$element.find('.LoginEditorial__homeownerRegistration');
        this.$homeownerRegistrationFields = this.$homeownerRegistrations.find('input');
        this.$homeownerBtns = this.$element.find("input[type='radio'][name='isHomeowner']");
        this.$notHomeownerBtn = this.$element.find("input[type='radio'][name='isHomeowner'][value='false']");
        this.$skipRegistrationModal = this.$element.find('.LoginEditorial__createAccountModal');
        this.$skipButton = this.$element.find('.LoginEditorial__button-skip');
        this.$resubmitButton = this.$element.find('.LoginEditorial__button-resubmit');
        this.$createAccountButton = this.$element.find("input[type='submit'][value='Create Account']");

        this.$loginPage.find('.LoginEditorial__loginForm')
            .attach(Form)
            .on(constants.FORM_AJAX_SUBMIT, this.handleLoginFormSubmision.bind(this));

        this.$createAccountPageForm
            .attach(Form)
            .on(constants.FORM_AJAX_SUBMIT, this.handleCreateAccountFormSubmission.bind(this));

        this.$skipButton.on('click', this.skipRegistration.bind(this));
        this.$resubmitButton.on('click', this.enableSubmission.bind(this));

        this.$element.find('.LoginEditorial__openCreateAccount').on('click', this.switchToCreate.bind(this));
        this.$element.find('.LoginEditorial__openLogin').on('click', this.switchToLogin.bind(this));
        this.$homeownerBtns.on('change', this.updateRegistrationFieldsDisplay.bind(this));
        this.$homeownerBtns.on('focus', this.homeownerBtnOnFocus);
        this.$homeownerBtns.on('blur', this.homeownerBtnOnBlur);

        var $createError = this.$element.find('.LoginEditorial__createAccountError');
        if ($createError.length > 0) {
            this.switchToCreate();
        }
        
        // show the login/signup pages based on the url query params
        var params = new URLSearchParams(window.location.search);
        var showLoginPage = params.get("isLogin") !== "false";
        if (showLoginPage) {
            this.switchToLogin();
        } else {
             this.switchToCreate();
        }
    };

    this.handleLoginFormSubmision = function (event, form) {
        $.ajax({
            method: form.options.method,
            url: form.options.action,
            data: form.data,
            dataType: "json"
        }).done(function (data) {
            if (data) {
                if (data.Success && data.UserId) {
                    AdobeLaunch.pushUserEvent(AdobeLaunch.USER_SIGNED_IN_EVENT_NAME, data.UserId);
                }

                location.href = data.RedirectUrl;
            }
        })
    };

    this.handleCreateAccountFormSubmission = function (event, form) {
        $.ajax({
            method: form.options.method,
            url: form.options.action,
            data: form.data,
            dataType: "json",
            context: this
        }).done(function (data) {
            if (data) {
                if (data.Success && data.UserId) {
                    AdobeLaunch.pushUserEvent(AdobeLaunch.USER_REGISTERED_EVENT_NAME);
                }

                if(data.PromptUserToSkipHomeRegistration) {
                    this.$skipRegistrationModal.modal('show');
                } else {
                    location.href = data.RedirectUrl;
                }
            }
        })
    };

    this.switchToCreate = function () {
        this.$loginPage.hide();
        this.$createAccountPage.show();

        utils.scrollToElement(this.$createAccountPage);
    };

    this.switchToLogin = function () {
        this.$createAccountPage.hide();
        this.$loginPage.show();

        utils.scrollToElement(this.$loginPage);
    };

    this.updateRegistrationFieldsDisplay = function (event) {
        var $selectedBtn = $(event.currentTarget);

        if ($selectedBtn.is(':checked') && $selectedBtn.val() === 'false') {
          this.$homeownerRegistrations.hide();
          this.$homeownerRegistrationFields.prop('disabled', true);
        }
        else {
          this.$homeownerRegistrations.show();
          this.$homeownerRegistrationFields.prop('disabled', false);
        }
    };

    this.homeownerBtnOnFocus = function(event) {
        var $focusedLabel = $(event.currentTarget).parent();
        $focusedLabel.addClass('focus');
    };

    this.homeownerBtnOnBlur = function(event) {
        var $blurredLabel = $(event.currentTarget).parent();
        $blurredLabel.removeClass('focus');
    };

    this.skipRegistration = function() {
        this.$notHomeownerBtn.prop("checked", true);
        this.$createAccountPageForm.submit();
    };

    this.enableSubmission = function() {
        this.$createAccountButton.prop('disabled', false);
    };
};

module.exports = component(LoginEditorial);
