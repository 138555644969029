var component = require('../../../lib/js/component.js');
var utils = require('../../../lib/js/utils.js');

function FindAHomeFilter() {

    var viewModel = function (vmData, placeholderTexts) {
        var self = this;
        var statePlaceholderText = 'Select a state';
        var regionPlaceholderText = 'Select a region';
        var cityPlaceholderText = 'Select a city';
        self.isGlobalNavLocation = vmData.isGlobalNavLocation;
        self.isFilterLocation = vmData.isFilterLocation;
        self.isHeroLocation = vmData.isHeroLocation;
        self.locationFilterDropdown = $('.pfCriteria--location');
        self.findAHomeFilterGlobalNav = $('.FindAHomeFilter--GlobalNav');
        self.cityDropdownContent = vmData.cityDropdownContent;

        self.defaultState = vmData.defaultState ? vmData.defaultState : '';
        self.defaultRegion = vmData.defaultRegion ? vmData.defaultRegion : '';
        self.defaultCity = vmData.defaultCity ? vmData.defaultCity : '';

        if (placeholderTexts) {
            if (placeholderTexts.state && placeholderTexts.state.length > 0) {
                statePlaceholderText = placeholderTexts.state;
            }

            if (placeholderTexts.region && placeholderTexts.region.length > 0) {
                regionPlaceholderText = placeholderTexts.region;
            }

            if (placeholderTexts.city && placeholderTexts.city.length > 0) {
                cityPlaceholderText = placeholderTexts.city;
            }
        }

        self.dataCache = vmData;

        self.criteria = {};

        self.addCriterion = function (data, key, defaultValue, css) {
            var criterion = {};

            criterion.choices = ko.observableArray(data);
            criterion.defaultValue = defaultValue;
            criterion.cssClass = css;
            criterion.savedChoices = ko.observableArray([]);
            criterion.savedDataKey = -1;
            criterion.selectedChoices = ko.observableArray([]);
            criterion.isDefaultValue = ko.computed(function () {
                if (criterion.selectedChoices().length > 0) {
                    if (criterion.selectedChoices()[0].value !== criterion.defaultValue) {
                        return false;
                    }
                }
                return true;
            });

            (key === 'city') ? criterion.isMultiple = true : criterion.isMultiple = false;

            criterion.selectedValue = ko.computed(function () {
                if (criterion.selectedChoices().length > 0) {
                    return criterion.selectedChoices()[0].value;
                } else {
                    return criterion.defaultValue;
                }
            });

            self.criteria[key] = criterion;
        }

        self.addCriterion([], "state", statePlaceholderText, '.FindAHomeFilter--Dropdown-state');
        self.addCriterion([], "region", regionPlaceholderText, '.FindAHomeFilter--Dropdown-region');
        self.addCriterion([], "city", cityPlaceholderText, '.FindAHomeFilter--Dropdown-city');

        self.populateCriterionChoices = function (criterion, choices, key) {
            criterion.choices([]);

            if (choices) {
                for (var i = 0; i < choices.length; i++) {
                    var choice = {};

                    if (key === "state") {
                        choice.value = choices[i].StateName;
                        choice.valueSEO = self.removeSpecialChar(choice.value);
                    } else if (key === "region") {
                        choice.value = choices[i].RegionName;
                        choice.valueSEO = self.removeSpecialChar(self.dataCache.currentState) + "/" + self.removeSpecialChar(choice.value);
                    } else { //city
                        choice.value = choices[i].CityName;
                        choice.valueSEO = self.removeSpecialChar(self.dataCache.currentState) + "/" + self.removeSpecialChar(self.dataCache.currentRegion) + "/" + self.removeSpecialChar(choice.value);
                    }

                    // store a string that can be used to properly construct a URL based on the name of the current choice
                    choice.urlName = self.removeSpecialChar(choice.value);
                    //--- passing link to improve SEO  
                    choice.urlNameSEO = "/homes/" + self.removeSpace(choice.valueSEO);

                    choice.isSelected = ko.observable(false);
                    choice.dataKey = i;
                    criterion.choices.push(choice);
                }
            }

            criterion.selectedChoices([]);

            if ((key === "region") && (choices.length === 1)) {
                self.processSelection(key, criterion.choices()[0]);
            }

            $('ul.' + key).children().attr('id', function () {
                return key + "-" + this.innerText.trim().replace(/[/.\s]/g, '');
            });
        }

        self.removeSpecialChar = function (link) {
            return link.replace(/[./']/g, "").replace(/ /g, "-").toLowerCase();
        }
        self.removeSpace = function (link) {
            return link.replace(/ /g, "-").toLowerCase();
        }

        self.populateCriterionChoices(self.criteria["state"], vmData.States, "state");

        self.getFindDataPacket = function () {

            var data = {
                "selectionSource": "location",
                "state": "",
                "region": "",
                "cityNames": ""
            }

            if (self.criteria['state'].savedChoices().length > 0) data.state = self.criteria['state'].savedChoices()[0].value;
            if (self.criteria['region'].savedChoices().length > 0) data.region = self.criteria['region'].savedChoices()[0].value;
            for (var i = 0; i < self.criteria['city'].savedChoices().length; i++) {
                if (i === 0) {
                    data.cityNames = self.criteria['city'].savedChoices()[i].value;
                } else {
                    data.cityNames += "," + self.criteria['city'].savedChoices()[i].value;
                }
            }

            return data;

        }

        self.copyToSave = function (selectedChoices, savedChoices) {

            savedChoices([]);

            for (var i = 0; i < selectedChoices().length; i++) {
                var choice = {};
                choice.value = selectedChoices()[i].value;
                savedChoices.push(choice);
            }

        }

        self.getSelectedCityCombinedTitle = function (defaultValue) {

            var newName = "";

            if (self.criteria['city'].choices().length > 0) {
                self.criteria['city'].choices().forEach(function (e) {
                    if (e.isSelected()) {
                        if (newName.length > 0) {
                            newName += ", " + e.value;
                        } else {
                            newName = e.value;
                        }
                    }
                });
                if (newName) {
                    return newName;
                } else {
                    return defaultValue;
                }
            } else {
                return defaultValue;
            }
        }

        self.issearchHomeDisabled = ko.computed(function () {
            return (self.criteria['state'].selectedChoices().length === 0);
        });

        self.isstateDisabled = ko.computed(function () {
            return false;
        });

        self.isregionDisabled = ko.computed(function () {
            return (self.criteria['state'].selectedChoices().length === 0);
        });

        self.iscityDisabled = ko.computed(function () {
            return (self.criteria['region'].selectedChoices().length === 0);
        });

        self.criteria["city"].selectedValue = ko.computed(function () {
            return self.getSelectedCityCombinedTitle(self.criteria['city'].defaultValue);
        });

        self.regionColumns = ko.pureComputed(function () {
            return self.criteria['region'].choices().length <= 1 ? 'FindAHomeFilter--Dropdown-maxCol1' : 'FindAHomeFilter--Dropdown-maxCol2';
        });

        self.cityColumns = ko.pureComputed(function () {
            var columnClass = '';

            if (self.criteria['city'].choices().length <= 1) {
                columnClass = 'FindAHomeFilter--Dropdown-maxCol1';
            } else if (self.criteria['city'].choices().length <= 2) {
                columnClass = 'FindAHomeFilter--Dropdown-maxCol2';
            } else {
                columnClass = 'FindAHomeFilter--Dropdown-maxCol3';
            }

            return columnClass;
        });

        self.toggleOpenCriterion = function (item, event) {
            event.stopPropagation();

            var $element = $(event.target).closest('.FindAHomeFilter--Dropdown');
            var $body = $('body');
            var $button = $element.children('.FindAHomeFilter--DropdownButton');

            if ($element.hasClass('is-disabled')) {
                return;
            }

            $element.toggleClass('is-open');

            if ($button.attr('aria-expanded') === 'false') {
                $(".FindAHomeFilter--DropdownButton").attr('aria-expanded', 'false');
                $button.attr('aria-expanded', 'true');
            } else {
                $button.attr('aria-expanded', 'false');
            }

            if (self.isHeroLocation && $element.hasClass('is-open') && !$body.hasClass('hero-location-dropdown-is-open')) {
                $body.addClass('hero-location-dropdown-is-open');
            } else if (self.isHeroLocation && !$element.hasClass('is-open') && $body.hasClass('hero-location-dropdown-is-open')) {
                $body.removeClass('hero-location-dropdown-is-open');
            }

            if ((self.isFilterLocation || self.isGlobalNavLocation) && self.cityDropdownContent && self.cityDropdownContent.length
                && $element.hasClass('FindAHomeFilter--Dropdown-city')
                && self.cityDropdownContent.css('position').toLowerCase() != 'fixed') {

                self.resetCityDropdownDisplay();
                self.adjustCityDropdownDisplay();
            }

            if (self.isGlobalNavLocation) {
                var $globalHeader = $element.closest('.GlobalHeader.is-open');

                if ($globalHeader && $globalHeader.length) {
                    if ($element.hasClass('is-open')) {
                        $globalHeader.addClass('GlobalHeader--FindAHomeFilterDropdown-is-open');
                    } else {
                        $globalHeader.removeClass('GlobalHeader--FindAHomeFilterDropdown-is-open');
                    }
                }

                // Keep dropdown open if user interacted with location selection
                var $dropdown = $(event.target).closest('.GlobalNav-dropdownHover');

                if ($dropdown && $dropdown.length && !$dropdown.hasClass('GlobalNav-dropdownHover--keepOpen')) {
                    $dropdown.addClass('GlobalNav-dropdownHover--keepOpen');
                }
            }

            $element.parent().siblings().find('.FindAHomeFilter--Dropdown').removeClass('is-open');
            $element.siblings().removeClass('is-open'); // for range
        }

        self.saveLocations = function () {

            for (var key in self.criteria) {

                var criterion = self.criteria[key];

                if (criterion.selectedChoices().length > 0) {
                    self.copyToSave(criterion.selectedChoices, criterion.savedChoices);
                } else {
                    criterion.savedChoices([]);
                }

            }

            self.updateProductFilter();

        }

        self.saveLocationsWithoutFilterUpdate = function () {

            for (var key in self.criteria) {

                var criterion = self.criteria[key];

                if (criterion.selectedChoices().length > 0) {
                    self.copyToSave(criterion.selectedChoices, criterion.savedChoices);
                } else {
                    criterion.savedChoices([]);
                }

            }
        }

        self.processSelection = function (key, selectedChoice) {
            var criterion = self.criteria[key];

            if (selectedChoice) {
                if (criterion.isMultiple) {
                    if (selectedChoice.isSelected()) {
                        selectedChoice.isSelected(false);
                        criterion.selectedChoices.remove(selectedChoice)
                    } else {
                        selectedChoice.isSelected(true);
                        criterion.selectedChoices.push(selectedChoice);
                    }
                } else {
                    if (!selectedChoice.isSelected()) {
                        selectedChoice.isSelected(true);
                        if (criterion.selectedChoices().length > 0) {
                            criterion.selectedChoices()[0].isSelected(false);
                            criterion.selectedChoices.replace(criterion.selectedChoices()[0], selectedChoice);
                        } else {
                            criterion.selectedChoices.push(selectedChoice);
                        }

                        //populate Other choices
                        if (key === 'state') {
                            self.dataCache.currentState = selectedChoice.urlName;
                            self.criteria['city'].selectedChoices([]);
                            self.criteria['city'].choices([]);
                            self.populateCriterionChoices(self.criteria["region"], self.dataCache.States[criterion.selectedChoices()[0].dataKey].Regions, "region");

                        } else if (key === 'region') {
                            self.dataCache.currentRegion = selectedChoice.urlName;
                            self.populateCriterionChoices(self.criteria["city"], self.dataCache.States[self.criteria['state'].selectedChoices()[0].dataKey].Regions[criterion.selectedChoices()[0].dataKey].Cities, "city")
                        }
                    }
                } // not multiple
            }
        }

        self.selectChoice = function (selectedChoice, event) {
            event.preventDefault();
            var $element = $(event.target);
            var $parent = $element.parent();
            setAriaSelectedOption($parent);

            var $checkbox = $parent.find('.FindAHomeFilter--DropdownItem--cityItem');

            if ($checkbox.length > 0) { // if there is a checkbox input, ie the city dropdown items
                var checked = $checkbox.is(':checked');
                $checkbox.toggle(!checked);
                $checkbox.attr('aria-checked', !checked);
                $checkbox.attr('checked', !checked);
            }

            for (var key in self.criteria) {
                // match if this criterion matches the one clicked in 'event'
                if ($(event.target).parents(self.criteria[key].cssClass).length > 0) {
                    self.processSelection(key, selectedChoice);
                    
                    if($checkbox.length) {
                        $checkbox.trigger('focus');
                    } else {
                        $parent.parents('.FindAHomeFilter--Dropdown')
                            .siblings('.FindAHomeFilter--Dropdown-city')
                            .trigger('regionChange');
                        self.toggleOpenCriterion(selectedChoice, event);
                        $element.parents('.FindAHomeFilter--DropdownContent').siblings('.FindAHomeFilter--DropdownButton').focus();
                    }

                    return;
                }
            }

            if($checkbox.length) {
                $checkbox.trigger('focus');
            } else {
                $element.parents('.FindAHomeFilter--DropdownContent').siblings('.FindAHomeFilter--DropdownButton').focus();
            }
        }

        self.emitLocationChange = function (state, region, cities) {

            //if it is not location page, alway emit false
            if (self.isHeroLocation) return false;
            if (self.isGlobalNavLocation && $('.ploc-listener').length === 0) return false;

            // performing null checks specifically because empty strings return false
            if (state != null && self.defaultState != null && state.toLowerCase() !== self.defaultState.toLowerCase()) return false;
            if (state != null && self.defaultRegion != null && region.toLowerCase() !== self.defaultRegion.toLowerCase()) return false;
            if (state != null && self.defaultCity != null) {
                if ((cities.length === 0 || cities.indexOf(",") >= 0) && (self.defaultCity.length === 0 || self.defaultCity.indexOf(",") >= 0)) {
                    return true;
                } else if (cities.toLowerCase() === self.defaultCity.toLowerCase()) {
                    return true;
                } else {
                    return false;
                }
            }

            return true;
        }

        self.searchHomes = function () {
            // close Global Nav menu
            if (self.isGlobalNavLocation) {
                var $hoverMenuLockedOpen = $('.GlobalNav-dropdownHover--keepOpen');
                var $hoverMenuDynamic = $('.GlobalNav-dropdownHover--dynamic');

                if ($hoverMenuLockedOpen && $hoverMenuLockedOpen.length) {
                    $hoverMenuLockedOpen.removeClass('GlobalNav-dropdownHover--keepOpen');
                }

                if ($hoverMenuDynamic && $hoverMenuDynamic.length) {
                    $hoverMenuDynamic.addClass('GlobalNav-dropdownHover--close');
                }
            }

            self.closeLocationFilter();

            var state = '';
            var stateValue = '';
            var region = '';
            var regionValue = '';
            var cities = '';
            var citiesValue = '';
            var citiesParams = '';
            var multipleCities = false;
            
            var $selectedCities = self.cityDropdownContent.find('.FindAHomeFilter--DropdownChoice')
                .filter('.is-selected');

            if($selectedCities.length) {
                var urlNames = [];
                var values = [];

                $selectedCities.each(function() {
                    var $this = $(this);
                    values.push($this.text());
                    urlNames.push($this.text().toLowerCase().replace(' ', '-'));
                })

                cities = urlNames.join(",");
                citiesValue = values.join(",");
                multipleCities = $selectedCities.length > 1;
            }

            for (var key in self.criteria) {

                if (self.criteria[key] !== null && self.criteria[key].selectedChoices().length > 0) {
                    if (key === 'state') {
                        state = self.criteria[key].selectedChoices()[0].urlName;
                        stateValue = self.criteria[key].selectedChoices()[0].value;
                    }
                    else if (key === 'region') {
                        region = self.criteria[key].selectedChoices()[0].urlName;
                        regionValue = self.criteria[key].selectedChoices()[0].value;
                    }
                }
            }

            var newLocation = '';
            if (state && state.length > 0) {
                newLocation = "/homes" + "/" + state;

                if (region && region.length > 0) {
                    newLocation = newLocation + "/" + region;

                    if (cities && cities.length > 0) {
                        if (multipleCities) {
                            citiesParams = "?cities=" + citiesValue;
                            newLocation = newLocation + citiesParams;
                        }
                        else {
                            newLocation = newLocation + "/" + cities;
                        }
                    }
                }
            }

            if (self.emitLocationChange(stateValue, regionValue, citiesValue)) {
                var plocData = {
                    "state": stateValue,
                    "region": regionValue,
                    "cities": citiesValue
                }

                if (multipleCities) {
                    history.replaceState({}, '', citiesParams);
                } else {
                    history.replaceState({}, '', window.location.pathname);
                }

                self.saveLocations();

                $('.ProductFilter').trigger('updateAttributeRanges', self.getFindDataPacket());
                $('.ProductMap--Totals').trigger("pmap-resetTotals");
                $('.ploc-listener').trigger("ploc-change", plocData);

                if (self.isGlobalNavLocation) {
                    if (self.locationFilterDropdown && self.locationFilterDropdown.length) {
                        self.locationFilterDropdown.trigger('set-filter-location-dropdowns', plocData);
                    }
                } else if (self.isFilterLocation) {
                    if (self.findAHomeFilterGlobalNav && self.findAHomeFilterGlobalNav.length) {
                        self.findAHomeFilterGlobalNav.trigger('set-global-nav-location-dropdowns', plocData);
                    }
                }

            } else {
                window.location.href = newLocation;
            }
        }

        self.cancelLocationFilter = function () {
            if (self.locationFilterDropdown && self.locationFilterDropdown.length > 0) {
                self.locationFilterDropdown.trigger('ploc-cancel');
            }
        }

        self.closeLocationFilter = function () {
            if (self.locationFilterDropdown && self.locationFilterDropdown.length > 0) {
                self.locationFilterDropdown.trigger('ploc-close');
            }
        }

        self.setIntialLocation = function () {
            var initialState = self.defaultState;
            var initialRegion = self.defaultRegion;
            var initialCity = self.defaultCity;
            var isQMI = utils.hasHashValue('qmi');
            var i = 0;

            if (initialState && initialState.length > 0) {

                for (i = 0; i < self.criteria['state'].choices().length; i++) {
                    if (initialState === self.criteria['state'].choices()[i].value) {
                        self.processSelection('state', self.criteria['state'].choices()[i]);
                        break;
                    }
                }

                if (initialRegion && initialRegion.length > 0) {

                    for (i = 0; i < self.criteria['region'].choices().length; i++) {
                        if (initialRegion === self.criteria['region'].choices()[i].value) {
                            self.processSelection('region', self.criteria['region'].choices()[i]);
                            break;
                        }
                    }

                    if (initialCity && initialCity.length > 0) {
                        var cities = [];

                        if (initialCity.indexOf(",") >= 0) {
                            cities = initialCity.split(',');
                        }

                        if (cities && cities.length > 0) {
                            for (x = 0; x < cities.length; x++) {
                                for (i = 0; i < self.criteria['city'].choices().length; i++) {
                                    if (cities[x] === self.criteria['city'].choices()[i].value) {
                                        self.processSelection('city', self.criteria['city'].choices()[i]);
                                        break;
                                    }
                                }
                            }
                        } else {
                            for (i = 0; i < self.criteria['city'].choices().length; i++) {
                                if (initialCity === self.criteria['city'].choices()[i].value) {
                                    self.processSelection('city', self.criteria['city'].choices()[i]);
                                    break;
                                }
                            }
                        }
                    } // city

                } // region

            } // state

            setTimeout(function () {
                self.saveLocations();
            }, 2000);
        }

        self.setIntialLocation();

        self.setLocationDropdowns = function (e, plocData) {
            var state = plocData.state;
            var region = plocData.region;
            var city = plocData.cities;
            var isQMI = utils.hasHashValue('qmi');
            var i = 0;

            if (state && state.length > 0) {

                for (i = 0; i < self.criteria['state'].choices().length; i++) {
                    if (state === self.criteria['state'].choices()[i].value) {
                        self.processSelection('state', self.criteria['state'].choices()[i]);
                        break;
                    }
                }

                if (region && region.length > 0) {

                    for (i = 0; i < self.criteria['region'].choices().length; i++) {
                        if (region === self.criteria['region'].choices()[i].value) {
                            self.processSelection('region', self.criteria['region'].choices()[i]);
                            break;
                        }
                    }

                    self.clearSelectedCities();

                    if (city && city.length > 0) {
                        var cities = [];

                        if (city.indexOf(",") >= 0) {
                            cities = city.split(',');
                        }

                        if (cities && cities.length > 0) {
                            for (x = 0; x < cities.length; x++) {
                                for (i = 0; i < self.criteria['city'].choices().length; i++) {
                                    if (cities[x] === self.criteria['city'].choices()[i].value) {
                                        self.processSelection('city', self.criteria['city'].choices()[i]);
                                        break;
                                    }
                                }
                            }
                        } else {
                            for (i = 0; i < self.criteria['city'].choices().length; i++) {
                                if (city === self.criteria['city'].choices()[i].value) {
                                    self.processSelection('city', self.criteria['city'].choices()[i]);
                                    break;
                                }
                            }
                        }
                    } // city

                } // region

            } // state

            setTimeout(function () {
                self.saveLocationsWithoutFilterUpdate();
            }, 2000);
        }

        self.clearSelectedCities = function () {
            for (var i = 0; i < self.criteria['city'].choices().length; i++) {
                for (var x = 0; x < self.criteria['city'].selectedChoices().length; x++) {
                    if (self.criteria['city'].selectedChoices()[x] === self.criteria['city'].choices()[i]) {
                        self.criteria['city'].selectedChoices()[x].isSelected(false);
                        self.criteria['city'].selectedChoices.remove(self.criteria['city'].choices()[i]);
                        break;
                    }
                }
            }
        }

        self.updateProductFilter = function () {
            if (self.locationFilterDropdown && self.locationFilterDropdown.length > 0) {
                self.locationFilterDropdown.trigger('ploc-change-choices', self.criteria);
            }
        }

        self.loadSavedCriteria = function () {
            // Load Saved Criteria
            // state
            var stateIsSaved = false;
            var i = 0;
            self.criteria['state'].selectedChoices([]);
            for (i = 0; i < self.criteria['state'].choices().length; i++) {
                stateIsSaved = false;
                if (self.criteria['state'].savedChoices().length > 0) {
                    stateIsSaved = (self.criteria['state'].choices()[i].value === self.criteria['state'].savedChoices()[0].value);
                }
                self.criteria['state'].choices()[i].isSelected(stateIsSaved);
                if (stateIsSaved) {
                    self.criteria['state'].selectedChoices.push(self.criteria['state'].choices()[i]);
                    self.populateCriterionChoices(self.criteria["region"], self.dataCache.States[self.criteria['state'].selectedChoices()[0].dataKey].Regions, "region");
                }
            }

            // region
            var regionIsSaved = false;
            self.criteria['region'].selectedChoices([]);
            for (i = 0; i < self.criteria['region'].choices().length; i++) {
                regionIsSaved = false;
                if (self.criteria['region'].savedChoices().length > 0) {
                    regionIsSaved = (self.criteria['region'].choices()[i].value === self.criteria['region'].savedChoices()[0].value);
                }
                self.criteria['region'].choices()[i].isSelected(regionIsSaved);
                if (regionIsSaved) {
                    self.criteria['region'].selectedChoices.push(self.criteria['region'].choices()[i]);
                    self.populateCriterionChoices(self.criteria["city"], self.dataCache.States[self.criteria['state'].selectedChoices()[0].dataKey].Regions[self.criteria['region'].selectedChoices()[0].dataKey].Cities, "city");
                }
            }

            // city
            var cityIsSaved = false;
            self.criteria['city'].selectedChoices([]);
            for (i = 0; i < self.criteria['city'].choices().length; i++) {
                cityIsSaved = false;
                for (x = 0; x < self.criteria['city'].savedChoices().length; x++) {
                    cityIsSaved = (self.criteria['city'].choices()[i].value === self.criteria['city'].savedChoices()[x].value);
                    if (cityIsSaved) break;
                }
                self.criteria['city'].choices()[i].isSelected(cityIsSaved);
                if (cityIsSaved) {
                    self.criteria['city'].selectedChoices.push(self.criteria['city'].choices()[i]);
                }
            }
        }

        self.resetCityDropdownDisplay = function () {
            if (self.cityDropdownContent && self.cityDropdownContent.length) {
                self.cityDropdownContent.css('height', 'auto');
                self.cityDropdownContent.css('overflow-y', 'auto');
            }
        }

        self.adjustCityDropdownDisplay = function () {
            if (self.cityDropdownContent && self.cityDropdownContent.length) {
                var cityElBottom = 0, windowBottom = 0;
                var $cityDropdown = self.cityDropdownContent.parent();

                if ($cityDropdown && $cityDropdown.length && $cityDropdown.hasClass('is-open')) {

                    cityElBottom = self.cityDropdownContent.offset().top + self.cityDropdownContent.height();
                    windowBottom = $(window).scrollTop() + $(window).height();

                    var $productFilter = $('.ProductFilter');

                    if ($productFilter && $productFilter.length && $productFilter.hasClass('affix')) {
                        if (cityElBottom > windowBottom) {
                            self.cityDropdownContent.css('height', windowBottom - self.cityDropdownContent.offset().top + 'px');
                            self.cityDropdownContent.css('overflow-y', 'scroll');
                        }
                    } else if (self.isGlobalNavLocation) {
                        if (cityElBottom > windowBottom) {
                            self.cityDropdownContent.css('height', windowBottom - self.cityDropdownContent.offset().top + 'px');
                            self.cityDropdownContent.css('overflow-y', 'scroll');
                        }
                    } else {
                        self.resetCityDropdownDisplay();
                    }
                }
            }
        }

        // listen for scrolling to adjust city dropdown
        if ((self.isFilterLocation || self.isGlobalNavLocation) && self.cityDropdownContent && self.cityDropdownContent.length) {
            $(window).on('scroll', function () {
                // Redumentary check for xs responsive breakpoint
                if (self.cityDropdownContent.css('position').toLowerCase() != 'fixed') {
                    self.adjustCityDropdownDisplay();
                }
            });
        }

        // listen for product filter dropdown closing
        if (self.locationFilterDropdown && self.locationFilterDropdown.length > 0) {
            self.locationFilterDropdown.on('pf-cancel', self.loadSavedCriteria.bind(this));
        }

        // listen for global nav ploc changes to update filter ploc and vice versa
        if (self.isFilterLocation) {
            self.locationFilterDropdown.on('set-filter-location-dropdowns', self.setLocationDropdowns.bind(this));
        } else if (self.isGlobalNavLocation) {
            self.findAHomeFilterGlobalNav.on('set-global-nav-location-dropdowns', self.setLocationDropdowns.bind(this));
        }

        self.ariaKeyboardMovement = function (data, event) {
            var $element = $(event.target);
            var isExpanded = $element.attr('aria-expanded') === 'true' ? true : false;
            var $dropdownContent = $element.next().find('ul');
            var $currentOptionId = $dropdownContent.find(".is-selected").parent().attr('id');
            if($dropdownContent.hasClass('city')) {
                $currentOptionId = $dropdownContent.find('.is-focused').attr('id');
            }
            switch (event.key) {
                case 'ArrowDown':
                case 'Down':
                case 'ArrowRight':
                case 'Right':
                    if (!isExpanded) {
                        self.toggleOpenCriterion(data, event);
                        setAriaActiveDescendant($dropdownContent.children().first(), $dropdownContent, $element);
                    } else {
                        if ($currentOptionId === undefined) {
                            setAriaActiveDescendant($dropdownContent.children().first(), $dropdownContent, $element);
                        } else {
                            var $currentItem = $('#' + $currentOptionId);
                            var $nextItem = $currentItem.next();
                            if(!$nextItem.length) {
                                $nextItem = $dropdownContent.children().first();
                            }
                            setAriaActiveDescendant($nextItem, $dropdownContent, $element);
                        }
                    }
                    break;

                case 'ArrowUp':
                case 'Up':
                case 'ArrowLeft':
                case 'Left':
                    if (!isExpanded) {
                        self.toggleOpenCriterion(data, event);
                        setAriaActiveDescendant($dropdownContent.children().last(), $dropdownContent, $element);
                    } else {
                        if ($currentOptionId === undefined) {
                            setAriaActiveDescendant($dropdownContent.children().last(), $dropdownContent, $element);
                        } else {
                            $currentItem = $('#' + $currentOptionId);
                            var $previousItem = $currentItem.prev();
                            if(!$previousItem.length) {
                                $previousItem = $dropdownContent.children().last();
                            }
                            setAriaActiveDescendant($previousItem, $dropdownContent, $element);
                        }
                    }
                    break;

                case 'Home':
                    if (isExpanded) {
                        setAriaActiveDescendant($dropdownContent.children().first(), $dropdownContent, $element);
                    }
                    break;

                case 'End':
                    if (isExpanded) {
                        setAriaActiveDescendant($dropdownContent.children().last(), $dropdownContent, $element);
                    }
                    break;

                case 'Enter':
                    self.toggleOpenCriterion(data, event);
                    break;

                case 'Escape':
                    if (isExpanded) {
                        self.toggleOpenCriterion(data, event);
                    }
                    break;

                case 'Tab':
                    if (isExpanded) {
                        self.toggleOpenCriterion(data, event);
                    }
                    return true;
            }
        }

        function setAriaActiveDescendant(option, dropdown, $button) {
            if(option && dropdown && $button) {
                option.siblings().removeClass('is-focused');
                option.addClass('is-focused');
                dropdown.attr('aria-activedescendant', option.attr('id'));

                if(dropdown.hasClass('state') || dropdown.hasClass('region')) {
                    option.find('.FindAHomeFilter--DropdownChoice').trigger('click');
                    $button.trigger('click'); // To prevent dropdown closing.
                } else {
                    option.find('.FindAHomeFilter--DropdownItem--cityItem').trigger('focus');
                }
            }
        }

        function setAriaSelectedOption(option) {
            if (option.closest('ul').attr('aria-multiselect') === 'true') {
                if (option.attr('aria-selected') === 'true') {
                    option.attr('aria-selected', false);
                } else {
                    option.attr('aria-selected', true);
                }
            } else {
                option.siblings().attr('aria-selected', 'false');
                option.attr('aria-selected', true);
            }
        }

        function getAriaSelectedChoice(target, selectedElementText) {
            var $parent = target.parent()
            var currentCritera;
            if ($parent.hasClass('FindAHomeFilter--Dropdown-state')) {
                currentCritera = self.criteria['state'];
            } else if ($parent.hasClass('FindAHomeFilter--Dropdown-region')) {
                currentCritera = self.criteria['region'];
            } else {
                currentCritera = self.criteria['city'];
            }
            var selectedChoice;
            ko.utils.arrayForEach(currentCritera.choices(), function (choice) {
                if (choice.value === selectedElementText) {
                    selectedChoice = choice;
                }
            });
            return selectedChoice;
        }

    }// viewModel.selectChoice

    /**
     * Init for the Location Selection module
     * @param  {Object} element HTML element
     */
    this.initialize = function (element) {
        var placeholderTexts = {};
        var stateDropdown = this.$element.find('.FindAHomeFilter--Dropdown-state');
        var regionDropdown = this.$element.find('.FindAHomeFilter--Dropdown-region');
        var cityDropdown = this.$element.find('.FindAHomeFilter--Dropdown-city');
        var $cityDropdownContent = this.$element.find('.FindAHomeFilter--Dropdown-city .FindAHomeFilter--DropdownContent');
        var isGlobalNavLocation = false;
        var isFilterLocation = false;
        var isHeroLocation = false;

        if (this.$element.hasClass('FindAHomeFilter--GlobalNav')) {
            isGlobalNavLocation = true;
        } else if (this.$element.hasClass('FindAHomeFilter--Filter')) {
            isFilterLocation = true;
        } else if (this.$element.hasClass('FindAHomeFilter--Hero')) {
            isHeroLocation = true;
        }

        if (stateDropdown && stateDropdown.length > 0) {
            placeholderTexts.state = stateDropdown.data('placeholder-text');
        }

        if (regionDropdown && regionDropdown.length > 0) {
            placeholderTexts.region = regionDropdown.data('placeholder-text');
        }

        if (cityDropdown && cityDropdown.length > 0) {
            placeholderTexts.city = cityDropdown.data('placeholder-text');
        }

        var vm = FindAHomeFilterData.locations[0];
        vm.defaultState = this.$element.data('state');
        vm.defaultRegion = this.$element.data('region');
        vm.defaultCity = this.$element.data('city');
        vm.currentState = vm.defaultState;
        vm.currentRegion = vm.defaultRegion;
        vm.cityDropdownContent = $cityDropdownContent;
        vm.isGlobalNavLocation = isGlobalNavLocation;
        vm.isFilterLocation = isFilterLocation;
        vm.isHeroLocation = isHeroLocation;

        ko.applyBindings(new viewModel(vm, placeholderTexts), element);

        var filterRegex = /[/.\s]/g;
        $('ul.state').children().attr('id', function () {
            return "state-" + this.innerText.trim().replace(filterRegex, '');
        });
        $('ul.region').children().attr('id', function () {
            return "region-" + this.innerText.trim().replace(filterRegex, '');
        });
        $('ul.city').children().attr('id', function () {
            return "city-" + this.innerText.trim().replace(filterRegex, '');
        });

        this.$cityDropdownButton = cityDropdown.find('.FindAHomeFilter--DropdownButton');
        this.$cityDropdownContent = $cityDropdownContent;
        cityDropdown.on('regionChange', this.onRegionChange.bind(this));
        this.onRegionChange();
    }

    this.onRegionChange = function() {
        this.$cityListItems = this.$cityDropdownContent.find('.FindAHomeFilter--DropdownItem');
        this.$cityCheckboxes = this.$cityListItems.find('.FindAHomeFilter--DropdownItem--cityItem');
        this.$cityButtons = this.$cityListItems.find('.FindAHomeFilter--DropdownChoice');
        this.$cityCheckboxes.on('keydown', this.onCityCheckboxKeyDown.bind(this));
    }

    this.onCityCheckboxKeyDown = function(e) {
        this.cityIndex = this.$cityCheckboxes.index(e.target);

        switch(e.keyCode) {
            // spacebar
            case 32:
                e.preventDefault();
                this.toggleCitySelect();
                break;

            // enter
            case 13:
                e.preventDefault();
                this.$cityDropdownButton.trigger('click');
                this.$cityDropdownButton.trigger('focus');
                break;

            // right and down keys
            case 39:
            case 40:
                e.preventDefault();
                this.cityIndex++;
                this.setCityFocus();
                break;

            // left and up keys
            case 37:
            case 38:
                e.preventDefault();
                this.cityIndex--;
                this.setCityFocus();
                break;
        }
    }

    this.setCityFocus = function() {
        if(this.$cityCheckboxes.length) {
            if(this.cityIndex < 0) {
                this.cityIndex = this.$cityCheckboxes.length - 1;
            } else if(this.cityIndex >= this.$cityCheckboxes.length) {
                this.cityIndex = 0;
            }

            var $thisCheckbox = $(this.$cityCheckboxes[this.cityIndex]);
            var $thisParent = $thisCheckbox.parent();
            $thisParent.siblings().removeClass('is-focused');
            $thisParent.addClass('is-focused');
            $thisCheckbox.trigger('focus');
        }
    }

    this.toggleCitySelect = function() {
        if(this.$cityCheckboxes.length) {
            var $thisCityItem = $(this.$cityListItems[this.cityIndex]);
            var $thisCityCheckbox = $(this.$cityCheckboxes[this.cityIndex]);
            var $thisCityButton = $(this.$cityButtons[this.cityIndex]);
            var isChecked = $thisCityCheckbox.is(':checked');

            $thisCityItem.attr('aria-selected', !isChecked);
            $thisCityCheckbox.attr('aria-checked', !isChecked);
            $thisCityCheckbox.attr('checked', !isChecked);
            if(isChecked) {
                $thisCityButton.removeClass('is-selected');
            } else {
                $thisCityButton.addClass('is-selected');
            }

            this.updateCityDropdownValue();
            $thisCityCheckbox.trigger('focus');
        }        
    }

    this.updateCityDropdownValue = function() {
        var dropdownText = [];
        this.$cityButtons.filter('.is-selected').each(function() {
            dropdownText.push($(this).text());
        });
        this.$cityDropdownButton.find('.FindAHomeFilter--DropdownButton-value')
            .text(dropdownText.join(", "));
    }
}

module.exports = component(FindAHomeFilter);
