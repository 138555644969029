var component = require('../../../lib/js/component.js');
var utils = require('../../../lib/js/utils.js');
var savedItemService = require('../../../lib/js/savedItemsService.js');

function ProductFilterV2() {

    var koModel, koModelProductToggle = {};

    var viewModel = function(options) {
        var self = this;

        self.viewLookupTable = options.viewLookupTable
        self.productToggleOptions = options.productToggleOptions;
        self.filterRef = options.filterRef;
        self.viewObservable = {
            productType: ko.observable(options.productToggleOptions.communityToggleLabel),
            currentProductTotalCount: ko.observable(0),
            currentProductMatchCount: ko.observable(0),
            currentProductNoMatchCount: ko.observable(0),
            qmiMatchCount: ko.observable(0),
            communityMatchCount: ko.observable(0),
            totalCommunities: ko.observable(0),
            totalQMI: ko.observable(0)
        };

        self.toggleChecked = {
            community: ko.observable(true),
            qmi: ko.observable(false)
        }

        self.filterView = function(item) {
            self.filterRef.onApply(self.viewLookupTable[self.viewObservable.productType()]);
        }

        // Toggle between community and qmi view - current view stored in view observable object in product type - triggers an event and passes product to summary model to notify of change 
        self.toggleProduct = function(item, event){
            // this will need to be updated when we add the home options filter to the map sidebar
            var toggledLabel = $(event.target).attr("for");
            self.toggleChecked.community(toggledLabel == "r-Communities");
            self.toggleChecked.qmi(toggledLabel == "r-QMIs");
            var updatedToggle = self.toggleChecked.community() ? self.productToggleOptions.communityToggleLabel : self.productToggleOptions.qmiToggleLabel;
            self.viewObservable.productType(updatedToggle);

            $(".pf-toggle-listener")
                .trigger("pf-view-change", [self.viewLookupTable[self.viewObservable.productType()]])
                .trigger("compare-view-change", self.viewObservable.productType()); 
                
            $(".pfv2-listener")
                .trigger("pf-view-change", [self.viewLookupTable[self.viewObservable.productType()]]);

            savedItemService.setSavedStateForDocument();
        }

        self.resetFilter = function() {
            self.filterRef.onReset();
            self.filterRef.onApply(self.viewLookupTable[self.viewObservable.productType()]);
            self.filterRef.togglePriceFilter();
        }
    }

    this.initialize = function (element, options) {
        var self = this;

        var $filterBtn = this.$element.find(".ProductFilterV2__management-filter-btn");
        var $filterPriceBtn = this.$element.find(".ProductFilterV2__Price-management-filter-btn");
        var $mobileClose = $("#filterNavIconCloseMobile");
        var $toggleInputs = this.$element.find(".ProductFilterV2__management-toggles");
        var $bedroomInputs = $("#ProductFilterV2__management-rooms-toggles-bedrooms");
        var $bathroomInputs = $("#ProductFilterV2__management-rooms-toggles-bathrooms");
        var $mapToggleLabel = $("#r-map-label");
        var $galleryToggleLabel = $("#r-gallery-label");

        $filterBtn.on('click', this.toggleFilter.bind(this));
        $filterPriceBtn.on('click', this.togglePriceFilter.bind(this));
        $mobileClose.bind('click', this.togglePriceFilter.bind(this));
        $bedroomInputs.on('click', utils.debounce(this.toggleBedroomSelection.bind(this) , 250, false));
        $bathroomInputs.on('click', utils.debounce(this.toggleBathroomSelection.bind(this) , 250, false));

        var $filterCircle = this.$element.find(".ProductFilterV2__management-filter-circle");
        var $filterCloseIcon = $("#filterNavIconClose");
        var $PricefilterCloseIcon = $("#PricefilterNavIconClose");

        $filterCircle.addClass("hide");
        $filterCloseIcon.addClass("hide hide-xs");
        $PricefilterCloseIcon.addClass("hide hide-xs");

        $('#PricefilterNavIconClose, .ProductFilterV2__Price-management-filter-btn').on('click', function() {
            if(!$('.ProductSummaryV2__communityContainer-filtered').is(":visible")) {
                self.resetFilterValues();
            }
        });

        var $filterCircleColor = this.$element.find(".ProductFilterV2__management-filter-circle");
        $filterCircleColor.css({
            "background-color" :  $filterCircleColor.data("filterCircleColor"),
            "color" : $filterCircleColor.data("filterCircleTextColor")
        })

        var $filterContent = this.$element.find(".ProductFilterV2__management-filter-content");
        var $filterPriceContent = this.$element.find(".ProductFilterV2__Price-management-filter-content");

        if($filterContent.data("enableShadow") == "True"){
            $filterContent.css("box-shadow", "0 6px 7px 0 " + $filterContent.data("shadowColor"))
        }
        if($filterPriceContent.data("enableShadow") == "True"){
            $filterPriceContent.css("box-shadow", "0 6px 7px 0 " + $filterPriceContent.data("shadowColor"))
        }
        this.resetFilterValues()

        $(window).on("resize", utils.debounce(this.handleFilterLayout.bind(this), 250, false));

        var productToggleOptions = {
            communityToggleLabel: $toggleInputs.data("communityToggleLabel") ,
            selectedToggleBackgroundColor : $toggleInputs.data("selectedToggleFillColor"), 
            selectedToggleColor : $toggleInputs.data("selectedToggleTextColor"), 
            qmiToggleLabel: $toggleInputs.data("qmiToggleLabel"),
            unselectedToggleBackgroundColor : $toggleInputs.data("unselectedToggleFillColor"), 
            unselectedToggleColor : $toggleInputs.data("unselectedToggleTextColor")
        }

        var viewLookupTable = {}
        viewLookupTable[$toggleInputs.data("communityToggleLabel")] = 'community'
        viewLookupTable[$toggleInputs.data("qmiToggleLabel")] = 'qmi'

        var koModelOptions = {
            productToggleOptions: productToggleOptions,
            viewLookupTable: viewLookupTable,
            filterRef: this
        }
        koModel = new viewModel(koModelOptions);
        koModelProductToggle = new viewModel(koModelOptions);

        var filterElement = document.getElementById('Price-management-view');
        var productToggleElement = document.getElementById('product-filter-v2-management-toggles');

        ko.applyBindings(koModel, filterElement);
        ko.applyBindings(koModelProductToggle, productToggleElement);

        $mapToggleLabel.on("click", function() {
            $galleryToggleLabel.addClass("filter-toggle-unselected");
            $mapToggleLabel.removeClass("filter-toggle-unselected");
        });

        $galleryToggleLabel.on("click", function() {
            $mapToggleLabel.addClass("filter-toggle-unselected");
            $galleryToggleLabel.removeClass("filter-toggle-unselected");
        });
    };

    this.toggleFilter = function() {
        var $filter = this.$element.find(".ProductFilterV2__management-filter");
        var $filterBtn = this.$element.find(".ProductFilterV2__management-filter-btn");
        var $filterContent = this.$element.find(".ProductFilterV2__management-filter-content");
        var $filterIconClose = $("#filterNavIconClose");
        var $filterIconArrow = $("#filterNavIconArrow");
        var $filterPriceLocation = this.$element.find(".ProductFilterV2__Price-management-view");
        if ($filterPriceLocation.hasClass("filter-open"))
           this.togglePriceFilter();


        $filter.toggleClass("filter-open");
        $filterBtn.toggleClass("filter-button-closed");
        $filterContent.toggleClass("show-filter-content");
        $filterIconClose.toggleClass("hide");
        $filterIconArrow.toggleClass("hide");

         $("body").removeAttr("style")
         this.handleFilterLayout()
    }

    this.togglePriceFilter = function() {

        var $filter = this.$element.find(".ProductFilterV2__Price-management-view");
        var $filterBtn = this.$element.find(".ProductFilterV2__Price-management-filter-btn");
        var $filterContent = this.$element.find(".ProductFilterV2__Price-management-filter-content");
        var $filterIconClose = $("#PricefilterNavIconClose");
        var $filterIconArrow = $("#PricefilterNavIconArrow");

        var $filterLocation = this.$element.find(".ProductFilterV2__management-filter");
        if ($filterLocation.hasClass("filter-open"))
           this.toggleFilter();

        $filter.toggleClass("filter-open");
        $filterBtn.toggleClass("filter-button-closed");
        $filterContent.toggleClass("show-filter-content");
        $filterIconClose.toggleClass("hide");
        $filterIconArrow.toggleClass("hide");

         $("body").removeAttr("style")
         this.handleFilterLayout()
    }

    this.initRoomSelection = function(){

        var $bedroomInputs = $("#ProductFilterV2__management-rooms-toggles-bedrooms");
        var $bathroomInputs = $("#ProductFilterV2__management-rooms-toggles-bathrooms");

        $bedroomInputs.children().first().prop("checked", true);
        $bathroomInputs.children().first().prop("checked", true);

        this.toggleBedroomSelection();
        this.toggleBathroomSelection();

    }

    this.toggleBedroomSelection = function(){

        var $toggleInputs = $("#ProductFilterV2__management-rooms-toggles-bedrooms");
        var $selected = $("#ProductFilterV2__management-rooms-toggles-bedrooms input:checked+label");
        var $notSelected = $("#ProductFilterV2__management-rooms-toggles-bedrooms input:not(:checked):not(:disabled)+label");
        var $disabled = $("#ProductFilterV2__management-rooms-toggles-bedrooms input:disabled+label");

        var toggleStyleOn = {
            "background-color" :  $toggleInputs.data("roomFillColor"),
            "color" : $toggleInputs.data("roomTextColor"),
            "border-color" : $toggleInputs.data("roomBorderColor")
        }

        var toggleStyleOff = {
            "background-color" :  "inherit",
            "color" : $toggleInputs.data("roomTextColor"),
            "border-color" : "#b9b9b9"
        }

        var disabled = {
            "background-color" :  "rgba(118, 118, 118, 0.3)",
            "color" : $toggleInputs.data("roomTextColor"),
            "border-color" : "#b9b9b9"
        }

        $selected.css(toggleStyleOn)
        $notSelected.css(toggleStyleOff)
        $disabled.css(disabled)

    }

    this.toggleBathroomSelection = function(){

        var $toggleInputs = $("#ProductFilterV2__management-rooms-toggles-bathrooms");
        var $selected = $("#ProductFilterV2__management-rooms-toggles-bathrooms input:checked+label");
        var $notSelected = $("#ProductFilterV2__management-rooms-toggles-bathrooms input:not(:checked):not(:disabled)+label");
        var $disabled = $("#ProductFilterV2__management-rooms-toggles-bathrooms input:disabled+label");

        var toggleStyleOn = {
            "background-color" :  $toggleInputs.data("roomFillColor"),
            "color" : $toggleInputs.data("roomTextColor"),
            "border-color" : $toggleInputs.data("roomBorderColor")
        }

        var toggleStyleOff = {
            "background-color" :  "inherit",
            "color" : $toggleInputs.data("roomTextColor"),
            "border-color" : "#b9b9b9"
        }

        var disabled = {
            "background-color" :  "rgba(118, 118, 118, 0.3)",
            "color" : $toggleInputs.data("roomTextColor"),
            "border-color" : "#b9b9b9"
        }

        $selected.css(toggleStyleOn)
        $notSelected.css(toggleStyleOff)
        $disabled.css(disabled)

    }

    this.initHomeOptions = function(){

        var homeOptions = this.$element.find(".ProductFilterV2__management-options-checkbox")
        homeOptions.each(function(i){
            if(!homeOptions[i].disabled){
                homeOptions[i].checked = true
            }

        })

    }

    this.initSliders = function(){

        this.initSliderBounds();
        this.initSliderStyling()
        this.handleLowerPrSlider();
        this.handleUpperPrSlider();
        this.handleLowerSqftSlider();
        this.handleUpperSqftSlider();

    }

    this.initSliderBounds = function(){

        var lowerPrSlider = $('#prLower');
        handleSliderBounds(lowerPrSlider,
            parseInt(lowerPrSlider.data("prSliderMin")),
            parseInt(lowerPrSlider.data("prSliderMax")),
            parseInt(lowerPrSlider.data("prSliderMin")))

        var upperPrSlider = $('#prUpper');
        handleSliderBounds(upperPrSlider,
            parseInt(upperPrSlider.data("prSliderMin")),
            parseInt(upperPrSlider.data("prSliderMax")),
            parseInt(upperPrSlider.data("prSliderMax")));

        var lowerSqftSlider = $('#sqftLower');
        handleSliderBounds(lowerSqftSlider,
            parseInt(lowerSqftSlider.data("sqftSliderMin")),
            parseInt(lowerSqftSlider.data("sqftSliderMax")),
            parseInt(lowerSqftSlider.data("sqftSliderMin")))

        var upperSqftSlider = $('#sqftUpper');
        handleSliderBounds(upperSqftSlider,
            parseInt(upperSqftSlider.data("sqftSliderMin")),
            parseInt(upperSqftSlider.data("sqftSliderMax")),
            parseInt(upperSqftSlider.data("sqftSliderMax")))

        lowerPrSlider.on("input", this.handleLowerPrSlider.bind(this))
        upperPrSlider.on("input" , this.handleUpperPrSlider.bind(this));
        lowerSqftSlider.on("input", this.handleLowerSqftSlider.bind(this))
        upperSqftSlider.on("input" , this.handleUpperSqftSlider.bind(this));

    }
    function handleSliderBounds (element, min, max, initialValue){

        var step = Math.pow(10 , Math.floor(Math.log(max) / Math.log(10))-1)

        if(min == max || (max-min) < step){
            initialValue = max
            max = max+step
            min = min-step
            element.prop("disabled", true)

            element.attr("min", min)
            element.attr("max", max)
            element.val(initialValue)
            element.attr("step", step)

        } else {

            var floorMin = Math.floor(min/step) * step
            var ceilMax = Math.ceil(max/step) * step

            element.attr("min", floorMin)
            element.attr("max", ceilMax)
            element.val(initialValue == max ? ceilMax : floorMin)
            element.attr("step", step)

        }



    }

    this.initSliderStyling = function(){

        var prSliderLabel = $('#prSliderLabel');
        var sqftSliderLabel = $('#sqftSliderLabel');
        var prRangeColor = $('#prRangeColor');
        var sqftRangeColor = $('#sqftRangeColor');

        prSliderLabel.css("color", prSliderLabel.data("prSliderTextColor"))
        sqftSliderLabel.css("color", sqftSliderLabel.data("sqftSliderTextColor"))
        prRangeColor.css("background-color", prRangeColor.data("prSliderColor"))
        sqftRangeColor.css("background-color", sqftRangeColor.data("sqftSliderColor"))

    }
    this.handleLowerPrSlider = function(){

        var lowerSlider = $('#prLower');
        var upperSlider = $('#prUpper');

        handleLowerSlider(lowerSlider, upperSlider)
        this.handleSliderStyling()

    };

    this.handleUpperPrSlider = function(){

        var lowerSlider = $('#prLower');
        var upperSlider = $('#prUpper')

        handleUpperSlider(lowerSlider, upperSlider)
        this.handleSliderStyling()

    }

    this.handleLowerSqftSlider = function(){

        var lowerSlider = $('#sqftLower');
        var upperSlider = $('#sqftUpper');

        handleLowerSlider(lowerSlider, upperSlider)
        this.handleSliderStyling()

    };

    this.handleUpperSqftSlider = function(){

        var lowerSlider = $('#sqftLower');
        var upperSlider = $('#sqftUpper');

        handleUpperSlider(lowerSlider, upperSlider)
        this.handleSliderStyling()

    };


    function handleLowerSlider(lowerSlider, upperSlider){

        var lowerVal = parseInt(lowerSlider.val());
        var upperVal = parseInt(upperSlider.val());


        if ((lowerVal > upperVal - parseInt(lowerSlider.attr("step"))) && !lowerSlider.prop("disabled") ) {
            upperSlider.val( lowerVal + parseInt(lowerSlider.attr("step")));

            if (upperVal == upperSlider.attr("max") ) {
                lowerSlider.val(parseInt(upperSlider.attr("max")) - parseInt(lowerSlider.attr("step")));
            }
            lowerVal = parseInt(lowerSlider.val());
            upperVal = parseInt(upperSlider.val());
        }
    }

    function handleUpperSlider(lowerSlider, upperSlider){

        var lowerVal = parseInt(lowerSlider.val());
        var upperVal = parseInt(upperSlider.val());

        if ((upperVal < lowerVal + parseInt(upperSlider.attr("step"))) && !upperSlider.prop("disabled")) {
            lowerSlider.val(upperVal - parseInt(upperSlider.attr("step")));

            if (lowerVal == lowerSlider.attr("min")) {
                upperSlider.val(parseInt(lowerSlider.attr("min")) + parseInt(upperSlider.attr("step")));
            }

            lowerVal = parseInt(lowerSlider.val());
            upperVal = parseInt(upperSlider.val());

        }
    }
    this.handleSliderStyling = function(){

        var lowerPrSlider = $('#prLower');
        var lowerPrValue = $('#prAmountLower');
        var upperPrSlider = $('#prUpper');
        var upperPrValue = $('#prAmountUpper');
        var prRangeColor = $('#prRangeColor');

        manageSliderStyling(lowerPrSlider, lowerPrValue, upperPrSlider, upperPrValue, prRangeColor)

        var lowerSqftSlider = $('#sqftLower');
        var lowerSqftValue = $('#sqftAmountLower');
        var upperSqftSlider = $('#sqftUpper');
        var upperSqftValue = $('#sqftAmountUpper');
        var sqftRangeColor = $('#sqftRangeColor');

        manageSliderStyling(lowerSqftSlider, lowerSqftValue, upperSqftSlider, upperSqftValue, sqftRangeColor)

        if(upperPrSlider.prop("disabled")){
            upperPrValue.addClass("hide")
        }

        if(upperSqftSlider.prop("disabled")){
            upperSqftValue.addClass("hide")
        }
    }
    function manageSliderStyling(lowerSlider, lowerValue, upperSlider, upperValue, rangeColor){

        var lowerVal = parseInt(lowerSlider.val());
        var upperVal = parseInt(upperSlider.val());

        var lowerBuffer = parseInt(lowerValue.innerWidth()) *.5
        var upperBuffer = parseInt(upperValue.innerWidth()) *.5

        normalizedLower = ((lowerVal - parseInt(lowerSlider.attr("min"))) / (parseInt(lowerSlider.attr("max")) - parseInt(lowerSlider.attr("min")))) *100
        normalizedUpper = ((upperVal - parseInt(lowerSlider.attr("min"))) / (parseInt(lowerSlider.attr("max")) - parseInt(lowerSlider.attr("min")))) *100

        diff = parseInt(normalizedUpper - normalizedLower) < 15 && parseInt(normalizedUpper - normalizedLower) > 0 ? 25 : 0 ;

        lowerBuffer = normalizedLower > 0 ? lowerBuffer : 0
        upperBuffer = normalizedUpper < 100 ? upperBuffer : 0


        lowerValue.css("margin-left", "calc(" + normalizedLower + '% - ' + (lowerBuffer+diff) + "px)")
        upperValue.css({
            "margin-right" : "calc(" + (100 - normalizedUpper) + '% - ' + (upperBuffer+diff) + "px)" ,
            "right" : 0
        });

        // add dollar signs to the price slider range
        var dollarSymbol = "";
        if(lowerSlider[0].id.toLowerCase() === "prlower") dollarSymbol = "$";

        lowerValue.val(dollarSymbol + nFormatter(lowerSlider.val(), 1));
        upperValue.val(dollarSymbol + nFormatter(upperSlider.val(), 1));

        rangeColor.css("margin-left" , normalizedLower + '%');
        rangeColor.css("width" , normalizedUpper - normalizedLower + '%');

    }


    function nFormatter(num, digits) {

        var lookup = [
          { value: 1, symbol: "" },
          { value: 1e3, symbol: "k" },
          { value: 1e6, symbol: "M" },
          { value: 1e9, symbol: "G" },
          { value: 1e12, symbol: "T" },
          { value: 1e15, symbol: "P" },
          { value: 1e18, symbol: "E" }
        ];

        var rx = /\.0+$|(\.[0-9]*[1-9])0+$/;

        var item = lookup.slice().reverse().find(function(item) {
          return num >= item.value;
        });

        return item ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol : "0";

    }

    this.handleFilterLayout = function(){

        var ctas = this.$element.find('.ProductFilterV2__management-ctas');
        var buttons = this.$element.find('.ProductFilterV2__management-buttons');
        var rooms = this.$element.find('.ProductFilterV2__management-rooms');
        var options = this.$element.find('.ProductFilterV2__management-options');

        if ($(window).outerWidth() < 1440) {
            ctas.removeClass("flex-md-column pl-md-9 my-6")
            ctas.addClass("justify-content-sm-center pt-6 full-width")
            buttons.addClass("mb-sm-6 ml-sm-8")
            ctas.css({
                "border-left": "none",
                "border-top": "1px solid #b9b9b9"
            });

        }
        else{
            ctas.addClass("flex-md-column pl-md-9 my-6")
            ctas.removeClass("justify-content-sm-center pt-6 full-width")
            buttons.removeClass("mb-sm-6 ml-sm-8")
            ctas.css({
                "border-left": "1px solid #b9b9b9",
                "border-top": "none"
            });
        }

        if($(window).outerWidth() < 1025){
            options.css("width", rooms.innerWidth() > 0 ? rooms.innerWidth() : "" )
        }
        else{
            options.css("width","")
        }

        if($(window).outerWidth() < 1025){
            this.$element.find('.ProductFilterV2__management-view-btn').trigger('click')
         }

        this.handleSliderStyling()

    }
    this.getFilterCount = function() {
        var prLowerSlider = $('#prLower');
        var prUpperSlider = $('#prUpper');
        var sqftLowerSlider = $('#sqftLower');
        var sqftUpperSlider = $('#sqftUpper');
        var bedrooms = $("#ProductFilterV2__management-rooms-toggles-bedrooms");
        var bathrooms = $("#ProductFilterV2__management-rooms-toggles-bathrooms");
        var homeOptions = this.$element.find(".ProductFilterV2__management-options-checkbox");

        var count = 0;

        if(parseInt(prLowerSlider.val()) > (parseInt(prLowerSlider.attr("min"))) || parseInt(prUpperSlider.val()) < parseInt(prUpperSlider.attr("max"))){
            if(!prUpperSlider.prop("disabled")){
                count = count + 1
            }

        }
        if(parseInt(sqftLowerSlider.val()) > parseInt(sqftLowerSlider.attr("min")) || parseInt(sqftUpperSlider.val()) < parseInt(sqftUpperSlider.attr("max"))){
            if(!sqftUpperSlider.prop("disabled")){
                count = count + 1
            }
        }
        if(bedrooms.children("input:checked").val() != "Any"){
            count = count + 1
        }
        if(bathrooms.children("input:checked").val() != "Any"){
            count = count + 1
        }
        if(getSelectedHomeOptions(homeOptions, false).length > 0){
            count = count + 1
        }

        return count;
    }

    this.handleFilterCount = function(){
        var count = this.getFilterCount();

        var filterCircle = this.$element.find(".ProductFilterV2__management-filter-circle")
        var arrowIcon = $("#filterNavIconArrow");
        var closeIcon = $("#filterNavIconClose");

        if(count > 0){
            filterCircle[0].innerHTML = count
            filterCircle.removeClass("hide");
            arrowIcon.removeClass("ml-sm-6 ml-lg-9");
            closeIcon.removeClass("ml-sm-6 ml-lg-9");
            arrowIcon.addClass("ml-sm-4");
            closeIcon.addClass("ml-sm-4");
        }
        else{
            filterCircle.addClass("hide");
            arrowIcon.addClass("ml-sm-6 ml-lg-9");
            closeIcon.addClass("ml-sm-6 ml-lg-9");
            arrowIcon.removeClass("ml-sm-4");
            closeIcon.removeClass("ml-sm-4");
        }

    }

    this.onApply = function(currentView){
        var filters ={};

        var prLowerSlider = $('#prLower');
        var prUpperSlider = $('#prUpper');
        var sqftLowerSlider = $('#sqftLower');
        var sqftUpperSlider = $('#sqftUpper');
        var bedrooms = $("#ProductFilterV2__management-rooms-toggles-bedrooms");
        var bathrooms = $("#ProductFilterV2__management-rooms-toggles-bathrooms");
        var homeOptions = this.$element.find(".ProductFilterV2__management-options-checkbox");

        filters["PR Lower"] = parseInt(prLowerSlider.val());
        filters["PR Upper"] = parseInt(prUpperSlider.val());
        filters["Sqft Lower"] = parseInt(sqftLowerSlider.val());
        filters["Sqft Upper"] = parseInt(sqftUpperSlider.val());
        filters["Bedrooms"] = bedrooms.children("input:checked").val() == "Any" ? 0 : parseInt(bedrooms.children("input:checked").val());
        filters["Bathrooms"] = bathrooms.children("input:checked").val() == "Any" ? 0 : parseInt(bathrooms.children("input:checked").val());
        filters["Home Options"] = getSelectedHomeOptions(homeOptions, true);

        this.handleFilterCount();
        this.togglePriceFilter();

        var isFiltered = this.getFilterCount() > 0;

        $(".pfv2-listener").trigger("pfv2-filter-change", [filters,isFiltered]);
        
        savedItemService.setSavedStateForDocument();
    }

    function getSelectedHomeOptions(homeOptions, checked){

        var selectedHomeOptions = [];
        homeOptions.each(function(i){
            if(homeOptions[i].checked == checked && !homeOptions[i].disabled){
                selectedHomeOptions.push(homeOptions[i].value.toLowerCase())
            }
        });

        return selectedHomeOptions;
    }

    this.onReset = function() {
        this.resetFilterValues();
        this.handleFilterCount();
        this.togglePriceFilter();
    }

    this.resetFilterValues = function() {
        this.initSliders();
        this.initRoomSelection();
        this.initHomeOptions();
    }

}

module.exports = component(ProductFilterV2);
