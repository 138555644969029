var component = require('../../../lib/js/component.js');
var utils = require("../../../lib/js/utils.js");
var moreToggle = require('../../../templates/shared/more-links.js');

function ProductSummaryV2() {

    var viewLookupTable = {
        "community-gallery": $(".ProductSummaryV2__communityContainer"),
        "exact-matches": $(".exact-matches"),
        "close-matches": $(".close-matches")
    }

    var galleryLookupTable = {
        "community": $(".ProductSummaryGalleryv2"),
        "qmi": $(".ProductSummaryQMIGalleryv2")
    }

    var filteredCommunityObjects;
    var brandNumber;

    var qmiGalleryExpand = false;
    var expandQMIBtn;

    var $qmiLargeCards;
    var $qmiGridCards;

    function deriveBrandNumber(brandName) {
        switch (brandName) {
            case 'Pulte':
                return 1;
            case 'Del Webb':
                return 2;
            case 'DiVosta':
                return 3;
            case 'Centex':
                return 4;
            case 'JW':
                return 6;
            case 'AmericanWest':
                return 7;
            default:
                return 0;
        }
    }

    this.getFilteredCommunities = function(brand, state, region, cityNames, filterValues) {
        var url = '/api/Community/ProductCommunitySearch?';
        var params = 'brand=' + brand + 
                     '&state=' + state + 
                     '&region=' + region + 
                     '&cityNames=' + cityNames +
                     '&minPrice=' + filterValues['PR Lower'] + 
                     '&maxPrice=' + filterValues['PR Upper'] + 
                     '&minBedrooms=' + filterValues['Bedrooms'] + 
                     '&minBathrooms=' + filterValues['Bathrooms'] + 
                     '&pageSize=0' + 
                     '&jsonFormat=true';

        $.ajax({
            type: 'GET',
            context: this,
            url: url + params
        }).done(function(response) {
            this.setExactAndCloseMatchHTML(response, filterValues);
        });
    }

    this.initialize = function (element, options) {
        this.lastItem = null;
        this.hasExperienceMessage = $('.ExperienceMessage').length ? true : false;
        this.setExperienceModalButtons();

        // ...More toggle for communities
        moreToggle.initialize(this.$element);

        // See More toggle for QMIs
        $('.more-button').off().on("click", function() {
            if($(this).parent().hasClass('collapse-more')) {
                $(this).parent().removeClass('collapse-more');
                $(this).text('See Less');       
            }
            else {
                $(this).parent().addClass('collapse-more');
                $(this).text('See More');     
            }
        });
        this.handleGalleryView(null,"community");
        this.$element.on("pfv2-filter-change", this.handleFilter.bind(this));
        this.$element.on("pf-view-change", this.handleGalleryView.bind(this));

        expandQMIBtn = this.$element.find(".qmi-expand");
        expandQMIBtn.on("click", utils.debounce(this.expandQMIs.bind(this), 250, false));
    }

    this.handleGalleryView = function(event,view) {
        Object.values(galleryLookupTable).forEach(function(val){
            val.hide()
        });
        galleryLookupTable[view].show();

        if (view === "qmi") {
            this.displayQMIs();
        }
    }

    function orderByRank(a, b) {
        if (a.communityFeaturedRanks.length == 0) {
            a.communityFeaturedRanks.push({
                'brandID': 0,
                'brandRank': 1000
            })
        }
        if (b.communityFeaturedRanks.length == 0) {
            b.communityFeaturedRanks.push({
                'brandID': 0,
                'brandRank': 1000
            })
        }

        // ensure we are only sorting by the brand rank for this particular brand
        var aHasRankForThisBrand = false;
        a.communityFeaturedRanks.forEach(function(ranking) {
            if(ranking.hasOwnProperty('brandID') && ranking['brandID'] == brandNumber) {
                a.communityFeaturedRanks[0].brandRank = ranking['brandRank'];
                aHasRankForThisBrand = true;
            }
        });
        if (!aHasRankForThisBrand) {
            a.communityFeaturedRanks[0].brandRank = 1000;
        } 

        var bHasRankForThisBrand = false;
        b.communityFeaturedRanks.forEach(function(ranking) {
            if(ranking.hasOwnProperty('brandID') && ranking['brandID'] == brandNumber) {
                b.communityFeaturedRanks[0].brandRank = ranking['brandRank'];
                bHasRankForThisBrand = true;
            }
        });
        if (!bHasRankForThisBrand) {
            b.communityFeaturedRanks[0].brandRank = 1000;
        }

        return a.communityFeaturedRanks[0].brandRank - b.communityFeaturedRanks[0].brandRank;
    }

    function orderByPriceAscending(a, b) {

        // if these communities have a featuredRank, do not sort by price
        var doNotSort = false;
        a.communityFeaturedRanks.forEach(function(ranking) {
            if(ranking.hasOwnProperty('brandID') && ranking['brandID'] == brandNumber) {
                doNotSort = true;
            }
        });
        b.communityFeaturedRanks.forEach(function(ranking) {
            if(ranking.hasOwnProperty('brandID') && ranking['brandID'] == brandNumber) {
                doNotSort = true;
            }
        });

        if (doNotSort) {
            return 0;
        }

        // make sure anything with no starting price is sorted to last
        function value(price) {
            return price === 0.0 ? Infinity : price;
        }

        return value(a.startingFromPrice) - value(b.startingFromPrice);
    }

    this.setExactAndCloseMatchHTML = function(filteredCommunities, filterValues) {

        filteredCommunityObjects = filteredCommunities;
        var communityGalleryArray = Array.from(viewLookupTable['community-gallery'].children());

        // make sure that the communities show up in the correct FeaturedCommunity order
        filteredCommunities.sort(orderByRank);

        // sort the unranked communities by price ascending
        filteredCommunities.sort(orderByPriceAscending);

        filteredCommunities.forEach(function(filteredCommunity) {
            communityGalleryArray.forEach(function(card) {            
                if ($(card).data('communityId') == filteredCommunity.id) {
                    if (filteredCommunity.isExactMatch) {
                        // in addition to using isExactMatch property, we need to take into account the hasTownhome and hasSingleFamily
                        // properties b/c these are not included in the isExactMatch logic in the api
                        // also - when the api has been updated to include the hasVillas and hasGardenHomes properties, we can
                        // add the conditionals here to enable those filter values
                        if ((filterValues['Home Options'].includes('condos/townhomes') && filteredCommunity.hasTownhome) ||
                            (filterValues['Home Options'].includes('single family homes') && filteredCommunity.hasSingleFamily)) {
                            $('.exact-matches').append($(card).get(0));
                        } else {
                            $('.close-matches').append($(card).get(0));
                        }
                    }
                    else {
                        $('.close-matches').append($(card).get(0));
                    }
                }
            });
        });

        this.updateExactAndCloseMatchNumbers();
    }

    this.updateExactAndCloseMatchNumbers = function() {
        $('#ExactMatchLabel').html(viewLookupTable['exact-matches'].children().length);
        $('#CloseMatchLabel').html(viewLookupTable['close-matches'].children().length);
    }

    this.handleFilter = function(event, filterValues, isFiltered, koModel) {

        this.resetCards();

        if(!isFiltered) {
            this.updateExactAndCloseMatchNumbers();
            return;
        }

        var brand = $("#ProductSummaryV2CommunityGallery").data('brand');
        var state = $("#ProductSummaryV2CommunityGallery").data('state');
        var region = $("#ProductSummaryV2CommunityGallery").data('region');
        var cityNames = $("#ProductSummaryV2CommunityGallery").data('city');
        this.getFilteredCommunities(brand, state, region, cityNames, filterValues);

        brandNumber = deriveBrandNumber(brand);

        $('.ProductSummaryV2__communityContainer').hide();
        $('.ProductSummaryV2__communityContainer-filtered').show();
    }

    this.resetCards = function() {

        var exactMatches = Array.from(viewLookupTable['exact-matches'].children());
        var closeMatches = Array.from(viewLookupTable['close-matches'].children());
        var allCards = exactMatches.concat(closeMatches);

        if (allCards.length > 0) {
            // make sure that the communities show up in the correct FeaturedCommunity order
            filteredCommunityObjects.sort(orderByRank);

            // sort the unranked communities by price ascending
            filteredCommunityObjects.sort(orderByPriceAscending);

            filteredCommunityObjects.forEach(function(filteredCommunity) {
                allCards.forEach(function(card) {       
                    if ($(card).data('communityId') == filteredCommunity.id) { 
                        $('.ProductSummaryV2__communityContainer').append($(card).get(0));
                    }
                });
            });
        }

        $('.ProductSummaryV2__communityContainer-filtered').hide();
        $('.ProductSummaryV2__communityContainer').show();
    }

    //If the experience modal is not on the page, the button clicks should navigate the user to the appropriate community/qmi page without the experience message popup.
    this.setExperienceModalButtons = function() {
        if (!this.hasExperienceMessage) {
            this.$element.find('.ProductSummary__buttons [data-target="#modal-experience"]').each( function() {
                var $this = $(this);
                $this.attr("href", $this.data('href'));
                $this.removeAttr('data-toggle');
            });
        }
    }

    this.displayQMIs = function() {
        this.$qmiLargeCards = this.$element.find("section.QMIGalleryCard");
        this.$qmiGridCards = this.$element.find("section.QMIGridCard");
        if (!qmiGalleryExpand) {
            this.$qmiLargeCards.each(function(index){
                if (index > 4) $(this).addClass("d-none");
            });
            this.$qmiGridCards.each(function(index){
                if (index > 4) $(this).addClass("d-none");
            });
        }
    }

    this.expandQMIs = function() {
        qmiGalleryExpand = true;
        this.$qmiLargeCards.each(function(){
            $(this).removeClass("d-none");
        });
        this.$qmiGridCards.each(function(){
            $(this).removeClass("d-none");
        });
        expandQMIBtn.addClass("d-none");
    }
}

module.exports = component(ProductSummaryV2);
