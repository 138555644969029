var component = require('../../../lib/js/component.js');
var LoginForm = require('../account-login/account-login-form.js');
var CreateForm = require('../account-create/account-create-form.js');

var AccountModal = function () {

    /**
     * initialize - Get references to the various DOM elements used by this
     * component, initialize form components, wire up the buttons to
     * switch views in this modal and listen for body events to trigger
     * the showing of this modal.
     *
     * @param  {Object} el HTML element
     * @param  {Object} options Component options
     */
    this.initialize = function (el, options) {
      var self = this;
      this.options = options || {};

      this.$modalDialog = $(this.find('.modal-dialog'));

      // find modal sub elements
      // each form is not actually a separate modal - there is only one
      // modal that contains both form parent elements
      this.$loginView = $(this.find('#AccountModal-login'));
      this.$createAccountView = $(this.find('#AccountModal-create'));

      // get references to the login <form> and a jQuery representation of it
      var loginFormContainer = this.$loginView.find('.AccountLoginForm').attach(LoginForm);
      this.loginForm = $(loginFormContainer).find('form')[0];
      this.$loginForm = $(this.loginForm);

      // get references to the create account <form> and a jQuery representation of it
      var createAccountFormContainer = this.$createAccountView.find('.AccountCreateForm').attach(CreateForm);
      this.createAccountForm = $(createAccountFormContainer).find('form')[0];
      this.$createAccountForm = $(this.createAccountForm);

      // find the Create with Email button on the login form and wire it up
      this.$accountCreateButton = this.$loginView.find('.AccountCreateButtons-email');
      this.$accountCreateButton.click(this.showCreateForm.bind(this));

      $(el).on('shown.bs.modal', function(){
        // show login form by default
        self.$createAccountView.hide();
        self.$loginView.show();
      });

      // listen for external triggers to open this account modal
      $('body').on('OPEN_ACCOUNT_MODAL', this.openAccountModal.bind(this));

    }

    /**
     * showCreateForm - click handler for the Create with Email button shown
     * in the login view of this modal.
     * @param  {Object} e click event
     */
    this.showCreateForm = function (e) {
        e.preventDefault();
        this.$loginView.hide();
        this.$createAccountView.show();
        this.resetScrollTop();
    }

    /**
     * resetScrollTop - reset the scroll position of the modal. Used when the
     * form view state changes.
     */
    this.resetScrollTop = function () {
        this.$modalDialog.scrollTop(0);
    }

    /**
     * openAccountModal - reset the view states of this modal and display it.
     * optionally handle attaching the saved item payload to both the
     * login and create account forms.
     *
     * @param  {Object} e event this component listens to for opening this modal
     * @param  {Object} payload object containing properties for saving an item to the user's saved items
     */
    this.openAccountModal = function (e, payload) {
        this.resetScrollTop();

        // reset the view states so the login form is shown
        this.$createAccountView.hide();
        this.$loginView.show();

        this.$element.modal('show');

        // handle the optional payload
        if (payload) {
            // add the redirect url to the payload so the login and create account pages know how to redirect user
            // doing this here because there is no reason to add the redirect url in saved item
            // data when the user is already logged in
            // TODO work with Sitecore devs to determine if they need the hostname added to this URL
            payload.RedirectUrl = window.location.pathname;

            //Update inputs to post saved data
            this.UpdateValueOfSaveInputs(this.$loginForm, payload);
            this.UpdateValueOfSaveInputs(this.$createAccountForm, payload);
        }
    }

    this.UpdateValueOfSaveInputs = function(form, payload) {
        var $caption = form.find('input.caption');
        var $communityBrand = form.find('input.communityBrand');
        var $communityId = form.find('input.communityId');
        var $deleteAction = form.find('input.deleteAction');
        var $mediaLocation = form.find('input.mediaLocation');
        var $planId = form.find('input.planId');
        var $qmiId = form.find('input.qmiId');
        var $saveElement = form.find('input.saveElement');
        var $toolType = form.find('input.toolType');
        var $pageUrl = form.find('input.pageUrl');
        var $thumbnailUrl = form.find('input.thumbnail');

        $caption.attr('value', payload.Caption);
        $communityBrand.attr('value', payload.CommunityBrand);
        $communityId.attr('value', payload.CommunityId);
        $deleteAction.attr('value', payload.DeleteAction);
        $mediaLocation.attr('value', payload.MediaLocation);
        $planId.attr('value', payload.PlanId);
        $qmiId.attr('value', payload.QmiId);
        $saveElement.attr('value', payload.SaveElement);
        $toolType.attr('value', payload.ToolType);
        $pageUrl.attr('value', payload.pageUrl);
        $thumbnailUrl.attr('value', payload.Thumbnail);
    }

}

module.exports = component(AccountModal);
