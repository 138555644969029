// Check if the current page URL indicates a community page
function checkCommunityPage() {
    var url = window.location.href;
    var regex = /^.*\b(?:homes)\b.*[0-9].*/;
    return regex.test(url);
}

// Display a popup after 3 minutes if on a community page
function displayCommunityPage3MinPopup() {
    setTimeout(function () {
        sendPopupInteractionToPersonalize("COMMUNITY_PAGE_STAYING");
        var communityPopup = document.querySelector('#community-lead-form-pers-modal_overlay');
            if (communityPopup !== null) {
                communityPopup.style.display = 'flex';
                }
    }, 3 * 60 * 1000); // 3 minutes in milliseconds
}

// Send interaction data to personalize based on the event name
function sendPopupInteractionToPersonalize(eventName) {
    var customEventData = {
        "channel": "WEB",
        "language": "EN",
        "currency": "USD",
        "page": window.location.href,
        "pointOfSale": Engage.settings.pointOfSale
    };
    engage.event(eventName, customEventData);
}

// Send interaction data to personalize based on the event name
function sendPopupInteractionToPersonalizeWithData(eventName, customEventData) {
    engage.event(eventName, customEventData);
}

// Initialize the popup display if on a community page
if (checkCommunityPage()) {
    displayCommunityPage3MinPopup();
}

if (hasExplanerArticle()) {
    TrackExplainerArticleLeadForm();
}

if (hasArticleAfterLeadFormSubmission()) {
    TrackAfterLeadFormStaying();
}

// Function to get query string parameters using URLSearchParams
function getQueryStringParameters() {
    var params = new URLSearchParams(window.location.search);
    return params;
}

// Function to check if 'explaner-article' parameter exists
function hasExplanerArticle() {
    var params = getQueryStringParameters();
    return params.has('explaner-article');
}

function hasArticleAfterLeadFormSubmission() {
    var params = getQueryStringParameters();
    return params.has("article-after-leadform");
}

function TrackAfterLeadFormStaying() {
    setTimeout(function () {
        triggerArticleAfterLeadFormPageStay();
    }, 30 * 1000); // 30 seconds in milliseconds
}

function TrackExplainerArticleLeadForm() {
    setTimeout(function () {
        triggerExplainerArticleLeadFormSubmit();
    }, 30 * 1000); // 30 seconds in milliseconds
}

// Function to show an alert with the time spent on the page
function triggerArticleAfterLeadFormPageStay() {
    if (!hasArticleAfterLeadFormSubmission()) return;
    sendPopupInteractionToPersonalize("ARTICLE_AFTER_LEAD_FORM_PAGE_STAYING");
}

function triggerExplainerArticleLeadFormSubmit() {
    if (!hasExplanerArticle()) return;
    sendPopupInteractionToPersonalize("EXPLAINER_ARTICLE_LEAD_FORM_PAGE_SUBMIT");
}