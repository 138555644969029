
var AlphaVision = require('../../../lib/js/alpha-vision.js');
var component = require('../../../lib/js/component.js');
var utils = require('../../../lib/js/utils.js');


function AlphaVisionMap() {
    var component;

    this.initialize = function (element) {
        component = this;

        component.builderGuid = this.$element.data("builder-guid");
        component.communityId = this.$element.data("community-id");
        component.mapContainer = this.$element.find(".map-target")[0];
        component.staticMap = this.$element.find(".static-map");

        component.filter = this.$element.find(".criteria");

        component.backButton = this.$element.find(".back-button");
        component.fitToView = this.$element.find(".fit-to-view");
        component.zoomButtons = this.$element.find(".zoom-buttons");

        component.hasDisclaimer = this.$element.data("has-disclaimer").toLowerCase() === 'true';
        component.disclaimer = this.$element.find(".disclaimer");

        component.panelToggle = this.$element.find(".panel-toggle-container");
        component.filterPanel = this.$element.find(".filter-panel");
        component.GmapSatellite = this.$element.find('#satellite')
        component.GmapRoadmap = this.$element.find('#roadmap')

        component.totals = this.$element.find(".totals-container");
        component.clearBtn = this.$element.find(".btn-clear-filters");
        component.slider = this.$element.find(".slider");
        component.ShowTotals(false);
        component.clearBtn.on("click", function () {
            component.ShowTotals(false);
            $(".status>.options>.option>.checkbox").prop("checked", false);
            $(".plans>.options>.option>.checkbox").prop("checked", false);
            $(".lot-type>.options>.option>.checkbox").prop("checked", false);
            $(".lot-facing>.options>.option>.checkbox").prop("checked", false);
            component.resetHomesiteStatuses();
            component.mapApi.resetLots();

        });
        component.panelToggle.on("click", function () {
            component.filterPanel.toggleClass("open");
        });

        component.filterPanel.on("transitionend", function (event) {
            if (event.originalEvent.propertyName === "max-width" &&
                component.mapType != AlphaVision.DataType.Lot) {
                component.mapApi.reset();
            }
        });

        component.viewoptions = this.$element.find(".view-options");
        component.viewoptions.on("click", function (event) {
             if(event.target.id)
             {
                 if (event.target.id == "default")
                 {
                     component.showSlider(false)
                     component.mapApi.displayGmap("")
                     component.showZoomButtons(true)
                 }

                 else
                 {
                     component.mapApi.displayGmap(event.target.id)
                     component.showSlider(true)
                     component.showZoomButtons(false)
                 }

             }

        });


        component.slider.on("click", function (event) {
             if( (component.GmapSatellite != null && component.GmapSatellite.is(':checked')) || (component.GmapRoadmap != null && component.GmapRoadmap.is(':checked')))
                component.showSlider(true)
        });

        component.toggleBackground = this.$element.find(".toggle .background");
        component.toggleSwitch = this.$element.find(".toggle .switch");
        component.toggleSwitchColor = this.$element.data("switch-color");

        component.maps = [];
        component.mapType;
        component.mapInitialized = false;

        component.selectedLot;
        component.lotInfo = this.$element.find(".map-content .lot-info");

        this.setupAlphaVisionApi();
        this.addSearchFilter();
        this.addFilterSectionClickHandler();
        this.addBackButtonClickHandler();
        this.addFitToViewButtonClickHandler();
        this.addToggleSwitchClickHandler();
        this.setupDisclaimer();
        this.setupResize();

        var isLotMapURL = utils.hasHashValue('homesite%20map');
        var lotMapModule = document.querySelector('#AlphaVisionMap');

        if(isLotMapURL && lotMapModule) {
            lotMapModule.style.display = 'block';
            utils.scrollToTarget(lotMapModule);
        }
    }

    this.ShowTotals = function(isVisible){
        if(isVisible)
            component.totals.show();
        else
            component.totals.hide();
    }

    this.setupAlphaVisionApi = function () {
        component.mapApi = new AVSiteplan(component.builderGuid,
            component.communityId,
            component.mapContainer,
            function (response) { component.genericCallback(response); },
            function (response) { component.eventCallback(response); }
        );
    }


    this.genericCallback = function (response) {
        if (response && response.datatype) {
            switch (response.datatype) {
                case (AlphaVision.DataType.MasterMap):
                    component.setupMasterMap(response);

                    break;

                case (AlphaVision.DataType.SitePlan):
                    component.setupSitePlan(response);
                    break;

                case (AlphaVision.DataType.Error):
                    component.handleError(response);

                    break;

                case (AlphaVision.DataType.Lot):
                    $('.total-Count').text(response.data.lots.length);
                    break;
                case (AlphaVision.DataType.Plan):
                default:
                    break;
            }

            component.showDisclaimer(response.datatype);
        }
    }

    this.eventCallback = function (response) {
        if (response && response.datatype && response.data && response.eventtype === AlphaVision.EventType.Click) {
            component.tryAddMapToMapsArray(response.datatype, response.name);
            component.showDisclaimer(response.datatype);
            component.toggleBackButton();
            switch (response.datatype) {

                case AlphaVision.DataType.SitePlan:
                    component.handleSitePlan(response);

                    break;

                case AlphaVision.DataType.Lot:
                    component.handleLot(response);

                    break;

                // case AlphaVision.DataType.Amenity:
                //     this.selectedAmenity = response.data;
                //     this.alphaVisionMapService.updateSelectedAmenity(this.selectedAmenity);
                //     this.mapLotsReady = true;
                //     break;

                default:
                    break;
            }
        }
    }

    this.handleError = function (response) {
        if (response.datatype === AlphaVision.DataType.Error && response.eventtype === AlphaVision.EventType.Init) {
            if (component.staticMap && component.staticMap.length) {
                component.showMap(false);
                component.showBackButton(false);
                component.showZoomButtons(false);

                component.showComponent(true);
                component.showStaticMap(true);
            }
            else {
                var id = $(component.$element).attr("id");
                component.removeNavigationItem(id);

                component.$element.remove();
            }
        }
    }

    this.setupMasterMap = function (response) {
        component.mapType = AlphaVision.DataType.MasterMap;
        component.tryAddMapToMapsArray(response.datatype, response.name);
        component.setupFilterForMastermap(response.data);
        component.addZoomButtonsClickHandler();
        component.showComponent(true);
        component.mapInitialized = true;
        component.mapApi.reset();
    }

    this.setupSitePlan = function (response) {
        component.mapType = AlphaVision.DataType.SitePlan;
        component.tryAddMapToMapsArray(response.datatype, response.name);
        component.setupFilterForSitePlan(response.data);
        component.addZoomButtonsClickHandler();
        component.showFilterPanel(true);
        component.showPanelToggle(true);
        component.showComponent(true);
        component.mapInitialized = true;
        component.mapApi.reset();
    }

    this.handleSitePlan = function (response) {
        component.mapType = AlphaVision.DataType.SitePlan;
        component.showFilterPanel(true);
        component.showPanelToggle(true);
        component.setupFilterForSitePlan(response.data);
    }

    this.handleLot = function (response) {
        component.mapType = AlphaVision.DataType.Lot;
        component.selectedLot = response.name;
        component.showLotInfo(false);

        setTimeout(function () {
            component.buildLotInformationTable(response.data);
            component.showLotInfo(true);
        }, 250);
    }

    this.setupDisclaimer = function () {
        if (component.hasDisclaimer) {
            component.disclaimer.on("click", function () {
                $(this).toggleClass("open");
            });
        }
    }

    // Filter Setup
    this.setupFilterForMastermap = function (data) {
        if (component.mapType === AlphaVision.DataType.MasterMap && data.siteplans && data.siteplans.length === 1) {
            if (data.availableStatus.length || data.availablePlans.length || data.lots.length) {
                component.storeSitePlanLots(data);
                component.setupFilter(data);
                component.addFilterOptionClickHandler();
                component.addFilterCheckboxClickHandler();
                component.showFilter(true);
                component.showFilterPanel(true);
                component.showPanelToggle(true);
            }
        }
        else {
            component.showFilter(false);
            component.showFilterPanel(false);
            component.showPanelToggle(false);
        }
    }

    this.setupFilterForSitePlan = function (sitePlan) {
        if (sitePlan) {
            component.storeSitePlanLots(sitePlan);
            component.setupFilter(sitePlan);
            component.addFilterOptionClickHandler();
            component.addFilterCheckboxClickHandler();
            component.showFilter(true);
        }
    }

    this.setupFilter = function (sitePlan) {
        if (sitePlan) {
            component.setupStatusFilter(sitePlan.availableStatus);
            component.setupAvailablePlans(sitePlan.availablePlans);
            component.setupTypes(sitePlan.lots);
            component.setupFacingOptions(sitePlan.lots);
        }
    }

    // Filter Setup - Status
    this.setupStatusFilter = function (statuses) {
        if (statuses && statuses.length) {
            var target = component.$element.find(".criteria .status .options");
            $(target).empty();

            // Sort statuses alphabetically
            var sortedStatuses = statuses.sort(function (first, second) {
                if (first.name < second.name) {
                    return -1;
                }
                else if (first.name > second.name) {
                    return 1;
                }

                return 0;
            });

            // Build html element that will display the statuses in the filter
            $(sortedStatuses).each(function (index, status) {
                var option = $("<div></div>", { class: "option mb-2" });
                var checkbox = $("<input></input>", { class: "checkbox mt-0 mr-4", type: "checkbox" }).val(status.name);
                var color = $("<div></div>", { class: "color mr-3" }).css("background-color", status.background);
                var title = $("<div></div>").text(status.name);

                $(option).append(checkbox);
                $(option).append(color);
                $(option).append(title);

                $(target).append(option);
            });
        }
    }

    // Filter Setup - Available Plans
    this.setupAvailablePlans = function (plans) {
        if (plans && plans.length) {

            var target = component.$element.find(".criteria .plans .options");
            $(target).empty();

            // Sort plans alphabetically
            var sortedPlans = plans.sort(function (first, second) {
                if (first.name < second.name) {
                    return -1;
                }
                else if (first.name > second.name) {
                    return 1;
                }

                return 0;
            });

            // Build html element that will display the available plans in the filter
            $(sortedPlans).each(function (index, plan) {
                var option = $("<div></div>", { class: "option mb-2" });
                var checkbox = $("<input></input>", { class: "checkbox mt-0 mr-4", type: "checkbox" }).val(plan.integrationId);
                var title = $("<div></div>").text(plan.name);

                $(option).append(checkbox);
                $(option).append(title);

                $(target).append(option);
            });
        }
    }

    // Filter Setup - Facing
    this.setupFacingOptions = function (lots) {
        var facingOptions = component.getAllFacingOptions(lots);

        var target = component.$element.find(".criteria .lot-facing .options");
        $(target).empty();

        // Build html element that will display the facing options in the filter
        $(facingOptions).each(function (index, facing) {
            var option = $("<div></div>", { class: "option mb-2" });
            var checkbox = $("<input></input>", { class: "checkbox mt-0 mr-4", type: "checkbox" }).val(facing);
            var title = $("<div></div>").text(facing);

            $(option).append(checkbox);
            $(option).append(title);

            $(target).append(option);
        });
    }

    this.getAllFacingOptions = function (lots) {
        var facingOptions = [];

        $(lots).each(function (index, lot) {
            $(lot.lotAttributes).each(function (index, attribute) {
                if (attribute.hasOwnProperty(AlphaVision.AlphaVisionAttributeValues.LotFacing)) {
                    var value = attribute[AlphaVision.AlphaVisionAttributeValues.LotFacing];
                    if (!facingOptions.includes(value)) {
                        facingOptions.push(value);
                    }

                    return false;
                }
            });
        });

        return facingOptions;
    }

    // Filter Setup - Types
    this.setupTypes = function (lots) {
        var types = component.getAllTypes(lots);

        var target = component.$element.find(".criteria .lot-type .options");
        $(target).empty();

        // Build html element that will display the types in the filter
        $(types).each(function (index, type) {
            var option = $("<div></div>", { class: "option mb-2" });
            var checkbox = $("<input></input>", { class: "checkbox mt-0 mr-4", type: "checkbox" }).val(type);
            var title = $("<div></div>").text(type);

            $(option).append(checkbox);
            $(option).append(title);

            $(target).append(option);
        });
    }

    this.getAllTypes = function (lots) {
        var types = [];

        $(lots).each(function (index, lot) {
            $(lot.lotAttributes).each(function (index, attribute) {
                if (attribute.hasOwnProperty(AlphaVision.AlphaVisionAttributeValues.LotType)) {
                    var value = attribute[AlphaVision.AlphaVisionAttributeValues.LotType];
                    if (!types.includes(value)) {
                        types.push(value);
                    }

                    return false;
                }
            });
        });

        var sortedTypes = types.sort(function (first, second) {
            if (first < second) {
                return -1;
            }
            else if (first > second) {
                return 1;
            }

            return 0;
        });

        return sortedTypes;
    }

    // Event Handlers
    this.addBackButtonClickHandler = function () {
        $(component.backButton).on("click", function () {
            if (component.maps.length > 1) {
                component.maps.pop();

                if (component.mapType === AlphaVision.DataType.Lot) {
                    component.mapType = AlphaVision.DataType.SitePlan;
                    component.selectedLot = null;
                    component.showLotInfo(false);
                }
                else if (component.mapType === AlphaVision.DataType.SitePlan) {
                    component.mapType = AlphaVision.DataType.MasterMap;
                    component.collapseFilter();
                    component.showFilter(false);
                }

                var candidateMap = component.maps[component.maps.length - 1];
                component.mapApi.selectMap(candidateMap);

            }

            component.toggleBackButton();
        });
    }

    this.addFilterCheckboxClickHandler = function () {
        $(component.filter).find("input[type='checkbox']").on("click", function () {
            var params = component.getFilterLotsParameters();
            if (params.hasFilterSeleceted) {
                component.mapApi.filterLots(params.statuses, params.plans, params.lotAttributes);
                component.ShowTotals(true);
            }
            else {
                component.mapApi.resetLots();
            }
        });

        $(component.filter).find(".layer-visibility input[type='checkbox']").on("click", function () {
            component.mapApi.toggleLotLayer();
        });
    }

    this.addFilterOptionClickHandler = function () {
        var optionTitles = component.filter.find(".filter-option .title");
        $(optionTitles).on("click", function () {
            var checkbox = $(this).siblings("input[type='checkbox']");
            $(checkbox).trigger("click");
        });
    }

    this.addFilterSectionClickHandler = function () {
        var sectionHeaders = component.$element.find(".section-header");
        $(sectionHeaders).on("click", function () {
            $(this).find(".icon").toggleClass("inverted");
            $(this).siblings(".filter-options").toggleClass("closed");
        });
    }

    this.addSearchFilter = function(){
            $('#findAHomesiteBtn').on("click", function () {
                var nolotexist = false;
                component.mapApi.reset();
                component.resetHomesiteStatuses();
                var lot = $('#homesite-search-input').val()

                switch(lot.length)
                {
                    case 0:
                        return;
                    case 1:
                        lot = "00"+lot; break;
                    case 2:
                        lot = "0"+lot; break;
                    case 4:
                        lot = "0"+lot;break;
                }


                if(lot.length === 5)
                {
                        var lotids =  component.sitePlanLots.filter(function(site) {
                            return site.lot == lot;
                          });
                        if(lotids.length > 0)
                        component.mapApi.selectLot(lot, function (response) {
                        if(response.data)
                                component.addFocussedLot("lot",lot)
                    });
                    else
                       nolotexist = true

                }
                else if (lot.length ===3){
                    var lotnos =  component.sitePlanLots.filter(function(plot) {
                        return plot.lotnumber == lot;
                      });
                      if(lotnos.length == 0)
                        nolotexist = true
                    else if(lotnos.length == 1)
                        component.mapApi.selectLot(lot, function (response) {
                                    component.addFocussedLot("lotnumber",lot)
                            });
                    else
                      lotnos.forEach(function (ids, index) {
                        $("g[lotnumber="+ids.lotnumber+"] circle").attr("r",50);
                        $("g[lotnumber="+ids.lotnumber+"] circle").attr("class","blink-circle");
                        setTimeout(function() {
                            $("g[lotnumber="+ids.lotnumber+"] circle").attr("class","").addClass('slice');
                            $("g[lotnumber="+ids.lotnumber+"] circle").attr("r",20);
                        }, 10000);
                    });
                }
                else
                    nolotexist = true

                    if(nolotexist)
                        component.showNoLotContent()

                component.toggleSwitch.attr('disabled', 'disabled');
});
}
this.resetHomesiteStatuses = function(){
    component.toggleSwitch.removeAttr("disabled");

    $(".status>.options>.option>.checkbox").prop("checked", false);


   component.setAvailableAndQuickMoveIns(false)
    component.toggleBackground.css({
        "background-color": "#dcdcdc",
        "border-color": ""
    });
    component.toggleSwitch.removeClass("enabled");
 }

this.showNoLotContent = function(){
    $("#lotnocontent").addClass("show");
    setTimeout(function() {
        $("#lotnocontent").removeClass("show");
        $("#lotnocontent").addClass("hide");
    }, 5000);
}

this.addFocussedLot = function(lotparam,lotno){
    $("g["+lotparam+"="+lotno+"] circle").attr("class","blink-circle");
    setTimeout(function() {
        $("g["+lotparam+"="+lotno+"] circle").attr("class","").addClass('slice');
    }, 10000);
}

    this.addFitToViewButtonClickHandler = function () {
        $(component.fitToView).on("click", function () {
            if (component.mapType === AlphaVision.DataType.MasterMap || component.mapType === AlphaVision.DataType.SitePlan) {
                if( (component.GmapSatellite != null && component.GmapSatellite.is(':checked')) || (component.GmapRoadmap != null && component.GmapRoadmap.is(':checked')))
                    component.mapApi.setZoomLevelAndCenterPosition()
                else
                    component.mapApi.reset()
            }
            else if (component.mapType === AlphaVision.DataType.Lot) {
                component.mapApi.selectLot(component.selectedLot);
            }
        });
    }

    this.addToggleSwitchClickHandler = function () {
        $(component.toggleSwitch).on("click", function () {
            if ($(this).hasClass("enabled")) {
                component.setAvailableAndQuickMoveIns(false)
                component.toggleBackground.css({
                    "background-color": "#dcdcdc",
                    "border-color": ""
                });

            }
            else {
                component.setAvailableAndQuickMoveIns(true)
                component.toggleBackground.css({
                    "background-color": component.toggleSwitchColor,
                    "border-color": component.toggleSwitchColor
                });
            }

            component.toggleSwitch.toggleClass("enabled");
            component.ShowTotals(true);
        });
    }

    this.addZoomButtonsClickHandler = function () {
        if (!component.zoomButtons.hasClass("visible")) {
            component.zoomButtons.addClass("visible");

            var btnZoomIn = this.$element.find(".btn-zoom-in");
            $(btnZoomIn).on("click", function () {
                component.mapApi.zoomIn();
            });

            var btnZoomOut = this.$element.find(".btn-zoom-out");
            $(btnZoomOut).on("click", function () {
                component.mapApi.zoomOut();
            });
        }
    }

    this.setupResize = function () {
        $(window).on("resize", function () {
            if (component.mapInitialized) {
                component.mapApi.reset();
            }
        });
    }

    // Helper Methods
    this.appendLotAttribute = function (attributes, lotAttributes, attributeType, title) {
        // Adds available lot information to the attributes array to be displayed in the lot information table
        $(lotAttributes).each(function (index, attribute) {
            if (attribute.hasOwnProperty(attributeType)) {
                attributes.push({
                    title: title,
                    value: attribute[attributeType].trim()
                });
            }
        });
    }

    this.buildLotInformationTable = function (lotData) {
        if (lotData) {
            var attributes = [{
                title: "Status",
                value: lotData.status
            }];

            component.appendLotAttribute(attributes, lotData.lotAttributes, AlphaVision.AlphaVisionAttributeValues.LotFacing, "Facing");
            component.appendLotAttribute(attributes, lotData.lotAttributes, AlphaVision.AlphaVisionAttributeValues.LotType, "Type");
            component.appendLotAttribute(attributes, lotData.lotAttributes, AlphaVision.AlphaVisionAttributeValues.Address, "Address");
            component.appendLotAttribute(attributes, lotData.lotAttributes, AlphaVision.AlphaVisionAttributeValues.Elevation, "Exterior");
            component.appendLotAttribute(attributes, lotData.lotAttributes, AlphaVision.AlphaVisionAttributeValues.EstMoveInDate, "Available");

            component.$element.find(".map-content .lot-info .title").html("Homesite " + lotData.lot);

            var tableBody = component.$element.find(".map-content .lot-info table tbody").empty();
            $(attributes).each(function (index, attribute) {
                $(tableBody).append(component.buildLotInformationTableRow(attribute.title, attribute.value));
            });
        }
    }

    this.buildLotInformationTableRow = function (title, value) {
        var row = $("<tr></tr>");
        var titleColumn = $("<td></td>", { class: "attribute" }).html(title);
        var valueColumn = $("<td></td>").html(value);

        $(row).append(titleColumn);
        $(row).append(valueColumn);

        return row;
    }

    this.collapseFilter = function () {
        component.filter.find(".icon").addClass("inverted");
        component.filter.find(".filter-options").addClass("closed");
    }

    this.getLotsForAppliedFilters = function () {
        var selectedFilterOptions = component.getSelectedFilterOptions();

        // Iterate over all lots in siteplan and include lotID in array if it matches all conditions of the filter

        var lotIds = [];
        $(component.sitePlanLots).each(function (index, lot) {
            // Determine if lot belongs to one of the checked statuses
            if (selectedFilterOptions.statuses.includes(lot.status)) {
                lotIds.push(lot.lot);

                return;
            }

            // Determine if lot belongs to one of the checked plans
            if (lot.plans && lot.plans.length) {
                var hasPlan = lot.plans.some(function (plan, index) {
                    return selectedFilterOptions.plans.includes(plan);
                });

                if (hasPlan) {
                    lotIds.push(lot.lot);

                    return;
                }
            }

            // Determine if lot belongs to one of the checked types
            if (selectedFilterOptions.types.includes(lot.type)) {
                lotIds.push(lot.lot);

                return;
            }

            // Determine if lot belongs to one of the checked facings
            if (selectedFilterOptions.facings.includes(lot.facing)) {
                lotIds.push(lot.lot);

                return;
            }
        });

        return lotIds.length ? lotIds : [''];
    }

    this.getFilterLotsParameters = function () {
        var checkedStatuses = $(component.filter).find(".status input[type='checkbox']:checked");
        var checkedPlans = $(component.filter).find(".plans input[type='checkbox']:checked")
        var checkedTypes = $(component.filter).find(".lot-type input[type='checkbox']:checked");
        var checkedFacings = $(component.filter).find(".lot-facing input[type='checkbox']:checked");
        var hasFilterSeleceted = false;

        var statuses = checkedStatuses.map(function (index, status) { return $(status).val(); }).get();
        if (statuses.length >= 1) {
            hasFilterSeleceted = true;
        }
        if (statuses.length == 1) {
            statuses = statuses[0];
        }
        else if (statuses.length == 0) {
            statuses = "";
        }

        var plans = checkedPlans.map(function (index, plan) { return $(plan).val(); }).get();
        if (plans.length >= 1) {
            hasFilterSeleceted = true;
        }
        if (plans.length == 1) {
            plans = plans[0];
        }
        else if (plans.length == 0) {
            plans = "";
        }

        var filterLotsParameters = {
            statuses: statuses,
            plans: plans
        };

        var lotAttributes = {};
        var lotTypes = checkedTypes.map(function (index, type) { return $(type).val(); }).get();
        if (lotTypes.length >= 1) {
            hasFilterSeleceted = true;
            lotAttributes.LOTTYPE = lotTypes;
        }

        var lotFacings = checkedFacings.map(function (index, type) { return $(type).val(); }).get();
        if (lotFacings.length >= 1) {
            hasFilterSeleceted = true;
            lotAttributes.LOTFACING = lotFacings;
        }

        if (Object.keys(lotAttributes).length > 0) {
            filterLotsParameters.lotAttributes = [lotAttributes];
        }
        else {
            filterLotsParameters.lotAttributes = [];
        }

        filterLotsParameters.hasFilterSeleceted = hasFilterSeleceted;

        return filterLotsParameters;
    }

    this.getSelectedFilterOptions = function () {
        var checkedStatuses = $(component.filter).find(".status input[type='checkbox']:checked");
        var checkedPlans = $(component.filter).find(".plans input[type='checkbox']:checked")
        var checkedFacings = $(component.filter).find(".facing input[type='checkbox']:checked");
        var checkedTypes = $(component.filter).find(".lot-types input[type='checkbox']:checked");

        var selectedFilterOptions = {
            statuses: checkedStatuses.map(function (index, status) { return $(status).val(); }).get(),
            plans: checkedPlans.map(function (index, plan) { return $(plan).val(); }).get(),
            facings: checkedFacings.map(function (index, option) { return $(option).val(); }).get(),
            types: checkedTypes.map(function (index, type) { return $(type).val(); }).get()
        };

        return selectedFilterOptions;
    }

    this.setAvailableAndQuickMoveIns = function(val){

        $(".status>.options>.option>.checkbox").each(function(item,elem){
            var status =  $(elem).attr("value")
            if(val)
            {
                if(status == "Available" || status == "Quick Move In")
                    $(elem).prop("checked", true);
            }
            else
            {
                if(status == "Available" || status == "Quick Move In")
                    $(elem).prop("checked", false);
            }
        var params = component.getFilterLotsParameters();
        if (params.hasFilterSeleceted)
            component.mapApi.filterLots(params.statuses, params.plans, params.lotAttributes);
        else
            component.mapApi.resetLots();

        })
 }


    this.removeNavigationItem = function (id) {
        var htmlId = "#" + id;

        var communityNav = $(".CommunityNav, .CommunityPersistentNav");
        if (communityNav) {
            var anchors = $(communityNav).find("a[data-nav-target='" + htmlId + "']");
            var item = $(anchors).closest("li");

            $(item).remove();
        }
    }

    this.showBackButton = function (isVisible) {
        if (isVisible) {
            component.backButton.addClass("visible");
        }
        else {
            component.backButton.removeClass("visible");
        }
    }

    this.showComponent = function (isVisible) {
        if (isVisible) {
            component.$element.addClass("visible");
        }
        else {
            component.$element.removeClass("visible");
        }
    }

    this.showDisclaimer = function (datatype) {
        if (component.hasDisclaimer) {
            component.disclaimer.removeClass("open");

            if (datatype == AlphaVision.DataType.SitePlan || datatype == AlphaVision.DataType.MasterMap) {
                component.disclaimer.addClass("visible");
            }
            else if (datatype == AlphaVision.DataType.Lot) {
                component.disclaimer.removeClass("visible");
            }
        }
    }

    this.showFilter = function (isVisible) {
        if (isVisible) {
            var firstFilterSection = component.filter.find(".filter-section").first();
            $(firstFilterSection).find(".icon").removeClass("inverted");
            $(firstFilterSection).find(".filter-options").removeClass("closed");

            component.filter.addClass("visible");
        }
        else {
            component.filter.removeClass("visible");
        }
    }

    this.showFilterPanel = function (isVisible) {
        if (isVisible) {
            component.filterPanel.addClass("open");
        }
        else {
            component.filterPanel.removeClass("open");
        }
    }

    this.showLotInfo = function (isVisible) {
        if (isVisible) {
            component.lotInfo.addClass("visible");
        }
        else {
            component.lotInfo.removeClass("visible");
        }
    }

    this.showMap = function (isVisible) {
        if (isVisible) {
            $(component.mapContainer).removeClass("d-none");
        }
        else {
            $(component.mapContainer).addClass("d-none");
        }
    }

    this.showPanelToggle = function (isVisible) {
        if (isVisible) {
            component.panelToggle.addClass("visible");
        }
        else {
            component.panelToggle.removeClass("visible");
        }
    }

    this.showStaticMap = function (isVisible) {
        if (isVisible) {
            component.staticMap.removeClass("d-none");
            component.staticMap.addClass("d-flex");
        }
        else {
            component.staticMap.removeClass("d-flex");
            component.staticMap.addClass("d-none");
        }
    }

    this.showZoomButtons = function (isVisible) {
        if (isVisible) {
            component.zoomButtons.addClass("visible");
        }
        else {
            component.zoomButtons.removeClass("visible");
        }
    }

    this.showSlider = function(isVisible)
    {
        if(isVisible)
        {
            $('.percent-slider').css('display', 'flex');
            component.mapApi.setTransparency(component.slider[0].value)
            if(component.slider[0].value == 1)
            $(".sliderLbl").text(0).append('%')
        else
            $(".sliderLbl").text(component.slider[0].value*10).append('%')
        }

        else
            $('.percent-slider').css('display', 'none');

    }

    this.storeSitePlanLots = function (sitePlan) {
        if (sitePlan) {
            component.sitePlanLots = sitePlan.lots.map(function (lot, index) {
                var sitePlanLot = {
                    lot: lot.lot,
                    status: lot.status,
                    lotnumber : lot.lotNumber
                };

                sitePlanLot.plans = lot.plans.map(function (plan, index) {
                    return plan.id;
                });

                var facingAttribute = lot.lotAttributes.find(function (attribute, index) {
                    return attribute.hasOwnProperty(AlphaVision.AlphaVisionAttributeValues.LotFacing);
                });
                sitePlanLot.facing = facingAttribute ? facingAttribute[AlphaVision.AlphaVisionAttributeValues.LotFacing] : "";

                var typeAttribute = lot.lotAttributes.find(function (attribute, index) {
                    return attribute.hasOwnProperty(AlphaVision.AlphaVisionAttributeValues.LotType);
                });
                sitePlanLot.type = typeAttribute ? typeAttribute[AlphaVision.AlphaVisionAttributeValues.LotType] : "";

                return sitePlanLot;
            }).sort(function (first, second) {
                if (first.lot > second.lot) {
                    return 1;
                }

                if (first.lot < second.lot) {
                    return -1;
                }

                return 0;
            });
        }
    }

    this.toggleBackButton = function () {
        if (component.maps.length > 1) {
            component.showBackButton(true);
        }
        else {
            component.showBackButton(false);
        }
    }


    this.tryAddMapToMapsArray = function (mapType, mapName) {
        if (mapType === AlphaVision.DataType.Lot && component.mapType === AlphaVision.DataType.Lot) {
            component.maps.pop();
        }

        if (!component.maps.includes(mapName)) {
            component.maps.push(mapName);
        }
    }
}

module.exports = component(AlphaVisionMap);
