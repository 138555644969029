var component = require('../../../lib/js/component.js');

function OSSVIPCard() {
    this.initialize = function(element) {
        this.$ossPhoneLabel = this.$element.find('.OSSVIP__availableVerbage');
        this.$phoneNumber = this.$element.find('.OSSVIP__phone');
        this.$ossHours = this.$element.find('.OSSVIP__OSSHours');
        this.$communityHours = this.$element.find('.OSSVIP__CommunityHours');
        this.$showOSSHours = this.$element.find('.OSSVIP__showOSSHours');
        this.$showCommunityHours = this.$element.find('.OSSVIP__showCommunityHours');

        this.$showCommunityHours.on('click', this.onCommunityHoursClick.bind(this));
        this.$showOSSHours.on('click', this.onShowOSSHoursClick.bind(this));
    }

    this.onCommunityHoursClick = function(event) {
        event.preventDefault();
        this.$ossPhoneLabel.hide();
        this.$phoneNumber.hide();
        this.$ossHours.hide();
        this.$communityHours.show();
        this.$showOSSHours.removeClass('d-none');
        this.$showCommunityHours.addClass('d-none');
    }

    this.onShowOSSHoursClick = function(event) {
        event.preventDefault();
        this.$ossPhoneLabel.show();
        this.$phoneNumber.show();
        this.$ossHours.show();
        this.$communityHours.hide();
        this.$showOSSHours.addClass('d-none');
        this.$showCommunityHours.removeClass('d-none');
    }
}

module.exports = component(OSSVIPCard);