var component = require('../../../lib/js/component.js');
var utils = require('../../../lib/js/utils.js');
var savedItemService = require('../../../lib/js/savedItemsService.js');

function HomeDesignFilter() {

    var koModel = {};

    var viewModel = function(options) {
        var self = this;

        self.viewTypeOptions = options.viewTypeOptions;
        self.viewLookupTable = options.viewLookupTable
        self.productToggleOptions = options.productToggleOptions;  
        self.filterRef = options.filterRef;
        self.viewObservable = {
            viewType: ko.observable(options.viewTypeOptions.galleryViewLabel),
            viewToggleText: ko.observable(options.viewTypeOptions.gridViewLabel),
            productType: ko.observable(options.productToggleOptions.homeToggleLabel),
            currentProductTotalCount: ko.observable(0),
            currentProductMatchCount: ko.observable(0),
            currentProductNoMatchCount: ko.observable(0),
            qmiMatchCount: ko.observable(0),
            homeMatchCount: ko.observable(0),
            totalHomePlans: ko.observable(0),
            totalQMIPlans: ko.observable(0),
            totalPlans: ko.observable(0)
        };

        self.toggleChecked = {
            home: ko.observable(true), 
            qmi: ko.observable(false)
        }

        self.filterView = function(item) {
            self.filterRef.onApply(self.viewLookupTable[self.viewObservable.productType()] + "-" + self.viewLookupTable[self.viewObservable.viewType()]);
        }

        // Toggle between list and grid view - current view stored in view observable object in view type - triggers an event and passes view to summary model to notify of change 
        self.toggleView = function(item, event) {
            var currentView = item.viewObservable.viewType(); 
            var eventView = $(event.target).attr("for");
            var updatedView = eventView == "r-grid" ? item.viewTypeOptions.gridViewLabel : item.viewTypeOptions.galleryViewLabel; 
            if($(window).outerWidth() < 1025){
                updatedView = self.viewTypeOptions.gridViewLabel; 
                currentView = item.viewTypeOptions.galleryViewLabel;
            }
            self.viewObservable.viewType(updatedView);
            var filterCount = self.filterRef.getFilterCount();
            $(".hdf-filter-listener").trigger("hdf-view-change",  
                    [self.viewLookupTable[self.viewObservable.productType()] + "-" + self.viewLookupTable[self.viewObservable.viewType()], 
                    filterCount > 0, this])
                    .trigger("compare-view-change", self.viewObservable.productType());  
          
            savedItemService.setSavedStateForDocument();
        }

         // Toggle between qmi and home view - current view stored in view observable object in product type - triggers an event and passes product to summary model to notify of change 
        self.toggleProduct = function(item, event){
            var toggledLabel = $(event.target).attr("for");
            self.toggleChecked.home(toggledLabel == "r-Homes");
            self.toggleChecked.qmi(toggledLabel == "r-QMIs");
            var updatedToggle = self.toggleChecked.home() ? self.productToggleOptions.homeToggleLabel : self.productToggleOptions.qmiToggleLabel;
            self.viewObservable.productType(updatedToggle);
            var filterCount = self.filterRef.getFilterCount();
            if($(window).outerWidth() < 1025){
               self.viewObservable.viewType(self.viewTypeOptions.gridViewLabel);
            }
            $(".hdf-filter-listener").trigger("hdf-view-change", 
                    [self.viewLookupTable[self.viewObservable.productType()] + "-" + self.viewLookupTable[self.viewObservable.viewType()],
                    filterCount > 0, this])
                    .trigger("compare-view-change", self.viewObservable.productType());  

            savedItemService.setSavedStateForDocument();
        }


        self.resetFilter = function() {
            self.filterRef.onReset();
            self.filterRef.onApply(self.viewLookupTable[self.viewObservable.productType()] + "-" + self.viewLookupTable[self.viewObservable.viewType()]);
        }
    
    }

    this.initialize = function (element, options) {
        
        var _this = this;

        var $filterBtn = this.$element.find(".HomeDesignFilter__management-filter-btn");
        var $mobileClose = this.$element.find("#filterNavIconCloseMobile")
        var $toggleInputs = this.$element.find(".HomeDesignFilter__management-toggles");
        var $bedroomInputs = this.$element.find("#HomeDesignFilter__management-rooms-toggles-bedrooms");
        var $bathroomInputs = this.$element.find("#HomeDesignFilter__management-rooms-toggles-bathrooms");
        var $viewContent = this.$element.find("#ManagementViewButtonLabel"); 


        $filterBtn.on('click', this.toggleFilter.bind(this));
        $mobileClose.bind('click', this.toggleFilter.bind(this));
        $bedroomInputs.on('click', utils.debounce(this.toggleBedroomSelection.bind(this) , 250, false));
        $bathroomInputs.on('click', utils.debounce(this.toggleBathroomSelection.bind(this) , 250, false));

        var $filterCircle = this.$element.find(".HomeDesignFilter__management-filter-circle")
        var $filterCloseIcon = this.$element.find("#filterNavIconClose")
        
        $filterCircle.addClass("hide")
        $filterCloseIcon.addClass("hide hide-xs")
    
        var $filterCircleColor = this.$element.find(".HomeDesignFilter__management-filter-circle"); 
        $filterCircleColor.css({
            "background-color" :  $filterCircleColor.data("filterCircleColor"), 
            "color" : $filterCircleColor.data("filterCircleTextColor")
        })

        var $filterContent = this.$element.find(".HomeDesignFilter__management-filter-content"); 
        
        if($filterContent.data("enableShadow")){
            $filterContent.css("box-shadow", "0 6px 7px 0 " + $filterContent.data("shadowColor"))
        }

        this.resetFilterValues()

        $(window).on("resize", utils.debounce(this.handleFilterLayout.bind(this), 250, false));
        
        $(document).on("click",function(e) {
            var parentFilter = e.target.closest(".HomeDesignFilter__management-filters");
            
            var closeOnClickElements = [];
            var $spaceBtwnFilterSearchAndHomes = _this.$element.find('.HomeDesignFilter__management-filters');
            var $spaceBtwnHomeAndGridToggle = _this.$element.find('.toggle-container');
            if ($spaceBtwnFilterSearchAndHomes != null) { 
                closeOnClickElements.push($spaceBtwnFilterSearchAndHomes[0]);
            }
            if ($spaceBtwnHomeAndGridToggle != null) { 
                closeOnClickElements.push($spaceBtwnHomeAndGridToggle[0]);
            }

            // Check if event target is a part of the filter and if filter is open
            if((parentFilter == null || closeOnClickElements.includes(e.target)) && $filterContent.hasClass("show-filter-content")) {
                _this.toggleFilter();
            }
        });

        var viewTypeOptions = {
            galleryViewLabel : $viewContent.data("galleryViewLabel"),
            gridViewLabel : $viewContent.data("gridViewLabel")
        }

        var productToggleOptions = {
            homeToggleLabel: $toggleInputs.data("homeToggleLabel") ,
            selectedToggleBackgroundColor :  $toggleInputs.data("selectedToggleFillColor"), 
            selectedToggleColor : $toggleInputs.data("selectedToggleTextColor"), 
            qmiToggleLabel: $toggleInputs.data("qmiToggleLabel"),
            unselectedToggleBackgroundColor :   $toggleInputs.data("unselectedToggleFillColor"), 
            unselectedToggleColor : $toggleInputs.data("unselectedToggleTextColor")
        }

        var viewLookupTable = {}
        viewLookupTable[$viewContent.data("galleryViewLabel")] = 'gallery'
        viewLookupTable[$viewContent.data("gridViewLabel")] = 'grid'
        viewLookupTable[$toggleInputs.data("homeToggleLabel")] = 'home'
        viewLookupTable[$toggleInputs.data("qmiToggleLabel")] = 'qmi'

        var koModelOptions = {
            viewTypeOptions: viewTypeOptions,
            productToggleOptions: productToggleOptions,
            viewLookupTable: viewLookupTable,
            filterRef: this
        }
        koModel = new viewModel(koModelOptions);
        this.$element.on('home-design-initialized', function(event, homePlansCount, qmiPlansCount) {
            koModel.viewObservable.currentProductMatchCount(homePlansCount),
            koModel.viewObservable.currentProductTotalCount(homePlansCount);
            koModel.viewObservable.homeMatchCount(homePlansCount);
            koModel.viewObservable.qmiMatchCount(qmiPlansCount);
            koModel.viewObservable.totalHomePlans(homePlansCount);
            koModel.viewObservable.totalQMIPlans(qmiPlansCount);
            koModel.viewObservable.totalPlans(homePlansCount + qmiPlansCount);
        });
        ko.applyBindings(koModel, element);
    };
    
    this.toggleFilter = function() {
        
        var $filter = this.$element.find(".HomeDesignFilter__management-filter");
        var $filterBtn = this.$element.find(".HomeDesignFilter__management-filter-btn"); 
        var $filterContent = this.$element.find(".HomeDesignFilter__management-filter-content"); 
        var $filterIconClose = this.$element.find("#filterNavIconClose");
        var $filterIconArrow = this.$element.find("#filterNavIconArrow");
       
        $filter.toggleClass("filter-open");
        $filterBtn.toggleClass("filter-button-closed");
        $filterContent.toggleClass("show-filter-content");
        $filterIconClose.toggleClass("hide d-flex");
        $filterIconArrow.toggleClass("hide d-flex");
        
        if($(window).outerWidth() < 768 && $filter.hasClass("filter-open")){
            $("body").css("overflow", "hidden");
        }
        else{
            $("body").removeAttr("style")
        }
        

        this.handleFilterLayout()

    }

    this.initRoomSelection = function(){
       
        var $bedroomInputs = this.$element.find("#HomeDesignFilter__management-rooms-toggles-bedrooms"); 
        var $bathroomInputs = this.$element.find("#HomeDesignFilter__management-rooms-toggles-bathrooms"); 
        
        $bedroomInputs.children().first().prop("checked", true);
        $bathroomInputs.children().first().prop("checked", true);

        this.toggleBedroomSelection();
        this.toggleBathroomSelection();

    }

    this.toggleBedroomSelection = function(){

        var $toggleInputs = this.$element.find("#HomeDesignFilter__management-rooms-toggles-bedrooms");
        var $selected = this.$element.find("#HomeDesignFilter__management-rooms-toggles-bedrooms input:checked+label");
        var $notSelected = this.$element.find("#HomeDesignFilter__management-rooms-toggles-bedrooms input:not(:checked):not(:disabled)+label");
        var $disabled = this.$element.find("#HomeDesignFilter__management-rooms-toggles-bedrooms input:disabled+label");

        var toggleStyleOn = {
            "background-color" :  $toggleInputs.data("roomFillColor"),
            "color" : $toggleInputs.data("roomTextColor"),
            "border-color" : $toggleInputs.data("roomBorderColor")
        }

        var toggleStyleOff = {
            "background-color" :  "inherit",
            "color" : $toggleInputs.data("roomTextColor"),
            "border-color" : "#b9b9b9"
        }

        var disabled = {
            "background-color" :  "rgba(118, 118, 118, 0.3)",
            "color" : $toggleInputs.data("roomTextColor"),
            "border-color" : "#b9b9b9"
        }

        $selected.css(toggleStyleOn)
        $notSelected.css(toggleStyleOff)
        $disabled.css(disabled)

    }

    this.toggleBathroomSelection = function(){
        
        var $toggleInputs = this.$element.find("#HomeDesignFilter__management-rooms-toggles-bathrooms");
        var $selected = this.$element.find("#HomeDesignFilter__management-rooms-toggles-bathrooms input:checked+label");
        var $notSelected = this.$element.find("#HomeDesignFilter__management-rooms-toggles-bathrooms input:not(:checked):not(:disabled)+label");
        var $disabled = this.$element.find("#HomeDesignFilter__management-rooms-toggles-bathrooms input:disabled+label");

        var toggleStyleOn = {
            "background-color" :  $toggleInputs.data("roomFillColor"), 
            "color" : $toggleInputs.data("roomTextColor"),
            "border-color" : $toggleInputs.data("roomBorderColor")
        }

        var toggleStyleOff = {
            "background-color" :  "inherit", 
            "color" : $toggleInputs.data("roomTextColor"),
            "border-color" : "#b9b9b9"
        }

        var disabled = {
            "background-color" :  "rgba(118, 118, 118, 0.3)", 
            "color" : $toggleInputs.data("roomTextColor"),
            "border-color" : "#b9b9b9"
        }
        
        $selected.css(toggleStyleOn)
        $notSelected.css(toggleStyleOff)
        $disabled.css(disabled)

    }

    this.initHomeOptions = function(){
        
        var homeOptions = this.$element.find(".HomeDesignFilter__management-options-checkbox")
        homeOptions.each(function(i){
            if(!homeOptions[i].disabled){
                homeOptions[i].checked = true
            }
                
        })

    }

    this.initSliders = function(){
        
        this.initSliderBounds(); 
        this.initSliderStyling()
        this.handleLowerPrSlider(); 
        this.handleUpperPrSlider(); 
        this.handleLowerSqftSlider(); 
        this.handleUpperSqftSlider(); 

    }

    this.initSliderBounds = function(){
        
        var lowerPrSlider = this.$element.find('#prLower'); 
        handleSliderBounds(lowerPrSlider, 
            parseInt(lowerPrSlider.data("prSliderMin")), 
            parseInt(lowerPrSlider.data("prSliderMax")),  
            parseInt(lowerPrSlider.data("prSliderMin")))

        var upperPrSlider = this.$element.find('#prUpper');
        handleSliderBounds(upperPrSlider, 
            parseInt(upperPrSlider.data("prSliderMin")), 
            parseInt(upperPrSlider.data("prSliderMax")),  
            parseInt(upperPrSlider.data("prSliderMax")));
            
        var lowerSqftSlider = this.$element.find('#sqftLower'); 
        handleSliderBounds(lowerSqftSlider, 
            parseInt(lowerSqftSlider.data("sqftSliderMin")), 
            parseInt(lowerSqftSlider.data("sqftSliderMax")),  
            parseInt(lowerSqftSlider.data("sqftSliderMin")))

        var upperSqftSlider = this.$element.find('#sqftUpper'); 
        handleSliderBounds(upperSqftSlider, 
            parseInt(upperSqftSlider.data("sqftSliderMin")), 
            parseInt(upperSqftSlider.data("sqftSliderMax")),  
            parseInt(upperSqftSlider.data("sqftSliderMax")))

        lowerPrSlider.on("input", this.handleLowerPrSlider.bind(this))
        upperPrSlider.on("input" , this.handleUpperPrSlider.bind(this));
        lowerSqftSlider.on("input", this.handleLowerSqftSlider.bind(this))
        upperSqftSlider.on("input" , this.handleUpperSqftSlider.bind(this));

    }

    function handleSliderBounds (element, min, max, initialValue){

        var step = Math.pow(10 , Math.floor(Math.log(max) / Math.log(10))-1)

        if(min == max || (max-min) < step){
            initialValue = max
            max = max+step
            min = min-step
            element.prop("disabled", true)

            element.attr("min", min)
            element.attr("max", max)
            element.val(initialValue)
            element.attr("step", step)

        } else {
            
            var floorMin = Math.floor(min/step) * step
            var ceilMax = Math.ceil(max/step) * step

            element.attr("min", floorMin)
            element.attr("max", ceilMax)
            element.val(initialValue == max ? ceilMax : floorMin)
            element.attr("step", step)

        }
        
        

    }

    this.initSliderStyling = function(){
        
        var prSliderLabel = this.$element.find('#prSliderLabel');
        var sqftSliderLabel = this.$element.find('#sqftSliderLabel');
        var prRangeColor = this.$element.find('#prRangeColor');
        var sqftRangeColor = this.$element.find('#sqftRangeColor');

        prSliderLabel.css("color", prSliderLabel.data("prSliderTextColor"))
        sqftSliderLabel.css("color", sqftSliderLabel.data("sqftSliderTextColor"))
        prRangeColor.css("background-color", prRangeColor.data("prSliderColor"))
        sqftRangeColor.css("background-color", sqftRangeColor.data("sqftSliderColor"))

    }

    this.handleLowerPrSlider = function(){
        
        var lowerSlider = this.$element.find('#prLower'); 
        var upperSlider = this.$element.find('#prUpper');

        handleLowerSlider(lowerSlider, upperSlider)
        this.handleSliderStyling()
  
    };

    this.handleUpperPrSlider = function(){
        
        var lowerSlider = this.$element.find('#prLower'); 
        var upperSlider = this.$element.find('#prUpper')

        handleUpperSlider(lowerSlider, upperSlider)
        this.handleSliderStyling()

    }

    this.handleLowerSqftSlider = function(){
       
        var lowerSlider = this.$element.find('#sqftLower'); 
        var upperSlider = this.$element.find('#sqftUpper');

        handleLowerSlider(lowerSlider, upperSlider)
        this.handleSliderStyling()

    };

    this.handleUpperSqftSlider = function(){
        
        var lowerSlider = this.$element.find('#sqftLower'); 
        var upperSlider = this.$element.find('#sqftUpper');

        handleUpperSlider(lowerSlider, upperSlider)
        this.handleSliderStyling()

    };
 

    function handleLowerSlider(lowerSlider, upperSlider){
       
        var lowerVal = parseInt(lowerSlider.val()); 
        var upperVal = parseInt(upperSlider.val()); 


        if ((lowerVal > upperVal - parseInt(lowerSlider.attr("step"))) && !lowerSlider.prop("disabled") ) {
            upperSlider.val( lowerVal + parseInt(lowerSlider.attr("step")));
            
            if (upperVal == upperSlider.attr("max") ) {
                lowerSlider.val(parseInt(upperSlider.attr("max")) - parseInt(lowerSlider.attr("step")));
            }
            lowerVal = parseInt(lowerSlider.val()); 
            upperVal = parseInt(upperSlider.val()); 
        }
    }

    function handleUpperSlider(lowerSlider, upperSlider){
       
        var lowerVal = parseInt(lowerSlider.val()); 
        var upperVal = parseInt(upperSlider.val()); 
   
        if ((upperVal < lowerVal + parseInt(upperSlider.attr("step"))) && !upperSlider.prop("disabled")) {
            lowerSlider.val(upperVal - parseInt(upperSlider.attr("step")));
            
            if (lowerVal == lowerSlider.attr("min")) {
                upperSlider.val(parseInt(lowerSlider.attr("min")) + parseInt(upperSlider.attr("step")));
            }

            lowerVal = parseInt(lowerSlider.val()); 
            upperVal = parseInt(upperSlider.val()); 

        }
    }

    this.handleSliderStyling = function(){
        
        var lowerPrSlider = this.$element.find('#prLower'); 
        var lowerPrValue = this.$element.find('#prAmountLower'); 
        var upperPrSlider = this.$element.find('#prUpper');
        var upperPrValue = this.$element.find('#prAmountUpper');
        var prRangeColor = this.$element.find('#prRangeColor');

        manageSliderStyling(lowerPrSlider, lowerPrValue, upperPrSlider, upperPrValue, prRangeColor);

        formatForCurrency(lowerPrValue);
        formatForCurrency(upperPrValue);

        var lowerSqftSlider = this.$element.find('#sqftLower'); 
        var lowerSqftValue = this.$element.find('#sqftAmountLower'); 
        var upperSqftSlider = this.$element.find('#sqftUpper');
        var upperSqftValue = this.$element.find('#sqftAmountUpper');
        var sqftRangeColor = this.$element.find('#sqftRangeColor');

        manageSliderStyling(lowerSqftSlider, lowerSqftValue, upperSqftSlider, upperSqftValue, sqftRangeColor);

        if(upperPrSlider.prop("disabled")){
            upperPrValue.addClass("hide")
        }

        if(upperSqftSlider.prop("disabled")){
            upperSqftValue.addClass("hide")
        }
    }

    function manageSliderStyling(lowerSlider, lowerValue, upperSlider, upperValue, rangeColor){
        
        var lowerVal = parseInt(lowerSlider.val()); 
        var upperVal = parseInt(upperSlider.val()); 
       
        var lowerBuffer = parseInt(lowerValue.innerWidth()) *.5;
        var upperBuffer = parseInt(upperValue.innerWidth()) *.5;

        normalizedLower = ((lowerVal - parseInt(lowerSlider.attr("min"))) / (parseInt(lowerSlider.attr("max")) - parseInt(lowerSlider.attr("min")))) *100
        normalizedUpper = ((upperVal - parseInt(lowerSlider.attr("min"))) / (parseInt(lowerSlider.attr("max")) - parseInt(lowerSlider.attr("min")))) *100
        
        diff = parseInt(normalizedUpper - normalizedLower) < 27 && parseInt(normalizedUpper - normalizedLower) > 0 ? 25 : 0 ;
        
        lowerBuffer = normalizedLower > 0 ? lowerBuffer : 0;
        upperBuffer = normalizedUpper < 100 ? upperBuffer : 0;
      
        lowerValue.css("margin-left", "calc(" + normalizedLower + '% - ' + (lowerBuffer+diff) + "px)")
        upperValue.css({
            "margin-right" : "calc(" + (100 - normalizedUpper) + '% - ' + (upperBuffer+diff) + "px)" , 
            "right" : 0
        }); 
        
       

        lowerValue.val(nFormatter(lowerSlider.val(), 1))
        upperValue.val(nFormatter(upperSlider.val(), 1))

        rangeColor.css("margin-left" , normalizedLower + '%');
        rangeColor.css("width" , normalizedUpper - normalizedLower + '%');
   
    }

    function formatForCurrency(num) {
        num.val("$"+num.val());
    }

    function nFormatter(num, digits) {
        
        var lookup = [
          { value: 1, symbol: "" },
          { value: 1e3, symbol: "k" },
          { value: 1e6, symbol: "M" },
          { value: 1e9, symbol: "G" },
          { value: 1e12, symbol: "T" },
          { value: 1e15, symbol: "P" },
          { value: 1e18, symbol: "E" }
        ];
        
        var rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
        
        var item = lookup.slice().reverse().find(function(item) {
          return num >= item.value;
        });
        
        return item ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol : "0";

    }

    this.handleFilterLayout = function(){
        
        var ctas = this.$element.find('.HomeDesignFilter__management-ctas'); 
        var buttons = this.$element.find('.HomeDesignFilter__management-buttons'); 
        var rooms = this.$element.find('.HomeDesignFilter__management-rooms');
        var options = this.$element.find('.HomeDesignFilter__management-options');

        if ($(window).outerWidth() < 1440) {
            ctas.removeClass("flex-md-column pl-md-9 my-6")
            ctas.addClass("justify-content-sm-center pt-6 full-width")
            buttons.addClass("mb-sm-6 ml-sm-8")
            ctas.css({
                "border-left": "none", 
                "border-top": "1px solid #b9b9b9"
            });
  
        }
        else{
            ctas.addClass("flex-md-column pl-md-9 my-6")
            ctas.removeClass("justify-content-sm-center pt-6 full-width")
            buttons.removeClass("mb-sm-6 ml-sm-8")
            ctas.css({
                "border-left": "1px solid #b9b9b9", 
                "border-top": "none"
            });
        }

        if($(window).outerWidth() < 1025){
            options.css("width", rooms.innerWidth() > 0 ? rooms.innerWidth() : "" )
        }
        else{
            options.css("width","")
        }

        if($(window).outerWidth() < 1025){
            this.$element.find('.HomeDesignFilter__management-view-btn').trigger('click')
         }
        
        this.handleSliderStyling()

    }

    this.getFilterCount = function() {
        var prLowerSlider = this.$element.find('#prLower'); 
        var prUpperSlider = this.$element.find('#prUpper');
        var sqftLowerSlider = this.$element.find('#sqftLower'); 
        var sqftUpperSlider = this.$element.find('#sqftUpper');
        var bedrooms = this.$element.find("#HomeDesignFilter__management-rooms-toggles-bedrooms"); 
        var bathrooms = this.$element.find("#HomeDesignFilter__management-rooms-toggles-bathrooms"); 
        var homeOptions = this.$element.find(".HomeDesignFilter__management-options-checkbox");

        var count = 0; 
   
        if(parseInt(prLowerSlider.val()) > (parseInt(prLowerSlider.attr("min"))) || parseInt(prUpperSlider.val()) < parseInt(prUpperSlider.attr("max"))){
            if(!prUpperSlider.prop("disabled")){
                count = count + 1
            }
            
        }
        if(parseInt(sqftLowerSlider.val()) > parseInt(sqftLowerSlider.attr("min")) || parseInt(sqftUpperSlider.val()) < parseInt(sqftUpperSlider.attr("max"))){
            if(!sqftUpperSlider.prop("disabled")){
                count = count + 1
            }
        }
        if(bedrooms.children("input:checked").val() != "Any"){
            count = count + 1
        }
        if(bathrooms.children("input:checked").val() != "Any"){
            count = count + 1
        }
        if(getSelectedHomeOptions(homeOptions, false).length > 0){
            count = count + 1
        }

        return count;

    }

    this.handleFilterCount = function(){
        var count = this.getFilterCount();
        
        var filterCircle = this.$element.find(".HomeDesignFilter__management-filter-circle")
        var arrowIcon = this.$element.find("#filterNavIconArrow")
        var closeIcon = this.$element.find("#filterNavIconClose")
        
        if(count > 0){
            filterCircle[0].innerHTML = count
            filterCircle.removeClass("hide")
            arrowIcon.removeClass("ml-sm-6 ml-lg-9")
            closeIcon.removeClass("ml-sm-6 ml-lg-9")
            arrowIcon.addClass("ml-sm-4")
            closeIcon.addClass("ml-sm-4")
        }
        else{
            filterCircle.addClass("hide")
            arrowIcon.addClass("ml-sm-6 ml-lg-9")
            closeIcon.addClass("ml-sm-6 ml-lg-9")
            arrowIcon.removeClass("ml-sm-4")
            closeIcon.removeClass("ml-sm-4")
        }

    }


    this.onApply = function(currentView){       
        var filters ={};
        
        var prLowerSlider = this.$element.find('#prLower'); 
        var prUpperSlider = this.$element.find('#prUpper');
        var sqftLowerSlider = this.$element.find('#sqftLower'); 
        var sqftUpperSlider = this.$element.find('#sqftUpper');
        var bedrooms = this.$element.find("#HomeDesignFilter__management-rooms-toggles-bedrooms"); 
        var bathrooms = this.$element.find("#HomeDesignFilter__management-rooms-toggles-bathrooms"); 
        var homeOptions = this.$element.find(".HomeDesignFilter__management-options-checkbox");
      
        filters["PR Lower"] = parseInt(prLowerSlider.val());
        filters["PR Upper"] = parseInt(prUpperSlider.val());
        filters["Sqft Lower"] = parseInt(sqftLowerSlider.val());
        filters["Sqft Upper"] = parseInt(sqftUpperSlider.val());
        filters["Bedrooms"] = bedrooms.children("input:checked").val() == "Any" ? 0 : parseInt(bedrooms.children("input:checked").val());
        filters["Bathrooms"] = bathrooms.children("input:checked").val() == "Any" ? 0 : parseInt(bathrooms.children("input:checked").val());
        filters["Home Options"] = getSelectedHomeOptions(homeOptions, true);

        this.handleFilterCount();
        var homeDesignContainer = $('.HomeDesignSummary__section-container');
        var isFiltered = this.getFilterCount() > 0;
        if (isFiltered > 0 && !homeDesignContainer.hasClass('is-filtered')) {
            homeDesignContainer.addClass('is-filtered');
        }

        if(!isFiltered) {
            homeDesignContainer.removeClass('is-filtered');
        }

        $(".hdf-filter-listener").trigger("hdf-filter-change", [filters, isFiltered, currentView, koModel]).trigger("compare-view-change", currentView);  

        savedItemService.setSavedStateForDocument();
    }

    function getSelectedHomeOptions(homeOptions, checked){
       
        var selectedHomeOptions = [];
        homeOptions.each(function(i){
            if(homeOptions[i].checked == checked && !homeOptions[i].disabled){
                selectedHomeOptions.push(homeOptions[i].value.toLowerCase())
            }
        });

        return selectedHomeOptions;
    }

    this.onReset = function() {
        this.resetFilterValues();
        this.handleFilterCount();
    }

    this.resetFilterValues = function() {
        this.initSliders();
        this.initRoomSelection();
        this.initHomeOptions();
    }
}

module.exports = component(HomeDesignFilter); 
