var Carousel = require('../../../templates/components/carousel/carousel');
var Slideshow = require('../../../templates/components/slideshow/slideshow');
var ResponsiveMedia = require('../../../templates/components/responsive-media/responsive-media');
var component = require('../../../lib/js/component.js');
var productMapController = require('../map/map-product-controller.js');
var requestInfo = require('../../../lib/js/request-info.js');
var utils = require('../../../lib/js/utils.js');
var mapPinHelper = require('../map/map-pin-helper.js');
var PopoverBtn = require('../tooltip/popover-btn.js');

var ProductMap = function () {
    var PMCisLoading = false;
    var brandsInLegend = {};

    this.initialize = function (el, options) {
        var _this = this;
        var state = this.$element.attr("data-state");
        var region = this.$element.attr("data-region");
        var city = this.$element.attr("data-city");
        var brand = $('body').data('brand');
        this.$body = $('body');
        this.hasExperienceMessage = $('.ExperienceMessage').length ? true : false;

        _this.$element.on('productsReceived', function () {
            _this.$element.find('img.u-responsiveMedia:not([src])').attach(ResponsiveMedia);
            _this.$element.find('.Carousel:not(.is-initialized,.SlideshowCarousel-main)').attach(Carousel);
            _this.$element.find('.Slideshow:not(.is-initialized)').attach(Slideshow);
            _this.$element.find('.ProductMap-Sidebar-schoolLink').on('click touch', _this.showSchoolDistrictModal.bind(_this));
            _this.$element.find('.ProductMap-backLink').on('click touch', _this.closeSchoolDistrictModal.bind(_this));
        });
        requestInfo.initRequestInfoModals();

        //store api url if it exists
        if (this.$body.data('api-url') && this.$body.data('api-url').length) {
            this.apiUrl = this.$body.data('api-url');
        } else {
            this.apiUrl = window.location.origin;
        }

        this.$productSelection = this.$element.find('.ProductMap-selection');
        this.$productSelectionClose = this.$element.find('.ProductMap-selectionClose');

        this.$productSelectionClose.on('click touch', this.resetPinSelection.bind(this));

        // This data structure is produced by createMapDataObj in FindHomes.js
        // Reproducing here to decouple this component from the FindHomes filter
        this.productLocation = {
            "brand": brand,
            "flag": "",
            "pageNumber": 0,
            "pageSize": 10,
            "productType": "community",
            "qmi": "false",
            "region": region,
            "state": state,
            "cityNames": city,
            "data": "",
            "searchForExact": false
        }

        if (city) {
            this.productLocation.flag = "any";
            this.productLocation.data = city;
        } else if (region) {
            this.productLocation.flag = "region";
            this.productLocation.data = region;
        } else {
            this.productLocation.flag = "state";
            this.productLocation.data = state;
        }

        if (window.location.href.toLowerCase().contains("qmi")) {
            this.productLocation.qmi = "true";
        }
               
        //var isGeoLocated = FindHomesData.initialLocation.isGeoLocated;
        //var isQMI = utils.hasHashValue('qmi');

        //TODO: Setup code to handle multiple cities (maybe in data-city & listen on filter change)

        this.$thisMap = this.$element.find(".Map");

        if (this.$thisMap && this.$thisMap.length > 0) {
            this.$thisMap.attach(productMapController, Pulte);

            if (window.Pulte.isMapScriptLoaded) {
                // trigger filter search for map based on current location
                this.$thisMap.trigger('filterSelected', this.productLocation);
            } else {
                $('body').one('MapScriptLoaded', function () {
                    // trigger filter search for map based on current location
                    _this.$thisMap.trigger('filterSelected', _this.productLocation);
                });
            }

            this.$thisMap.on('pinSelected', this.openProductSelection.bind(this));
            this.$thisMap.on('closeProductSelection', this.closeProductSelection.bind(this));
            this.$thisMap.on('pmap-loadMapLegend', this.loadMapLegend.bind(this));

            if (!utils.is.mobileOrTabletScreen()) {
                this.$element.find('.ProductMap__legendHeader').removeClass('collapsed');
                this.$element.find('.ProductMap__legendKey').addClass('in');
            }
        }

        this.$element.on("pf-change", this.updateMapResults.bind(this));

        this.$totals = $('.ProductMap--Totals');
        this.$totals.find('.total-communities').hide();
        this.$totals.find('.exact-communities').hide();
        this.$totals.find('.total-qmis').hide();
        this.$totals.find('.exact-qmis').hide();

        this.$totals.on('pmap-updateTotals', function (e, data) {

            var $element = $('.ProductMap--Totals');
            var totalCommunities = $element.find('.total-communities');
            var exactCommunities = $element.find('.exact-communities');
            var totalQMIs = $element.find('.total-qmis');
            var exactQMIs = $element.find('.exact-qmis');

            $element.find('.totals-loading').hide();


            if (typeof data.totalMatch !== 'undefined') {
                var totalCommunitiesText = $(totalCommunities).data('community-total-label');
                $(totalCommunities).find('.total-display').text(totalCommunitiesText.replace('{#}', data.totalMatch));
                $(totalCommunities).show();
                $(totalQMIs).hide();
                $(exactQMIs).hide();
            }

            if (typeof data.exactMatch !== 'undefined') {
                var exactCommunitiesText = $(exactCommunities).data('community-exact-label');
                $(exactCommunities).find('.total-display').text(exactCommunitiesText.replace('{#}', data.exactMatch));
                $(exactCommunities).show();
            }

            if (typeof data.totalMatchQMICommunity !== 'undefined') {
                var totalQMIsText = $(totalQMIs).data('qmi-total-label');
                $(totalQMIs).find('.total-display').text(totalQMIsText.replace('{#}', data.totalMatchQMICommunity));
                $(totalQMIs).show();
                $(totalCommunities).hide();
                $(exactCommunities).hide();
            }

            if (typeof data.exactMatchQMI !== 'undefined') {
                var exactQMIsText = $(exactQMIs).data('qmi-exact-label');
                $(exactQMIs).find('.total-display').text(exactQMIsText.replace('{#}', data.exactMatchQMI));
                $(exactQMIs).show();
            }
        });

        this.$totals.on('pmap-resetTotals', this.resetTotals.bind(this, false));
        this.$totals.on('pmap-resetExactMatches', this.resetTotals.bind(this, true));

        this.$element.find(".ProductMap-descriptionContent--collapsed").on('click', function (e) {
            e.preventDefault();
            $(".ProductMap-descriptionContent--collapsed").hide();
            $(".ProductMap-descriptionContent:not(.ProductMap-descriptionContent--collapsed)").show();
        });
    };

    this.resetTotals = function (exactOnly) {
        if (!exactOnly) {
            this.$totals.find('.total-communities').hide();
            this.$totals.find('.total-qmis').hide();
        }
        this.$totals.find('.exact-communities').hide();
        this.$totals.find('.exact-qmis').hide();
    }

    this.resetPinSelection = function (e) {
        e.preventDefault();
        this.$thisMap.trigger('resetPinSelection');
    };

    this.closeProductSelection = function () {
        if (this.$productSelection && this.$productSelection.length > 0) {
            if (this.$productSelection.hasClass('in')) {
                this.$productSelection.removeClass('in');
            }

            if (this.$body && this.$body.length > 0) {
                if (this.$body.hasClass('ProductMap-ModalOpenBody')) {
                    this.$body.removeClass('ProductMap-ModalOpenBody');
                }
            }
        }
    };

    this.openProductSelection = function (e, product) {
        var _this = this;

        if (product && this.$productSelection && this.$productSelection.length > 0) {
            if (!this.$productSelection.hasClass('in')) {
                this.$productSelection.addClass('in');
            }

            if (this.$body && this.$body.length > 0) {
                if (!this.$body.hasClass('ProductMap-ModalOpenBody')) {
                    this.$body.addClass('ProductMap-ModalOpenBody');
                }
            }

            var $current = $(this);

            if (!PMCisLoading) {

                var myNode = document.getElementById("ProductMap-productWrapper");
                var brandName = $(myNode).data('brand');
                while (myNode.hasChildNodes()) {
                    myNode.removeChild(myNode.firstChild);
                }
                this.$productSelection.find('.ProductMap-selectionLoading').show();

                PMCisLoading = true;
                if (product.isQmi) {
                    url = _this.apiUrl + '/api/product/GetQMISidebar?' + 'communityId=' + product.communityId + '&brandName=' + brandName;
                } else {
                    url = _this.apiUrl + '/api/product/GetCommunitySidebarById?' + 'communityId=' + product.communityId + '&brandName=' + brandName;
                }

                $.ajax({
                    type: 'GET',
                    context: this,
                    url: url,
                    success: function (response) {
                        var productWrapper = document.querySelector('.ProductMap-productWrapper');
                        var sidebarDiv = new DOMParser().parseFromString(response,'text/html').querySelectorAll('body>div');
                        sidebarDiv.forEach(function(el) {
                            productWrapper.appendChild(el);
                        });
                        _this.$element.trigger('productsReceived');
                        _this.$productSelection.find('.ProductMap-selectionLoading').hide();
                        _this.$element.find('.PopoverBtn').attach(PopoverBtn);
                        _this.$schoolDistrictModal = _this.$element.find('.ProductMap-schoolDistrictModal');
                        PMCisLoading = false;

                        if (!_this.hasExperienceMessage) {
                            var $sidebarButton = _this.$element.find('.experience-modal-button');
                            $sidebarButton.attr("href", $sidebarButton.data('href'));
                            $sidebarButton.removeAttr('data-toggle');
                        }
                    }
                });
            }
        }
    };

    this.showSchoolDistrictModal = function (e) {
        this.$schoolDistrictModal.addClass('in');
    }

    this.closeSchoolDistrictModal = function (e) {
        if (this.$schoolDistrictModal.hasClass('in')){
            this.$schoolDistrictModal.removeClass('in');
        }
    }

    this.updateMapResults = function (e, pfData) {
        if (utils.is.mobile()) {
            $(".ProductMap-descriptionContent:not(.ProductMap-descriptionContent--collapsed)").hide();
            $(".ProductMap-descriptionContent--collapsed").show();
        }

        this.resetPinSelection(e);

        if (pfData.productType === "qmi") {
            this.productLocation.qmi = "true";
        } else if (pfData.productType === "community") {
            this.productLocation.qmi = "false";
        }

        if (pfData.changeType) {
            if (pfData.changeType !== 'product') {
                this.productLocation.state = pfData.state;
                this.productLocation.region = '';
                this.productLocation.cityNames = '';
                this.productLocation.minPrice = pfData.minPrice;
                this.productLocation.maxPrice = pfData.maxPrice;
                this.productLocation.minBedrooms = pfData.minBedrooms;
                this.productLocation.maxBedrooms = pfData.maxBedrooms;
                this.productLocation.minBathrooms = pfData.minBathrooms;
                this.productLocation.maxBathrooms = pfData.maxBathrooms;
                this.productLocation.homeType = pfData.homeType;
                this.productLocation.flag = 'state';
                this.productLocation.data = '';

                if (pfData.changeType === 'filter') {
                    this.productLocation.searchForExact = true;
                } else if (pfData.changeType === 'location') {
                    this.productLocation.searchForExact = false;
                }
            }
        }

        if (pfData.region) {
            this.productLocation.region = pfData.region;
            this.productLocation.data = pfData.region;
            this.productLocation.flag = "region"
        }

        if (pfData.cities) {
            this.productLocation.cityNames = pfData.cities;
            this.productLocation.data = pfData.cities;
            this.productLocation.flag = 'any';
        }

        if (pfData.changeType && pfData.changeType === 'filter') {
            this.$thisMap.trigger('sorterSelected', this.productLocation);
        } else {
            this.$thisMap.trigger('filterSelected', this.productLocation);
        }
    }

    this.setMapPinIcon = function ($element, iconSvgProperties) {
        iconSvgProperties.scale = .75;
        var mapPinProperties = mapPinHelper.getGoogleMapPinObject(iconSvgProperties);
        $element.attr({ 'src': mapPinProperties.url, 'width': mapPinProperties.scaledSize.width, 'height': mapPinProperties.scaledSize.height });
    }

    this.loadMapLegend = function (e, brandsInSearch) {

        // Show rows if (and only if) the current search contains that brand
        if (brandsInSearch.pulte) {
            if (!brandsInLegend.pulte) {
                this.setMapPinIcon(this.$element.find('.ProductMap__pulteExact'), { brandName: 'Pulte', pinType: 'exact' });
                this.setMapPinIcon(this.$element.find('.ProductMap__pulteClose'), { brandName: 'Pulte', pinType: 'close' });
                brandsInLegend.pulte = true;
            }
            this.$element.find('.ProductMap__pulte').show();
        } else {
            this.$element.find('.ProductMap__pulte').hide();
        }

        if (brandsInSearch.pulteActiveAdult) {
            if (!brandsInLegend.pulteActiveAdult) {
                this.setMapPinIcon(this.$element.find('.ProductMap__pulteActiveAdultExact'), { brandName: 'Pulte', pinType: 'exact', isActiveAdult: true });
                this.setMapPinIcon(this.$element.find('.ProductMap__pulteActiveAdultClose'), { brandName: 'Pulte', pinType: 'close', isActiveAdult: true });
                brandsInLegend.pulteActiveAdult = true;
            }
            this.$element.find('.ProductMap__pulteActiveAdult').show();
        } else {
            this.$element.find('.ProductMap__pulteActiveAdult').hide();
        }

        if (brandsInSearch.centex) {
            if (!brandsInLegend.centex) {
                this.setMapPinIcon(this.$element.find('.ProductMap__centexExact'), { brandName: 'Centex', pinType: 'exact' });
                this.setMapPinIcon(this.$element.find('.ProductMap__centexClose'), { brandName: 'Centex', pinType: 'close' });
                brandsInLegend.centex = true;
            }
            this.$element.find('.ProductMap__centex').show();
        } else {
            this.$element.find('.ProductMap__centex').hide();
        }

        if (brandsInSearch.delwebb) {
            if (!brandsInLegend.delwebb) {
                this.setMapPinIcon(this.$element.find('.ProductMap__delwebbExact'), { brandName: 'DelWebb', pinType: 'exact' });
                this.setMapPinIcon(this.$element.find('.ProductMap__delwebbClose'), { brandName: 'DelWebb', pinType: 'close' });
                brandsInLegend.delwebb = true;
            }
            this.$element.find('.ProductMap__delwebb').show();
        } else {
            this.$element.find('.ProductMap__delwebb').hide();
        }

        if (brandsInSearch.divosta) {
            if (!brandsInLegend.divosta) {
                this.setMapPinIcon(this.$element.find('.ProductMap__divostaExact'), { brandName: 'DiVosta', pinType: 'exact' });
                this.setMapPinIcon(this.$element.find('.ProductMap__divostaClose'), { brandName: 'DiVosta', pinType: 'close' });
                brandsInLegend.divosta = true;
            }
            this.$element.find('.ProductMap__divosta').show();
        } else {
            this.$element.find('.ProductMap__divosta').hide();
        }

        if (brandsInSearch.jw) {
            if (!brandsInLegend.jw) {
                this.setMapPinIcon(this.$element.find('.ProductMap__jwExact'), { brandName: 'JW', pinType: 'exact' });
                this.setMapPinIcon(this.$element.find('.ProductMap__jwClose'), { brandName: 'JW', pinType: 'close' });
                brandsInLegend.jw = true;
            }
            this.$element.find('.ProductMap__jw').show();
        } else {
            this.$element.find('.ProductMap__jw').hide();
        }

        if (brandsInSearch.americanwest) {
            if (!brandsInLegend.americanwest) {
                this.setMapPinIcon(this.$element.find('.ProductMap__americanwestExact'), { brandName: 'AmericanWest', pinType: 'exact' });
                this.setMapPinIcon(this.$element.find('.ProductMap__americanwestClose'), { brandName: 'AmericanWest', pinType: 'close' });
                brandsInLegend.americanwest = true;
            }
            this.$element.find('.ProductMap__americanwest').show();
        } else {
            this.$element.find('.ProductMap__americanwest').hide();
        }
    }

};

module.exports = component(ProductMap);
