var component = require('../../../lib/js/component.js');
var requestInfo = require('../../../lib/js/request-info.js');
var Carousel = require('../../../templates/components/carousel/carousel');
var ResponsiveMedia = require('../../../templates/components/responsive-media/responsive-media');
var Tooltip = require('../../../templates/components/tooltip/tooltip');
var SlideShow = require('../../../templates/components/slideshow/slideshow');
var PopoverBtn = require('../tooltip/popover-btn');
var savedItemsService = require('../../../lib/js/savedItemsService.js');
var AdobeLaunch = require('../../../lib/js/adobelaunch.js');
var deeplink = require('../../../lib/js/deeplink.js');

function ProductSummary() {

    this.initialize = function (element, options) {
        this.lastItem = null;

        var _this = this;
        var communityPageNumber = 0;
        var qmiPageNumber = 0;
        var totalCommunities = 0;
        var totalQMIs = 0;
        var exactMatchCommunitiesCount = 0;
        var exactMatchCountsUpdated = false;
        var apiUrl = window.location.origin;
        var gettingProduct = false;
        var gettingTotals = false;
        var $communityContainer = this.$element.find('.ProductSummary__communityContainer');
        var $qmiSummaries = this.$element.find('.QMISummaries');
        var $showMore = this.$element.find('.ProductSummary__btn-show-more');
        var $productsLoadingSpinner = this.$element.find('.ProductSummary__productsLoading');
        var allShowing = false;
        var $body = $('body');
        this.hasExperienceMessage = $('.ExperienceMessage').length ? true : false;
        this.setExperienceModalButtons();

        // first round of tooltips will already have the Tooltip.js attached, therefore add the initialized class
        this.$element.find('.ProductSummary__community .LMP-Tooltip').addClass('ProductSummary__community--tooltipInitialized');

        $qmiSummaries.hide();
        this.data = {
			"brand": "",
			"state": "",
			"region": "",
			"cityNames": "",
			"minPrice": "",
			"maxPrice": "",
			"minBedrooms": "",
			"maxBedrooms": "",
			"minBathrooms": "",
            "maxBathrooms": "",
            "homeType": "",
            "productType": "",
			"pageSize": 5,
			"pageNumber": 0
        }

        this.data.brand = $body.data('brand');
        this.data.state = this.$element.data('state');
        this.data.region = this.$element.data('region');
        this.data.cityNames = this.$element.data('cities');
        
        if ($body.data('api-url') && $body.data('api-url').length > 0) {
            apiUrl = $body.data('api-url');
        }

        this.findTotals = function() {
            gettingTotals = true;
            $.ajax({
                type: 'GET',
                context: this,
                url: apiUrl + '/api/map/search?' + 'brand=' + this.data.brand + '&state=' + this.data.state + '&region=' + this.data.region + '&cityNames=' + this.data.cityNames,
                success: function(response) {
                    totalCommunities = response.CommunityCount;
                    totalQMIs = response.QmiCount;
                    gettingTotals = false;
                    if (!gettingProduct) {
                        _this.checkTotals();
                    }
                }
            });
        }

        this.findTotals();

        this.$element.on("pf-change", function(event, pfData) {
            $showMore.hide();
            
            switch(pfData.changeType) {
                case "product":
                    _this.data.productType = pfData.productType;
                    break;

                case "filter":
                    _this.data.minPrice = pfData.minPrice;
                    _this.data.maxPrice = pfData.maxPrice;
                    _this.data.minBedrooms = pfData.minBedrooms;
                    _this.data.maxBedrooms = pfData.maxBedrooms;
                    _this.data.minBathrooms = pfData.minBathrooms;
                    _this.data.maxBathrooms = pfData.maxBathrooms;
                    _this.data.homeType = pfData.homeType;
                    exactMatchCountsUpdated = false;
                    break;

                case "location":
                    _this.data.state = pfData.state;
                    _this.data.region = pfData.region;
                    _this.data.cityNames = pfData.cities;
                    _this.findTotals();
                    exactMatchCountsUpdated = false;
                    break;
            }

            if (_this.data.productType === "qmi") {
                _this.ShowQMI();
            } else {
                _this.showCommunities();
            }
        });

        requestInfo.initRequestInfoModals();
        $productsLoadingSpinner.hide();

        this.showCommunities = function() {
            communityPageNumber = 0;
            $communityContainer.empty();
            this.GetMoreCommunities();
            $qmiSummaries.hide();
            $communityContainer.show();
        }

        this.GetMoreCommunities = function() {
            gettingProduct = true;
            this.data.pageSize = 5;
            this.data.pageNumber = communityPageNumber;
            var url = apiUrl + '/api/Community/ProductCommunitySearch?';
            var params = 'brand=' + this.data.brand + '&state=' + this.data.state + '&region=' + this.data.region + '&cityNames=' + this.data.cityNames
                + '&minPrice=' + this.data.minPrice + '&maxPrice=' + this.data.maxPrice + '&minBedrooms=' + this.data.minBedrooms + '&maxBedrooms='
                + this.data.maxBedrooms + '&minBathrooms=' + this.data.minBathrooms + '&maxBathrooms=' + this.data.maxBathrooms + '&homeType=' + this.data.homeType + '&pageSize='
                + this.data.pageSize + '&pageNumber=' + this.data.pageNumber;

            $productsLoadingSpinner.show();
            $.ajax({
                type: 'GET',
                url: url + params,
                success: function(response) {                    
                    $communityContainer.append(response);

                    // update save buttons for the newly loaded communities
                    savedItemsService.setChildSavedState($communityContainer[0]);

                    _this.updateCommunityLmpTooltips();
                    _this.$element.trigger('productsReceived');
                    gettingProduct = false;
                    $productsLoadingSpinner.hide();

                    if (!gettingTotals) {
                        _this.checkTotals();
                    }

                    _this.lastItem = $('.ProductSummary__communityContainer').find('.ProductSummary__community.row:nth-last-child(' + _this.data.pageSize + ')');
                    _this.setExperienceModalButtons();

                    // wrap the response in a jquery object and pass it into the analytics trigger
                    var analyticsDiv = new DOMParser().parseFromString(response,'text/html').querySelectorAll('body>div');
                    var responseObj = $(analyticsDiv);
                    _this.triggerAnalyticsDataLayerEvent(responseObj, AdobeLaunch.COMMUNITY_LISTING_DISPLAYED_EVENT_NAME);
                }
            });

            communityPageNumber++;
        }

        this.ShowQMI = function() {
            qmiPageNumber = 0;
            $qmiSummaries.empty();
            this.GetMoreQMIs();
            $communityContainer.hide();
            $qmiSummaries.show();
        }

        this.GetMoreQMIs = function() {
            gettingProduct = true;
            this.data.pageSize = 10;
            this.data.pageNumber = qmiPageNumber;
            var url = apiUrl + '/api/Qmi/Search?';
            var params = 'brand=' + this.data.brand + '&state=' + this.data.state + '&region=' + this.data.region + '&cityNames=' + this.data.cityNames
                + '&minPrice=' + this.data.minPrice + '&maxPrice=' + this.data.maxPrice + '&minBedrooms=' + this.data.minBedrooms + '&maxBedrooms='
                + this.data.maxBedrooms + '&minBathrooms=' + this.data.minBathrooms + '&maxBathrooms=' + this.data.maxBathrooms + '&pageSize='
                + this.data.pageSize + '&pageNumber=' + this.data.pageNumber;

            $productsLoadingSpinner.show();
            $.ajax({
                type: 'GET',
                url: url + params,
                success: function(response) {
                    $qmiSummaries.append(response);

                    // update save buttons for the newly loaded qmis
                    savedItemsService.setChildSavedState($qmiSummaries[0]);

                    _this.$element.trigger('productsReceived');
                    gettingProduct = false;
                    $productsLoadingSpinner.hide();
                    if (!gettingTotals) {
                        _this.checkTotals();
                    }

                    _this.lastItem = $('.QMISummaries').find('.HomeDesignSummary--qmi:nth-last-child(' + _this.data.pageSize + ')');
                    _this.setExperienceModalButtons();
                    _this.updateQmiPopoverBtnTooltips();

                    // get each qmi element from the response and pass it into the analytics trigger method
                    var $qmiElements = $(response).find('.HomeDesignSummary--qmi');
                    _this.triggerAnalyticsDataLayerEvent($qmiElements, AdobeLaunch.PLAN_LISTING_DISPLAYED_EVENT_NAME);
                }
            });

            qmiPageNumber++;
        }
        
        this.GetMoreProductSummaries = function() {
            if (!allShowing && $.active === 0) {
                if (_this.lastItem && _this.lastItem.length) {
                    var lastItemTop = _this.lastItem.offset().top - (window.innerHeight);
                    var y = $(window).scrollTop();

                    if (y >= lastItemTop) {
                        $(window).trigger('scrollingContent');

                        if (this.data.productType === "qmi") {
                            _this.GetMoreQMIs();
                        } else {
                            _this.GetMoreCommunities();
                        }
                    }
                }
            }
        }

        this.checkTotals = function() {
            allShowing = false;
            var maxShowing = (this.data.pageNumber + 1) * this.data.pageSize;
            
            if(this.data.productType === 'qmi') {
                if (maxShowing >= totalQMIs) {
                    allShowing = true;
                }
            } else {
                if (maxShowing >= totalCommunities) {
                    allShowing = true;
                }
            }

            if (allShowing || this.data.pageNumber >= 1) {
                $showMore.hide();
            } else {
                $showMore.show();
            }
        }

        this.triggerAnalyticsDataLayerEvent = function($elements, eventName) {
            if ($elements && $elements.length && eventName) {
                if (eventName === AdobeLaunch.COMMUNITY_LISTING_DISPLAYED_EVENT_NAME) {
                    // update the exact match counts before calling analytics if they haven't been set yet for current products
                    if (!exactMatchCountsUpdated) {
                        $.ajax({
                            type: 'GET',
                            context: this,
                            url: apiUrl + '/api/map/search?' + 'brand=' + this.data.brand + '&state=' + this.data.state + '&region=' + this.data.region + '&cityNames=' + this.data.cityNames +
                                '&minPrice=' + this.data.minPrice + '&maxPrice=' + this.data.maxPrice + '&minBedrooms=' + this.data.minBedrooms + '&maxBedrooms=' + this.data.maxBedrooms +
                                '&minBathrooms=' + this.data.minBathrooms + '&maxBathrooms=' + this.data.maxBathrooms + '&homeType=' + this.data.homeType,
                            success: function(response) {
                                exactMatchCommunitiesCount = response.CommunityCount;
                                exactMatchCountsUpdated = true;
            
                                AdobeLaunch.pushListingDisplayedEvent($elements, eventName, this.data, exactMatchCommunitiesCount);
                            }
                        });
                    } else {
                        AdobeLaunch.pushListingDisplayedEvent($elements, eventName, this.data, exactMatchCommunitiesCount);
                    }
                } else if (eventName === AdobeLaunch.PLAN_LISTING_DISPLAYED_EVENT_NAME) {
                    AdobeLaunch.pushListingDisplayedEvent($elements, eventName, this.data);
                }
            }
        }

        _this.$element.on('productsReceived', function() {
            _this.$element.find('img.u-responsiveMedia:not([src])').attach(ResponsiveMedia);
            _this.$element.find('.Carousel:not(.is-initialized,.SlideshowCarousel-main)').attach(Carousel);
            _this.$element.find('.Slideshow:not(.is-initialized)').attach(SlideShow);
            _this.$element.find("[data-toggle='popover']").popover({html:true});
            _this.$element.find(".PopoverBtn:not(.is-initialized)").attach(Tooltip);
        });

        $showMore.on('click', function() {
            $(this).hide();
            if (_this.data.productType === "qmi") {
                _this.GetMoreQMIs();
            } else {
                _this.GetMoreCommunities();
            }
            $(window).on( 'scroll', function() {
                _this.GetMoreProductSummaries();
            });
        });

        // wait for deeplink processing to finish
        deeplink.deeplinkProcessed.then(function() {
            // if product type isn't already set to qmi, then load the communities
            if (_this.data.productType !== 'qmi') {
                _this.showCommunities();
            }
        });
    }
    
    //If the experience modal is not on the page, the button clicks should navigate the user to the appropriate community/qmi page without the experience message popup.
    this.setExperienceModalButtons = function() {
        if (!this.hasExperienceMessage) {
            this.$element.find('.experience-modal-button').each( function() {
                var $this = $(this);
                $this.attr("href", $this.data('href'));
                $this.removeAttr('data-toggle');
            });

        }
    }

    this.updateQmiPopoverBtnTooltips = function() {
        var tooltips = this.$element.find('.QmiSummary--search .PopoverBtn:not(.QmiSummary--tooltipInitialized)');

        tooltips.attach(PopoverBtn);
        tooltips.addClass('QmiSummary--tooltipInitialized');
    }

    this.updateCommunityLmpTooltips = function() {
        var tooltips = this.$element.find('.ProductSummary__community .LMP-Tooltip:not(.ProductSummary__community--tooltipInitialized)');

        tooltips.attach(PopoverBtn);
        tooltips.addClass('ProductSummary__community--tooltipInitialized');
    }

    this.deeplink = function(event, val) {
        if (val == "qmi") {
            this.data.productType = "qmi";

            var qmiButton = $('[data-product-type="qmi"]');

            qmiButton.siblings().removeClass('is-selected');
            qmiButton.addClass('is-selected');

            this.ShowQMI();
        } 
    }
}

module.exports = component(ProductSummary);
