var component = require('../../../lib/js/component.js');

function ShareItem() {
    /**
     * Init for the ShareItem module
     * @param  {Object} element HTML element
     */
    this.initialize = function (element) {
        var imageURL, pageURL, emailBody, $element = $(element);

        $element.on('show.bs.modal', function (e) {
            imageURL = $(e.relatedTarget).attr('data-media');
            pageURL = $(e.relatedTarget).attr('data-url');
            emailBody = $(e.relatedTarget).attr('data-emailcontent');
        });

        $element.on("click", "a", function (e) {
          var self = $(this);
          var newLines = '%0D%0A%0D%0A';
          var pinterestMediaEncoded = encodeURIComponent("https://res.cloudinary.com/dv0jqjrc3/image/fetch/w_1200/" + imageURL);

          if (self.hasClass("fa-envelope")) window.open("mailto:?body=" + emailBody + newLines + pageURL);
          if (self.hasClass("fa-x-twitter")) window.open("https://twitter.com/intent/tweet?url=" + pageURL);
          if (self.hasClass("fa-facebook-f")) window.open("https://www.facebook.com/sharer/sharer.php?u=" + pageURL);
          if (self.hasClass("fa-pinterest")) window.open("https://www.pinterest.com/pin-builder/?description=%22%22&media=" + pinterestMediaEncoded + "&method=button&url=" + encodeURIComponent(pageURL));
        });
    };
}

module.exports = component(ShareItem);