var AdobeLaunch = require('../../lib/js/adobelaunch.js');
var ResponsiveMedia = require('../../templates/components/responsive-media/responsive-media');
var Form = require('../../templates/components/forms/form.js');
var constants = require('../../templates/components/forms/constants.js');
var analyticsForm = require('../../templates/components/forms/analyticsForm.js');
var CommunityLeadForm = require('../../templates/components/community-lead-form/community-lead-form.js');
var RIMisLoading = false;
var RIMisInitialized = false;
var userIsAuthenticated = false;
var isCreateAccountForm = false;
var isLoginForm = false;
var $modalFormContainer = null;

function initRequestInfoModals(event, $element) {
    if (!RIMisInitialized) {
        $(document).on('click', "a[data-target^='.modal-requestInfo']", function (e) {
            var $current = $(this);

            if (!$current.attr("data-trigger-modal")) {
                $(".CommunitySummary-buttons a[data-target^='.modal-requestInfo'], .HomeDesignSummary-buttons a[data-target^='.modal-requestInfo'], .QMISummary-buttons a[data-target^='.modal-requestInfo'], .ProductMap-Sidebar-buttons a[data-target^='.modal-requestInfo']").attr("data-trigger-modal", "");
                var target = $current.data("target");

                if ($(target).length == 0) {
                    e.preventDefault();

                    if (!RIMisLoading) {
                        var communityId = target.replace(".modal-requestInfo[data-community-id='", "").replace("']", "");
                        var formCommunityId = "";
                        var $communityModalLeadForm = $(".CommunityModalLeadForm");

                        if ($communityModalLeadForm.length > 0) {
                            var datasource = $communityModalLeadForm.data('datasource');
                            var $communityIdInput = $communityModalLeadForm.find("input[name=CommunityIds]");
                            if ($communityIdInput.length > 0) {
                                formCommunityId = $communityIdInput.val();
                            }

                            if (communityId != formCommunityId) {
                                $communityModalLeadForm.find(".CommunityLeadForm").remove();
                                var communityLeadFormUrl = window.location.origin + '/api/forms/CommunityLeadForm?' + 'communityId=' + communityId + '&datasource=' + datasource;
                                RIMisLoading = true;
                                $.ajax({
                                    context: this,
                                    url: communityLeadFormUrl,
                                    success: function (response) {
                                        $(response).appendTo($communityModalLeadForm.find(".CommunityModalLeadForm__container"));

                                        $communityModalLeadForm.find('img.u-responsiveMedia:not([src])').attach(ResponsiveMedia);
                                        $communityModalLeadForm.modal('show');
                                        setupForm($communityModalLeadForm);
                                    },
                                    complete: function () {
                                        RIMisLoading = false;
                                    }
                                });
                                var brand = $('body').data('brand');
                                AdobeLaunch.pushFormViewedEvent(AdobeLaunch.FORM_COMMUNITY_ID, AdobeLaunch.FORM_MODAL_TYPE,
                                    AdobeLaunch.FORM_REQUEST_INFO_NAME, communityId, brand);
                            } else {
                                $communityModalLeadForm.modal('show');
                                setupForm($communityModalLeadForm);
                            }
                        }
                        else {
                            var commSpecificModalId = "Contactnav-community-specific";

                            if ($("#communitySpecificForm input[name=CommunityId]").length > 0) {
                                formCommunityId = $("#communitySpecificForm input[name=CommunityIds]").val();
                            }

                            if (communityId != formCommunityId) {
                                $(".ContactNavigation-body #" + commSpecificModalId).remove();
                                var communitySpeceficContactStepUrl = window.location.origin + '/api/forms/CommunitySpecificContactStep?' + 'communityId=' + communityId;
                                RIMisLoading = true;
                                $.ajax({
                                    context: this,
                                    url: communitySpeceficContactStepUrl,
                                    success: function (response) {
                                        $(response).appendTo(".ContactNavigation-body");
                                        // Clear out any existing or previous community specific contact forms
                                        // before triggering the function to add this new, current one.
                                        window.Pulte.contactForms = window.Pulte.contactForms.filter(function (form) {
                                            return form.id != commSpecificModalId;
                                        })
                                        $(".ContactNavigation").trigger("ContactNavigation.RegisterForm", $("#" + commSpecificModalId));
                                        showActiveFormModal(commSpecificModalId);
                                        populateDataFromLeadForm($current);
                                    },
                                    complete: function () {
                                        RIMisLoading = false;
                                    }
                                });
                            } else {
                                showActiveFormModal(commSpecificModalId);
                                populateDataFromLeadForm($current);
                            }
                        }
                    }
                }
            }
        });
    }
    RIMisInitialized = true;

    function showActiveFormModal(modalId) {
        if (window.Pulte && window.Pulte.contactForms) {
            window.Pulte.contactForms.forEach(function (form) {
                if (form.id === modalId) {
                    form.show();
                    if (form.communityId) {
                        AdobeLaunch.pushFormViewedEvent(AdobeLaunch.FORM_COMMUNITY_ID, AdobeLaunch.FORM_MODAL_TYPE,
                            AdobeLaunch.FORM_REQUEST_INFO_NAME, form.communityId, form.communityBrand);
                    }
                }
            });
        }
    }

    function populateDataFromLeadForm($element) {
        if ($element.hasClass("lead-form-submit")) {
            // store the form information coming from the modal trigger button
            var firstName = $element.attr("data-first-name"),
                lastName = $element.attr("data-last-name"),
                email = $element.attr("data-email"),
                zipcode = $element.attr("data-zipcode"),
                phone = $element.attr("data-phone");

            // pass the form information into the RFMI lead form and trigger the error validation checks
            var $communitySpecificForm = $("#communitySpecificForm");
            if ($communitySpecificForm.length) {
                $firstNameFormInput = $communitySpecificForm.find('[name="FirstName"]');
                $lastNameFormInput = $communitySpecificForm.find('[name="LastName"]');
                $emailFormInput = $communitySpecificForm.find('[name="Email"]');
                $zipcodeFormInput = $communitySpecificForm.find('[name="ZipCode"]');
                $phoneFormInput = $communitySpecificForm.find('[name="Phone"]');

                if ($firstNameFormInput.length) {
                    $firstNameFormInput.val(firstName);
                    $firstNameFormInput.blur();
                }
                if ($lastNameFormInput.length) {
                    $lastNameFormInput.val(lastName);
                    $lastNameFormInput.blur();
                }
                if ($emailFormInput.length) {
                    $emailFormInput.val(email);
                    $emailFormInput.blur();
                }
                if ($zipcodeFormInput.length) {
                    $zipcodeFormInput.val(zipcode);
                    $zipcodeFormInput.blur();
                }
                if ($phoneFormInput.length) {
                    $phoneFormInput.val(phone);
                    $phoneFormInput.blur();
                }
            }
        }
    }

    handleSubmit = function (event, form) {
        if (isLoginForm) {
            form.data.username = form.data.Email;
        }

        if (!form.data.IsRealtor) {
            form.data.BrokerOffice = '';
            form.data.BrokerId = '';
        }

        $.ajax({
            method: form.options.method,
            url: form.options.action,
            data: form.data,
            dataType: "json",
            context: this,
            success: function (response) {
                if (isCreateAccountForm || isLoginForm) {
                    $(".CommunityModalLeadForm").modal('hide');

                    if (isCreateAccountForm) {
                        AdobeLaunch.pushUserEvent(AdobeLaunch.USER_REGISTERED_EVENT_NAME);
                    } else {
                        AdobeLaunch.pushUserEvent(AdobeLaunch.USER_SIGNED_IN_EVENT_NAME, response.UserId);
                    }
                    location.href = response.RedirectUrl;
                } else if (response.success) {
                    // show thank you messaging and forms if successful
                    formSubmissionSuccess(form, response.userExists);
                }
                analyticsForm.RewriteObjectForm(response, event);
            },
            error: onError
        });
    }

    function getQueryParamValue(paramName) {
        var params = new URLSearchParams(window.location.search);
        return params.get(paramName);
    }

    function sendPopupInteractiontoPersanalize(eventName) {
        var customEventData = {
            "channel": "WEB",
            "language": "EN",
            "currency": "USD",
            "page": window.location.href,
            "pointOfSale": Engage.settings.pointOfSale
        };
        engage.event(eventName, customEventData);
    }

    function checkAndExecuteCDPEvent(interactionType) {
        var cdpPopupValue = getQueryParamValue('cdp_popup');
        
        if (cdpPopupValue === 'true') {
            sendPopupInteractiontoPersanalize(interactionType);
        }
    }

    function formSubmissionSuccess(form, isExistingUser) {
        var $communityForm = $modalFormContainer.find('.CommunityLeadForm');
        if (form.data) {
            var edhRegionId = $communityForm.find('.Field-edhregionid').val();
            AdobeLaunch.pushFormSubmitSucceededEvent(AdobeLaunch.FORM_COMMUNITY_ID, AdobeLaunch.FORM_MODAL_TYPE, AdobeLaunch.FORM_REQUEST_INFO_NAME, form.communityId, null, edhRegionId);
            $communityForm.find('.CommunityLeadForm__customer-info-selection-container').addClass('d-none');
            sessionStorage.setItem('communityFormSubmittedSuccessfully', 'true');
            sessionStorage.setItem('communityFormFirstName', form.data.FirstName);
            checkAndExecuteCDPEvent("LEAD_FORM_SUBMIT");
            updateLeadFormField("leadFormSubmitted",window.location.pathname)
            var urlParams = new URLSearchParams(window.location.search);
            if (urlParams.get("cdp-popup") === "return-to-last-viewed-community")
            {
                sendPopupInteractiontoPersanalize("RETURN_TO_LAST_VIEWED_COMMUNITY_LEAD_FORM_SUBMIT");
            }
            setupThankYouMessage(userIsAuthenticated, isExistingUser);

            var isB2CEnabled = $communityForm.data("b2cEnabled")
            if (!userIsAuthenticated) {

                //temporary feature flag to change styling to enable testing of Azure B2C
                if((isB2CEnabled.toLowerCase() === 'true')){
                    convertFormToB2CLogin()
                }
                else{
                    if (isExistingUser) {
                        convertFormToLogin();
                    } else {
                        convertFormToCreateAccount();
                    }
                }
                
            } else {
                //unhide the modal done button if the user is already logged in
                $communityForm.find('.CommunityLeadForm__done-button-container').removeClass('d-none')
                $communityForm.find('.CommunityLeadForm__done-button-container').addClass('d-flex')
                $communityForm.find('.CommunityLeadForm__form-container').addClass('CommunityLeadForm__vertically-centered');
            }
        }
    }

    onError = function (data, textStatus, event) {
        analyticsForm.RewriteObjectForm(data, event);
        AdobeLaunch.pushFormSubmitFailedEvent(AdobeLaunch.FORM_COMMUNITY_ID, AdobeLaunch.FORM_MODAL_TYPE, AdobeLaunch.FORM_REQUEST_INFO_NAME);
    }

    function setupForm($modalElement) {
        // show the main right panel when the modal opens
        $modalElement.find('.CommunityLeadForm__customer-info-selection-container').removeClass('d-none');
        // hide the thank you messages that might have been opened from previous submissions
        $modalElement.find('.CommunityLeadForm__customer-thank-you-message-container').addClass('d-none');
        $modalElement.find('.CommunityLeadForm__realtor-thank-you-message-container').addClass('d-none');
        $modalElement.find('.CommunityLeadForm__customer-info-thank-you-container').addClass('d-none');
        $modalElement.find('.CommunityLeadForm__create-account-container').addClass('d-none');
        $modalElement.find('.CommunityLeadForm__log-in-container').addClass('d-none');
        $modalElement.find('.CommunityLeadForm__done-button-container').addClass('d-none')
        $modalElement.find('.CommunityLeadForm__done-button-container').removeClass('d-flex')
        // attach the form handling js and submission event
        var $leadForm = $modalElement.find('form[name=CommunityLeadForm]');
        $leadForm.attach(Form);
        $modalFormContainer = $modalElement;
        $leadForm.on(constants.FORM_AJAX_SUBMIT, handleSubmit);
        $leadForm.on('change', function () {
            handleFormStarted($leadForm)
        });


        userIsAuthenticated = $leadForm.data('user-is-authenticated')
        isCreateAccountForm = false;
        isLoginForm = false;

        $modalElement.find('.CommunityLeadForm__is-realtor-checkbox').on('change', isRealtorToggle);
        $modalElement.find('.CommunityLeadForm').attach(CommunityLeadForm);
        isRealtorToggle();
    }

    handleFormStarted = function ($targetForm) {
        $targetForm.off('change');
        AdobeLaunch.pushFormStartedEvent(AdobeLaunch.FORM_COMMUNITY_ID, AdobeLaunch.FORM_MODAL_TYPE, AdobeLaunch.FORM_REQUEST_INFO_NAME);
    }

    function isRealtorToggle() {
        var $isRealtorCheckbox = $modalFormContainer.find('.CommunityLeadForm__is-realtor-checkbox')
        var isRealtor = $isRealtorCheckbox.is(':checked');
        var $form = $isRealtorCheckbox.closest('form');
        var $submit = $form.find('[type="submit"]');
        var $realtorInfoContainer = $form.find('.CommunityLeadForm__realtor-info-container');
        var $realtorNameInput = $realtorInfoContainer.find('.CommunityLeadForm__BrokerOffice');
        var $realtorNumberInput = $realtorInfoContainer.find('.CommunityLeadForm__BrokerId');

        if (isRealtor) {
            $submit.prop('disabled', true);
            $form.find('.CommunityLeadForm__is-realtor-label').addClass('d-none');
            $realtorInfoContainer.removeClass('d-none');
            $realtorNameInput.prop('disabled', false);
            $realtorNumberInput.prop('disabled', false);
            $realtorNameInput.focus();

        } else {
            $form.find('.CommunityLeadForm__is-realtor-label').removeClass('d-none')
            $realtorInfoContainer.addClass('d-none');
            $realtorNameInput.prop('disabled', true);
            $realtorNameInput.parent().removeClass('is-empty').removeClass('has-error');
            $realtorNumberInput.prop('disabled', true);
            $realtorNumberInput.parent().removeClass('is-empty').removeClass('has-error');
        }

        if ($form.is(':valid')) {
            $submit.prop('disabled', false);
        }
    }

    function replaceFirstNameMergeField($headlineElement) {
        var $firstNameInput = $modalFormContainer.find('.CommunityLeadForm__firstNameInput');

        // replace merge field for customer first name in headline element
        $headlineElement.text(function (index, text) {
            if (text.indexOf('{firstName}') > -1) {
                text = text.replace('{firstName}', $firstNameInput.val());
            }

            return text;
        });
    }

    function selectThankYouText($thankYouElement, userIsAuthenticated, isExistingUser) {
        var existingUserText = $thankYouElement.data("existing-user-text");

        if (!userIsAuthenticated && isExistingUser) {
            $thankYouElement.prop('innerHTML', existingUserText);
        }
    }

    function setupThankYouMessage(userIsAuthenticated, isExistingUser) {
        // unhide the thank you message container
        $modalFormContainer.find('.CommunityLeadForm__customer-info-thank-you-container').removeClass('d-none');

        // Show Customer/Realtor Thank You Message based off isRealtorCheckbox
        var isRealtor = $modalFormContainer.find('.CommunityLeadForm__is-realtor-checkbox').is(":checked")
        if (isRealtor) {
            $modalFormContainer.find('.CommunityLeadForm__realtor-thank-you-message-container').removeClass('d-none');
            var $realtorThankYouMessageHeadline = $modalFormContainer.find('.CommunityLeadForm__realtor-thank-you-headline');
            selectThankYouText($realtorThankYouMessageHeadline, userIsAuthenticated, isExistingUser);
            replaceFirstNameMergeField($realtorThankYouMessageHeadline);
            var $realtorThankYouMessage = $modalFormContainer.find('.CommunityLeadForm__realtor-thank-you-message');
            selectThankYouText($realtorThankYouMessage, userIsAuthenticated, isExistingUser);
        } else {
            //this.$customerInfoThankYouContainer.removeClass('d-none');
            $modalFormContainer.find('.CommunityLeadForm__customer-thank-you-message-container').removeClass('d-none');
            var $customerThankYouMessageHeadline = $modalFormContainer.find('.CommunityLeadForm__customer-thank-you-headline');
            selectThankYouText($customerThankYouMessageHeadline, userIsAuthenticated, isExistingUser);
            replaceFirstNameMergeField($customerThankYouMessageHeadline);
            var $customerThankYouMessage = $modalFormContainer.find('.CommunityLeadForm__customer-thank-you-message');
            selectThankYouText($customerThankYouMessage, userIsAuthenticated, isExistingUser);
        }
    }

    function convertFormToCreateAccount() {
        // change name and action of form to create account
        var $leadform = $modalFormContainer.find('form[name=CommunityLeadForm]');
        $leadform.prop('name', '');
        $leadform.prop('action', '/formhandling/account/createaccount');

        moveEmailField($modalFormContainer.find('.CommunityLeadForm__create-account-label'));

        // enable customer info thank you password fields
        $createAccountContainer = $modalFormContainer.find('.CommunityLeadForm__create-account-container');
        var $passwordField = $createAccountContainer.find('input[name=Password]');
        var $passwordVerifyField = $createAccountContainer.find('input[name=PasswordVerify]');
        $passwordField.prop('disabled', false);
        $passwordVerifyField.prop('disabled', false);

        // edit name of create account password input
        $passwordField.prop('name', 'password');

        // show the create account container
        $createAccountContainer.removeClass('d-none');
        isCreateAccountForm = true;
    }

    function convertFormToLogin() {
        var $leadform = $modalFormContainer.find('form[name=CommunityLeadForm]');
        $leadform.prop('name', '');
        $leadform.prop('action', '/formhandling/account/login');

        moveEmailField($modalFormContainer.find('.CommunityLeadForm__log-in-prompt'));

        var $loginContainer = $modalFormContainer.find('.CommunityLeadForm__log-in-container');
        $loginContainer.find('input[name=Password]').prop('disabled', false);
        $loginContainer.find('input[name=RememberMe]').prop('disabled', false);
        $loginContainer.removeClass('d-none');

        // change Create Account Password field name so it doesn't match Login Password field name
        $modalFormContainer.find('.CommunityLeadForm__create-account-container').find('input[name=Password]')
            .prop('name', 'CreateAccountPassword');

        isLoginForm = true;
    }

    function convertFormToB2CLogin() {

        $modalFormContainer.find('form[name=CommunityLeadForm]')
            .prop('name', '')
            .prop('action', '/auth/b2c/signinsignupprefill')
            .find('.CommunityLeadForm__create-account-container')
                .removeClass('d-none')
                .find(".FieldGroup")
                    .addClass('d-none')
                    .end()
                .find(".CommunityLeadForm__submit-button")
                    .val("Login or Create an Account")
                    .prop('disabled', false);

        isCreateAccountForm = true;

    } 

    // move email field to the thank-you-container and make it readonly
    function moveEmailField($fieldBefore) {
        var $leadForm = $modalFormContainer.find('.CommunityLeadForm')
        var $emailContainer = $leadForm.find('.CommunityLeadForm__email-container');
        var $emailInput = $emailContainer.find('input[name=Email]')
        $fieldBefore.after($emailContainer);
        $emailInput.prop('readonly', true);
    }
}

module.exports = {
    initRequestInfoModals: initRequestInfoModals
};

function updateCustomField(customFieldName, customFieldValue) {
    var rawData = {};
    rawData[customFieldName] = customFieldValue;

    var gestid = Engage.guest_ref;

    var data = {
        GuestId: gestid,
        PatchData: rawData
    };

    updateData(data);
}

function updateLeadFormField(customFieldName, customFieldValue) {
    var rawData = {};
    rawData[customFieldName] = customFieldValue;

    var gestid = Engage.guest_ref;

    var data = {
        GuestId: gestid,
        PatchData: rawData
    };

    updateLeadFormSubmit(data);
}

function updateData(data) {
    // Construct the URL dynamically
    var updateDataUrl = window.location.origin + '/api/cdpapi/UpdateCustomFieldsData';

    // AJAX call to update data on server
    $.ajax({
        url: updateDataUrl,
        type: "PATCH",
        contentType: "application/json",
        data: JSON.stringify(data),
        success: function() {
            // Data updated successfully
        },
        error: function(xhr, status, error) {
            // Handle error
        }
    });
}

function updateLeadFormSubmit(data) {
    // Construct the URL dynamically
    var updateDataUrl = window.location.origin + '/api/cdpapi/UpdateLeadFormSubmitted';

    // AJAX call to update data on server
    $.ajax({
        url: updateDataUrl,
        type: "PATCH",
        contentType: "application/json",
        data: JSON.stringify(data),
        success: function() {
            // Data updated successfully
        },
        error: function(xhr, status, error) {
            // Handle error
        }
    });
}