var AlphaVision = {
    AlphaVisionAttributeValues: {
        LotNumber: 'Lot Number',
        LotLabel: 'LotLabel',
        Lot: 'lot',
        Address: 'address',
        EstMoveInDate: 'estmoveindate',
        Premium: 'premium',
        UniqueLot: 'uniquelot',
        Elevation: 'elevation',
        LotFacing: 'LOTFACING',
        LotType: 'LOTTYPE',
        HomeSiteAvailability: 'Home Site Availability'
    },
    
    DataType: {
        MasterMap: "mastermap",
        SitePlan: "siteplan",
        Error: "error",
        Lot: "lot",
        Amenity: "amenity",
        Plan: "plan",
        ZoomLevel: "zoom_level",
        SVG: "svg"
    },
    
    EventType: {
        Init: "init",
        Click: "click",
        ShowLots: "showLots()",
        ShowPlans: "showPlans()",
        FilterLots: "filterLots()",
        SelectLot: "selectLot()",
        SelectMap: "selectMap()",
        Reset: "reset()",
        ExportStaticSVG: "exportStaticSVG()",
        ZoomIn: "zoomIn()",
        ZoomOut: "zoomOut()"
    },

    MapType: {
        Master: 1,
        SitePlan: 2,
        Lot: 3
    },
    
    GetAlphaVisionAttributes: function (attributes) {
        result.lotLabel;
        result.lotNumber;
        result.lotFacing;
        result.lotType;
        result.lotPremium;
        result.address;
        result.elevation;
        result.estMoveInDate;
        result.homeSiteAvailability;
        result.uniqueLot;
        result.lot;
    
        // Setting Values from the array of objects / properties returned from AlphaVision
        attributes.map(function (attribute) {
            var key = Object.keys(attribute)[0];
    
            switch (key) {
                case AlphaVisionAttributeValues.LotNumber:
                    result.lotNumber = attribute[AlphaVisionAttributeValues.LotNumber];
                    break;
    
                case AlphaVisionAttributeValues.LotLabel:
                    result.lotLabel = attribute[AlphaVisionAttributeValues.LotLabel];
                    break;
    
                case AlphaVisionAttributeValues.Lot:
                    result.lot = attribute[AlphaVisionAttributeValues.Lot];
                    break;
    
                case AlphaVisionAttributeValues.Address:
                    result.address = attribute[AlphaVisionAttributeValues.Address];
                    break;
    
                case AlphaVisionAttributeValues.EstMoveInDate:
                    result.estMoveInDate = attribute[AlphaVisionAttributeValues.EstMoveInDate];
                    break;
    
                case AlphaVisionAttributeValues.Premium:
                    result.lotPremium = attribute[AlphaVisionAttributeValues.Premium];
                    break;
    
                case AlphaVisionAttributeValues.UniqueLot:
                    result.uniqueLot = attribute[AlphaVisionAttributeValues.UniqueLot];
                    break;
    
                case AlphaVisionAttributeValues.Elevation:
                    result.elevation = attribute[AlphaVisionAttributeValues.Elevation];
                    break;
    
                case AlphaVisionAttributeValues.LotFacing:
                    result.lotFacing = attribute[AlphaVisionAttributeValues.LotFacing];
                    break;
    
                case AlphaVisionAttributeValues.LotType:
                    result.lotType = attribute[AlphaVisionAttributeValues.LotType];
                    break;
    
                case AlphaVisionAttributeValues.HomeSiteAvailability:
                    result.homeSiteAvailability = attribute[AlphaVisionAttributeValues.HomeSiteAvailability];
                    break;
            }
        });
    
        return result;
    }
}

module.exports = AlphaVision;