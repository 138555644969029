var component = require('../../../lib/js/component.js');
var utils = require('../../../lib/js/utils.js');

function LocationCopyCard() {

    this.initialize = function (element, options) {

        $element = $(element);
        $options = $(options);

        var _this = this;

        
    }
}

module.exports = component(LocationCopyCard);
